/*
* expandable menu started
*/
@media (max-width: 1199px) {
  .header-navigation {
    margin-bottom: 0;
  }

  .logo-box {
    width: 100%;
    float: none;
    margin: 0 !important;
  }

  .navigation-box {
    margin: 0;
  }

  .navbar-expand-lg .menu-toggler {
    display: block;
    margin-right: 0;
    border-color: #fff;
    background-color: transparent !important;
    margin: 23px 0;
    color: #fff;
    border: none;
    font-size: 28px;
    float: right;
    outline: none;
    cursor: pointer;
  }

  .menu-toggler:hover {
    border-color: #ff4eb5;
  }

  .menu-toggler .icon-bar {
    background: #fff;
  }

  .menu-toggler:hover .icon-bar {
    background: #ff4eb5;
  }

  .navbar-expand-lg .navbar-collapse {
    padding-bottom: 0;
    overflow: auto !important;
    width: 100%;
  }

  .navbar-expand-lg .navbar-collapse {
    display: none !important;
    background: transparent;
    padding: 0px 0px !important;
    padding-bottom: 0px !important;
    margin: 0;
    background: #000;
    -webkit-transition: all .5s ease;
    transition: all .5s ease;
    height: 0;
  }

  .navbar-expand-lg .navbar-collapse.showen {
    display: block !important;
    height: auto;
    max-height: 70vh;
  }

  .navbar-collapse.show {
    overflow-y: auto;
  }

  .header-navigation .main-navigation {
    float: none !important;
    text-align: left !important;
  }

  .header-navigation .nav {
    width: 100%;
    text-align: left;
  }

  .header-navigation .nav > li {
    display: block;
    padding: 0 !important;
    width: 100%;
    float: none;
  }

  .header-navigation .nav > li + li {
    margin-left: 0 !important;
  }

  .header-navigation .nav > li > a {
    padding: 8px 0;
  }

  .header-navigation .right-box.nav {
    width: 100%;
    margin: 0;
  }

  .header-navigation .right-box.nav > li > a {
    border: none !important;
    padding: 8px 0;
    color: #fff;
  }

  .header-navigation .nav > li > a:after {
    display: none;
  }

  .header-navigation .nav.navigation-box + .nav {
    border-top: 1px dashed #2a2833;
  }

  .header-navigation .navigation-box > li > .sub-menu,
  .header-navigation .navigation-box > li > .sub-menu > li > .sub-menu {
    position: relative !important;
    width: 100% !important;
    opacity: 1 !important;
    visibility: visible !important;
    left: auto !important;
    right: auto !important;
    top: auto !important;
    -webkit-transition: none !important;
    transition: none !important;
    display: none;
    float: none !important;
    margin: 0;
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }

  .header-navigation .navigation-box > li > .sub-menu > li > a::after {
    display: none;
  }

  .header-navigation ul.navigation-box > li + li {
    padding: 0 !important;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    display: block;
    float: none;
    margin: 0 !important;
  }

  .header-navigation .nav > li.show-mobile {
    display: none;
  }

  .header-navigation ul.navigation-box > li > a,
  .header-navigation .right-box.nav > li > a {
    padding: 0;
    display: block;
    color: #fff;
    padding: 16px 0 16px 20px !important;
    -webkit-transition: background 0.4s ease 0s;
    transition: background 0.4s ease 0s;
  }

  .header-navigation ul.navigation-box li a .sub-nav-toggler {
    background-color: transparent;
    background-image: none;
    border: 1px solid #fff;
    border-radius: 4px;
    padding: 9px 9px;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 15px;
    /*margin-top: 8px;*/
    margin-right: 0px;
    display: block;
    cursor: pointer;
    outline: none;
  }

  .header-navigation .navigation-box .sub-nav-toggler .icon-bar {
    background-color: #fff;
    border-radius: 1px;
    display: block;
    height: 1px;
    width: 20px;
  }

  .header-navigation .navigation-box .sub-nav-toggler .icon-bar + .icon-bar {
    margin-top: 4px;
  }

  .header-navigation .container .right-side-box {
    top: 20px;
    right: 15px;
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
  }

  .header-navigation .container {
    display: block;
    padding-right: 0px;
    padding-left: 0px;
    position: relative;
  }

  .header-navigation .container .logo-box {
    position: relative;
    top: 0;
    left: 0;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    float: none;
    padding-right: 145px;
  }

  .header-navigation .container .logo-box .navbar-brand {
    float: left;
    padding: 20px 0;
  }

  .header-navigation .container .logo-box .navbar-toggler {
    float: left;
  }

  .header-navigation ul.navigation-box {
    display: block;
    margin: 0 !important;
    width: 100%;
  }

  .header-navigation ul.navigation-box > li {
    padding: 0;
    display: block;
  }

  .header-navigation ul.navigation-box > li > a {
    display: block;
    padding: 13px 30px;
  }

  .header-navigation ul.navigation-box > li > a:after {
    display: none;
  }

  .header-navigation ul.navigation-box > li + li {
    margin: 0;
  }

  .header-navigation .main-navigation {
    float: none;
    width: 100%;
    display: none;
    text-align: left;
    background: #28ad47;
    max-height: 70vh;
    overflow-y: scroll;
  }

  .header-navigation .container .menu-toggler {
    display: block;
  }

  .header-navigation.stricky-fixed ul.navigation-box > li {
    padding: 0;
  }

  .header-navigation .container .logo-box .navbar-brand {
    background-color: transparent;
  }

  .header-navigation .container .menu-toggler {
    float: left;
    margin: 28px 0;
    color: #2a2833;
    /*margin-left: 30px;*/
    position: relative;
  }

  .header-navigation .container .logo-box {
    padding-right: 15px !important;
  }

  .header-navigation {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .header-navigation .container .logo-box {
    left: 0 !important;
  }

  .header-navigation .container .logo-box {
    padding-left: 15px;
  }

  .header-navigation .main-navigation {
    padding-right: 0;
  }

  .header-navigation ul.navigation-box > li.current > a, .header-navigation ul.navigation-box > li:hover > a {
    color: #fff;
  }

  .site-header__header-two .header-navigation .container .menu-toggler {
    color: #fff;
  }

  .site-header__header-two .header-navigation ul.navigation-box > li > a {
    color: #fff;
  }
}
@media (max-width: 425px) {
  .header-navigation .container .right-side-box {
    display: none !important;
  }

  .header-navigation .container .menu-toggler {  
    float: left!important;
    position: absolute;
    top: 2px;
    /* float: right !important; */
  }

  .header-navigation .container .logo-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
  .header-navigation .container .logo-box:after {
    display: none;
  }
}
@media (max-width: 425px) {
  .download_btn {
    padding: 0px 30px;
    margin: 25px 0;
  }
}
@media (max-width: 1199px) {
  
}
 @media (max-width: 991px) {
/*  .show {
    display: none !important;
} */
.hide {
  display: block !important;
}
  .cta-one .block-title__title {
    text-align: center;
}
  [class*=banner-one__shape-], [class*=banner-two__shape-] {
    display: none;
}
  img {
    max-width: 100%;
  }
  .show {display: none;}
.hide {display: block;}
.timeline {
    padding: 0px  10px;
}

  .cta-one {
    margin-bottom: 0;
    padding: 8em 0 2em 0;
    text-align: center;
  }

  .fact-one__single {
    margin-bottom: 20px;
  }

  .app-shot-one .app-shot-one__carousel::before {
    display: none;
  }

  .app-shot-one .app-shot-one__carousel {
    padding-top: 0;
    margin-top: 0;
    padding-bottom: 20px;
  }

  .banner-one__moc {
    /*display: none;*/
    display: block;
    position: relative;
    top: auto;
    right: 0px;
  }

  .banner-one .container {
    padding-top:120px;
    padding-bottom: 120px;
  }

  .cta-one__moc {
    position: relative;
    top: auto;
    left: auto;
  }

  .cta-two__moc {
    position: relative;
    top: auto;
    right: auto;
  }

  .fact-one__single {
    margin-left: auto;
    margin-right: auto;
  }

  .banner-two__moc {
    right: -52%;
  }
}
@media (max-width: 767px) {
    .mailchimp-one {
    background-size: contain;
    padding: 4em 0px;
  }
  .activity_img-d
	{
	float:none;
	}


.l-text {
  text-align: center;
  background: #000000a3;
  padding: 30px;
  border-radius: 3px;
}


.banner-one__moc {
    display: block;
    position: relative;
    top: auto;
    left: 30px;
}

  .video-one__title {
    font-size: 30px;
  }

  .banner-two__moc {
    display: none;
  }

  .banner-two .container {
    padding-top: 150px;
  }
}
@media (max-width: 575px) {
 .inner-banner {
    padding-top: 150px;
    padding-bottom: 120px;
  }
    .main-logo {
    max-width: 220px;
}
}
@media (max-width: 480px) {
  .block-title__title br,
  .banner-one__text br,
  .banner-two__text br {
    display: none;
  }

  .block-title__title {
    font-size: 40px;
    line-height: 1.1em;
  }

  [class*=banner-one__shape-],
  [class*=banner-two__shape-] {
    display: none;
  }

  .banner-one__moc {
    display: block;
    position: relative;
    top: auto;
    right: auto;
    left: 0;
  }

  .banner-two__title,
  .banner-one__title {
    font-size: 50px;
  }
  .banner-two__title br,
  .banner-one__title br {
    display: none;
  }
}
@media (max-width: 375px) {
  .block-title__title {
    font-size: 36px;
  }

  .banner-one .container {
    padding-top: 130px;
  }

  .banner-one__moc:before {
    /*display: none;*/
  }

  .video-one__btn {
    width: 60px;
    height: 60px;
  }
  .video-one__btn i {
    font-size: 20px;
    line-height: 60px;
  }

  .video-one__title {
    font-size: 24px;
    margin-top: 15px;
  }
}

/*------Aboutus Page---------*/
@media (max-width: 992px)
{
  .sports_activity-d {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .keylist-d {
    width: unset;
    padding-top: 25px;
  }
}
@media (max-width: 768px)
{
  .activity-d {
    float: none;
    margin-top: 4%;
  }
  .keylist-d {
    width: unset;
    padding-top: 25px;
  }
  .activity-d h4 {

    text-align: center;
  }
  .activity-d p {

    text-align: center;
  }
  .sports_activity-d {
    padding-top: 50px;
    padding-bottom: 0px;
  }
  .aboutlasttext-d {
  float: unset;
}
}