@charset "utf-8";

/* CSS Document */

* {
  padding: 0px;
  margin: 0px;
  list-style-type: none;
  text-decoration: none;
  outline: none;
}

section {
  /* display: inline-block; */
  width: 100%;
  position: relative;
}

a:hover,
button:hover,
a:focus,
button:focus {
  cursor: pointer;
  text-decoration: none !important;
  outline: none !important;
}

ul,
li {
  list-style-type: none;
}

span,
p,
label,
li,
a,
button,
input,
select,
textarea {
  font-size: 13.5px;
  font-weight: 400;
  transition: all 0.2s ease 0s;
}

label {
  /*font-weight: 100 !important*/
}

p {
  margin: 0px;
  color: #444444;
  font-size: 14px;
  line-height: 27px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 800;
}

body {
  overflow-x: hidden;
  font-family: "Poppins", sans-serif !important;
  margin: 0px;
  padding: 0px;
  scrollbar-width: none;
}

a:hover {
  color: #248e3d !important;
}

a,
button {
  text-decoration: none;
  outline: none;
}

.commanhead {
  margin: 0;
  font-weight: 500 !important;
  padding: 13px 13px 13px;
  font-size: 16px;
  color: #515a53;
}

.commanbox .commanhead {
  border-bottom: 1px solid #d8d8d8;
}

.activityul {
  margin: 0;
}

.activityul li {
}

.activityul li a {
  display: block;
  border-bottom: 1px solid #e0e0e0;
  padding: 13px 15px;
  color: #6e6e6e;
  /*font-weight: 100;*/
  font-size: 14px;
  color: #69756c;
}

.activityul li a:hover,
.activityul li a.active {
  background-color: rgba(44, 174, 74, 0.13);
}

.activityul li a img {
  margin-right: 16px;
  width: 26px;
}

/*Start Top header CSS*/

.topbar {
  background-color: #0d4756;
  display: inline-block;
  width: 100%;
  position: fixed;
  top: 0;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.71);
  z-index: 9999;
}

.logodiv {
  margin: 11px 0;
}

.logodiv a {
}

.logodiv a .logoicon {
  width: 40px;
  position: relative;
  top: 3px;
}

.logodiv a .logotext {
  width: 174px;
  margin-left: 10px;
}

.topsearch {
  position: relative;
}

.w3-round {
  margin-left: 969%;
  height: 22px;
  margin-top: -143%;
}

.topsearch input {
  width: 100%;
  border: 0;
  background-color: #16596b;
  color: #fff;
  opacity: 1;
  font-size: 15px;
  padding: 22px 42px 21px 15px;
  letter-spacing: 0.2px;
}

.topsearch input:focus {
  background-color: #1e6b80;
}

.topsearch img {
  position: absolute;
  right: 10px;
  top: 28px;
  width: 22px;
}

.dropdiv {
  margin-top: 11px;
  text-align: right;
}

.dropdiv .dropdown {
  margin-top: 6px;
  color: #fff;
}

.dropnotify {
  display: inline-block;
}

.dropaccount {
  float: right;
}

.dropbel button img {
  width: 25px;
}

.dropnotify button {
  padding: 0;
  background-color: transparent !important;
  border: 0 !important;
  display: inline-block;
  margin-right: 10px;
}

.dropnotify button:hover {
  background-color: transparent !important;
}

.dropnotify button img {
}

.dropnotify button span {
  background-color: #d94040;
  width: 20px;
  display: inline-block;
  height: 20px;
  border-radius: 46px;
  border: 1px solid #fff;
  font-size: 10px;
  line-height: 17.5px;
  position: relative;
  top: -8px;
  left: -12px;
  color: #fff !important;
}

.dropnotify button:hover span {
  background-color: #f9e721;
  color: #000 !important;
}

.dropaccount {
  /* margin-top: 1px !important; */
}

.dropaccount button {
  background-color: transparent;
  border: 0;
  color: #fff !important;
}

.dropaccount button .t_userimg {
  background-image: url("../img/userimg.jpg");
  display: inline-block;
  width: 35px;
  height: 35px;
  border-radius: 50px;
  border: 1px solid #28b049;
  background-size: cover;
  vertical-align: middle;
  margin-right: 4px;
  background-color: #fff;
}

.dropaccount button:hover .t_userimg {
  border: 1px solid #f9e721;
}

.dropaccount button:hover {
  color: #f9e721 !important;
}

.dropaccount button img.t_accarrow {
  margin-left: 5px;
}

.divpost {
  border: 0px solid #e4e4e4;
  border-bottom-color: rgb(228, 228, 228);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-radius: 3px;
  border-bottom: 2px solid #e4e4e4;
  background-color: #fff;
  margin-top: 15px;
}

.userdetail {
  display: inline-block;
  width: 100%;
  padding: 11px;
}

.postuserul {
  display: flex;
  margin: 0px;
}

.p_usermore .dropaccount {
  margin-top: 7px !important;
  text-align: right;
  width: 20px;
}

.p_userli {
  flex: 1;
  display: inline-block;
  width: 50px;
  height: 50px;
  border-radius: 60px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding-left: 58px;
  position: relative;
}

.p_userli label {
  display: block;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 500;
  color: #333;
  font-size: 16px;
}

.p_userimg {
  display: inline-block;
  width: 50px;
  height: 50px;
  border-radius: 60px;
  border: 1px solid #999;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  vertical-align: middle;
  position: relative !important;
  top: 0 !important;
  left: -60px !important;
}

.p_usertime {
  display: block;
  margin-top: -15px;
  font-size: 13px;
  color: #8b9b8f;
  font-weight: 300;
}

.p_usermore {
}

.btn {
  box-shadow: unset !important;
}

.p_usermore {
}

body {
  background-color: #f7f7f7 !important;
}

.dropdown-menu {
  z-index: 9999;
}
.postdata {
  margin-top: 11px;
  border-top: 1px solid #e8e8e8;
}

.postdata p {
  font-size: 14px;
  /* padding: 10px 11px 10px 11px; */
  padding: 15px 16px !important;
  line-height: 22px;
  color: #444;
  margin: 0;
  font-weight: 400;
}

.postlikediv {
  display: inline-block;
  width: 100%;
}

.commentul {
  display: flex;
  padding: 4px 15px 10px 15px;
  border-bottom: 1px solid #e8e8e8;
  margin-bottom: 0;
  align-items: baseline;
  border-top: 1px solid #e8e8e8;
}

.commentli {
  flex: 1;
}

.likeuser {
  vertical-align: -4px;
  margin-right: 2px;
  width: 19px;
}

.likeinner {
  display: inline-block;
  margin-left: -1px;
  color: #6b786e;
  vertical-align: middle;
  font-weight: 300 !important;
}

.userlikeinner {
  display: inline-block;
  font-size: 13px;
  width: 100%;
  /* margin-bottom: 9px;
	padding: 0px 15px; */
  color: #888;
  padding: 13px;
}

.likeinner ul {
}

.likeinner ul li {
  position: relative;
}

.likeinner ul span {
  position: relative;
}

.likespan {
  font-size: 14px;
  color: #69756c;
  font-weight: 500;
  cursor: pointer;
}

.likeinner li {
}

.likeinner li span {
  display: inline-block;
  width: 35px;
  height: 35px;
  border-radius: 70px;
  position: absolute;
  left: 0;
  top: 0;
  background-position: center;
  background-size: cover;
  border: 1px solid #fff;
}

.userlikeinner span {
  font-weight: 300;
  font-size: 13px;
}

.lastliketotal {
}

.commentimg {
  width: 21px;
  margin-right: 3px;
}

.likecount {
  background-color: #1dac40;
  text-align: center;
  color: #fff;
  font-weight: 600;
  font-size: 12px;
  line-height: 32px;
}

.commentinner {
  display: inline-block;
  color: #6b786e;
  vertical-align: middle;
  margin-top: 6px;
  cursor: pointer;
  flex: auto;
  text-align: left;
}

.commentinner {
  margin-right: 3px;
}

.commentinner span {
  margin-right: 3px;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
  color: #69756c;
}

.commentinner span .spancount {
  font-size: 14px;
  margin-left: 5px;
  color: #1dac40;
}

/*End Top header CSS*/

.affix {
  position: fixed;
  z-index: 9;
}

.commanbox {
  border: 1px solid #e4e4e4;
  border-bottom-color: rgb(228, 228, 228);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: rgb(228, 228, 228);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-radius: 3px;
  border-bottom: 2px solid #e4e4e4;
  background-color: #fff;
  margin-top: 15px;
  display: inline-block;
  width: 100%;
}

.profileleft {
  text-align: center;
  display: inline-block;
  width: 100%;
  margin-top: 77px;
}

.pad7 {
  padding: 7.5px !important;
}

.profilediv {
}

.profilespan {
  /* border-radius: 70px;
    position: relative;
    left: 1px;
    margin-top: -46px;
    border: 1px solid #2cae4a;
    z-index: 1;
    background-position: 50%;
	background-size: cover; */
  display: inline-block;
  border: 1px solid #999;
  width: 110px;
  height: 110px;
  border-radius: 70px;
  position: relative;
  margin-top: -46px;
  border: 1px solid #2cae4a;
  z-index: 1;
  background-position: center;
  background-size: cover;
}

.prof_span {
  width: 110px;
  height: 110px;
  border-radius: 70px;
  content: "";
  display: inline-block;
  position: absolute;
  left: 0;
  top: -46px;
  background-color: #2cae4a;
  z-index: 0;
  left: 1px;
}

.profileig_inner {
  display: inline-block;
  position: relative;
}

.profilediv a {
  display: inline-block;
  width: 40px;
  height: 40px;
  background-color: #248e3d;
  border-radius: 40px;
  border: 2px solid #fff;
  padding-top: 9px;
  position: absolute;
  right: -12px;
  bottom: 0;
  z-index: 1;
}

.pro_useredit {
  display: block;
  margin-top: 13px;
  color: #e39633;
  font-size: 14px;
  font-weight: 500 !important;
  letter-spacing: 0.3px;
}

.pro_username {
  font-weight: 500 !important;
  font-size: 16px;
  color: #515a53;
  margin-top: 24px;
}

.profilediv a img {
}

.profileul {
  display: inline-block;
  width: 100%;
  border-top: 1px solid #e3e3e3;
  border-bottom: 1px solid #e3e3e3;
  padding: 7px 0px 13px 0px;
  margin-top: 10px;
}

.profileul li {
  display: inline-block;
  width: 47%;
  /* margin-top: 10px; */
  margin-top: 0px;
}

.profileul li a {
  /*font-weight: 100;*/
  font-size: 14px;
  color: #69756c;
}

.profileul li a span {
  display: block;
  color: #2cae4a;
  font-weight: 500;
  font-size: 20px;
}

.pro_viewprofile {
  color: #2cae4a;
  font-weight: 500;
  font-size: 14px;
  padding-bottom: 12px;
  display: inline-block;
  color: #8c958e;
}

.pro_viewprofile:hover {
}

.br_user label {
  font-weight: 100;
  font-size: 14px;
  color: #69756c;
}

.b_requestul {
  display: inline-block;
  width: 100%;
  overflow-y: scroll;
  height: 300px;
  scrollbar-width: none;
}

.b_requestul li {
  display: flex;
  align-items: center;
  padding: 5px 12px;
  border-bottom: 1px solid #e0e0e0;
}

.b_requestul .br_user {
  flex: 1;
  align-items: center;
}

.b_requestul .br_user span {
  display: inline-block;
  width: 40px;
  height: 40px;
  border: 1px solid #999;
  border-radius: 40px;
  vertical-align: middle;
  margin-right: 5px;
  border: 1px solid #2cae4a;
  background-size: cover;
  background-repeat: no-repeat;
}

.b_requestul .br_actions {
}

.br_delete {
  border: 1px solid #cf2f44 !important;
  background-color: #f7bbbb;
}

.br_accept {
  border: 1px solid #2cae4a !important;
  background-color: #cbecd2;
}

.actincomman {
  width: 30px;
  height: 30px;
  display: inline-block;
  border: 0;
  margin-left: 6px;
  border-radius: 4px;
}

.swiper-container {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  width: 80%;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide:nth-child(2n) {
  width: 60%;
}

.swiper-slide:nth-child(3n) {
  width: 40%;
}

.swiper-containerdownload .swiper-slide img {
  width: 100%;
}

.downloadapp_div {
  text-align: center;
  padding: 15px 10px;
}

.downloadapp_div a {
  margin: 0px 7.5px;
}

.downloadlink {
}

.downloadlink img {
  margin: 0px 7.5px;
}

.appleplay {
  position: relative;
  top: -1px;
}

.helpul {
  padding: 10px;
}

.helpul li {
  display: inline-block;
}

.helpul li a {
  display: inline-block;
  padding: 4px 17px;
  border: 1px solid #2cae4a;
  color: #2cae4a;
  border-radius: 23px;
  font-size: 12px;
  margin: 5px;
  background-color: rgba(203, 236, 210, 0.35);
}

.helpul li a:hover {
  background-color: #2cae4a;
  color: #fff !important;
}

.livescore {
  float: right;
  font-weight: 600;
  color: #d94040;
  font-size: 17px;
  position: relative;
  top: -4px;
}

.livescore img {
  vertical-align: -6px;
  margin-right: 5px;
}

.postarea_div {
  position: relative;
}

.postarea_div span {
  width: 40px;
  height: 40px;
  display: inline-block;
  border-radius: 60px;
  position: absolute;
  left: 10px;
  top: 8px;
  z-index: 1;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  overflow: hidden;
}

.postarea_div textarea {
  resize: none;
  width: 100%;
  border: 0;
  background-color: #edf8ef;
  height: 135px;
  padding: 19px 9px 6px 60px;
  color: #2cae4a;
  margin-top: -34px;
  margin-bottom: -14px;
}

.postactiondiv {
  display: inline-block;
  width: 100%;
  padding: 12px 12px 0px 10px;
  border-top: 1px solid #eaeaea;
}

.postactiondiv button {
  float: right;
  border: 0;
  background-color: #2cae4a;
  border-radius: 50px;
  color: #fff;
  font-size: 16px;
  padding: 0px 34px;
  margin-top: 1px;
  /*font-weight: 600;*/
}

.postactiondiv button:hover {
  background-color: #1c8033;
}

.postactiondiv ul {
  display: inline-block;
}

.postactiondiv ul li {
  display: inline-block;
  margin-right: 10px;
}

.postactiondiv ul li a {
  display: block;
}

.postactiondiv ul li a img {
  width: 36px;
}

.postcomment {
  display: inline-block;
  width: 100%;
  position: relative;
  margin-bottom: 0px;
  padding: 15px;
  display: none;
}

.postuser_img {
  display: inline-block;
  width: 44px;
  height: 44px;
  border-radius: 30px;
  background-position: center;
  background-size: cover;
  border: 1px solid #2cae4a;
  position: relative;
  left: -54px;
  top: 16px;
  float: left;
  margin-bottom: -27px;
  margin-top: -16px;
}

.postcomment_div {
  position: relative;
  display: inline-block;
  width: 100%;
}

.postcomment_div a {
  display: inline-block;
  position: absolute;
  left: 10px;
  bottom: 9px;
}

.postcomment_div a img {
}

.postcomment_div textarea {
  width: 100%;
  background-color: #edf8ef;
  padding: 0;
  padding-top: 0px;
  padding-right: 0px;
  padding-left: 0px;
  color: #2cae4a;
  border-radius: 22px;
  border: 1px solid #2cae4a;
  padding-left: 43px;
  padding-top: 10px;
  padding-right: 100px;
  overflow: unset !important;
  height: 44px;
  resize: none !important;
}

.postcomment_div button {
  float: right;
  border: 0;
  background-color: #2cae4a;
  padding: 4px 27px;
  border-radius: 50px;
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  position: absolute;
  right: 11px;
  bottom: 7px;
}

textarea::-webkit-scrollbar {
  display: none;
}

.chatdiv::-webkit-scrollbar,
.b_requestul::-webkit-scrollbar {
  display: none;
}

.chatdiv::-moz-scrollbar {
  display: none;
}

textarea::-moz-scrollbar {
  display: none;
}

textarea::-ms-scrollbar {
  display: none;
}

.replydiv {
}

.usersubcomment {
  position: relative;
  padding-left: 57px;
  margin-top: 8px;
}

.usersubcomment .postuser_img {
  left: -55px;
  top: 16px;
}

.usersubcomment p {
  background-color: #ebf7ee;
  font-size: 12px;
  line-height: 17px;
  padding: 9px 16px 9px 15px;
  border-radius: 20px;
  margin-bottom: 0;
  text-align: justify;
  border: 1px solid #c5dcc9;
}

.replydiv .commentul {
  padding: 8px 15px 7px 5px;
}

.replydiv .postcomment {
  padding: 9px 0px 0px 56px;
  display: block;
}

.videopost {
  position: relative;
}

.videopost span {
  display: inline-block;
  position: absolute;
  right: 10px;
  bottom: 10px;
  background-color: rgba(0, 0, 0, 0.57);
  color: #fff;
  border-radius: 31px;
  padding: 3px 16px;
  border: 1px solid #fff;
}

.videopost .vidplaydiv {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 100%;
  text-align: center;
}

.videopost .vidplaydiv a {
  display: inline-block;
}

.livepost {
  position: relative;
}

.livepost span {
  position: absolute;
  right: 20px;
  top: 20px;
  background-color: #dd2121;
  font-size: 16px;
  font-weight: 600;
  border-radius: 31px;
  padding: 2px 18px;
  border: 1px solid #fff;
  color: #fff;
  letter-spacing: 1.5px;
}

.containerbottom {
  margin-top: 67px;
}

.chatsec {
}

.chatdiv {
  padding: 10px;
  background-color: #fff;
  display: inline-block;
  position: fixed;
  right: 0;
  top: 64px;
  height: 100%;
  box-shadow: -2px 3px 2px rgba(198, 198, 198, 0.5);
  overflow-y: scroll;
  width: 61px;
  white-space: nowrap;
  transition: all 0.2s ease 0s;
  scrollbar-width: none;
  z-index: 9;
}

.chatul {
  padding-bottom: 105px;
}

.chatul li {
  margin-bottom: 11px;
}

.chatul li a {
  color: #535353;
  font-size: 13px;
  display: block;
}

.chatul li a span {
  display: inline-block;
  width: 45px;
  height: 45px;
  border-radius: 100px;
  vertical-align: middle;
  border: 2px solid #fff;
  margin-right: 11px;
  background-position: center;
  background-size: cover;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.5);
}

.chatul li a span.active {
  border-color: #2cae4a;
}

.chatopen {
  background-color: #0d4756;
  position: fixed;
  right: 0;
  bottom: 0;
  width: 68px;
  padding: 12px 13px;
  text-align: center;
  height: 54px;
}

.userchatdiv {
  position: absolute;
  right: 80px;
  bottom: 0;
  background-color: #fff;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.4);
  overflow: hidden;
  position: fixed;
  z-index: 9;
  transform: scale(0);
  transition: all 0.3s ease 0s;
  width: 270px;
}

.chathead {
  background-color: #2cae4a;
  padding: 8px 6px 8px 6px;
}

.chathead .ch_userimg {
  display: inline-block;
  width: 35px;
  height: 35px;
  border-radius: 50px;
  border: 1px solid #90f9a8;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  vertical-align: -5px;
}

.chathead label {
  color: #fff;
  line-height: 15px;
  font-weight: 500;
  position: relative;
  top: -8px;
  left: 14px;
}

.chathead label span {
  display: block;
  color: #ffff24;
  font-weight: 500;
  font-size: 11px;
}

.chathead .chatclose {
  float: right;
  font-size: 18px;
  position: relative;
  top: 7px;
  right: 6px;
  color: #fff;
}

.chatdetails {
  display: inline-block;
  width: 100%;
  background-color: #edf8ef;
  min-height: 250px;
}

.chatsend input:focus {
  border: 1px solid #9cddab;
}

.chatuserli {
}

.chatuserli span {
}

.chatuserli p {
}

.chatuserli {
}

.chatuserli span {
  display: inline-block;
  width: 35px;
  height: 35px;
  border-radius: 30px;
  vertical-align: -4px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: 2px;
  position: relative;
  left: 0px;
  border: 1px solid #2cae4a;
  top: 4px;
}

.chatuserli p,
.chatmeli p {
  display: inline-block;
  background-color: #fff;
  border: 1px solid #dceadf;
  padding: 7px;
  width: 190px;
  border-radius: 14px;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 5px;
  word-break: break-all;
}
.chatmeli p {
  text-align: left;
}

.chatsend {
  background-color: #dcecdf;
  padding: 6px;
}

.inputTextTosend {
  padding-left: 32px !important;
}

.chatsend input {
  display: inline-block;
  width: 100%;
  border: 1px solid #dbe3dc;
  padding: 10px 72px 10px 1px;
  box-sizing: border-box;
  border-radius: 50px;
  font-size: 12px;
}

.chatsend a {
  display: inline-block;
  width: 36px;
  height: 36px;
  border-radius: 50px;
  background-color: #2cae4a;
  box-shadow: 0px 2px 2px #627365;
  padding: 10px 9px 9px 9px;
  position: absolute;
  right: 6px;
  bottom: 14px;
}

.chatsend a img {
  width: 16px;
}

.chatsend input:focus {
}

.chathead .chatclose:hover {
  color: #1c7331 !important;
}

.chatul_box {
}

.chatul_box li {
  padding: 0px 7px;
  margin-top: 9px;
}

.chatmeli {
  text-align: right;
}

.profileleft {
  background-image: unset !important;
}

.leftmenuopen {
  display: inline-block;
  position: relative;
  top: 12px;
  left: -3px;
  z-index: 999;
}

.leftmenuopen img {
  width: 23px;
}

.closemenu {
  float: right;
  position: absolute;
  right: 11px;
  top: 11px;
}

.closemenu img {
  width: 21px;
}

.chatopen:hover {
  background-color: #248e3d;
}

.chatopen img {
  width: 45px;
}

.chatopenimg,
.chatdivopen {
  width: 200px;
}

.notificationbox {
}

.chatnotify {
  display: none;
}

.dropnotify > .dropdown-menu {
  height: 350px;
  width: 294px;
  top: 41px;
  padding: 0;
  overflow: hidden;
}

.notificationbox {
  padding: 0px;
}

.notificationbox h3 {
  /* text-align: left;
	color: #535353;
	font-size: 16px;
	background-color: #ececec;
	padding: 12px 15px 9px 10px; */
  text-align: left;
  color: #2cae4a;
  font-size: 15px;
  background-color: #fff;
  padding: 18px 12px 16px 10px;
  font-weight: 500 !important;
  border-bottom: 1px solid #c1c1c1;
}

.notificationbox h3 a {
  color: #2cae4a;
  float: right;
  margin-top: 1px;
}

.notiul {
  height: 303px;
  overflow-y: scroll;
  scrollbar-width: none;
  margin-bottom: 0;
}

.notiul::-webkit-scrollbar {
  display: none;
}

.notiul li label {
  color: #444;
  font-size: 13px;
  font-weight: 500;
}

.notiul li span.notiimg {
  /* display: inline-block;
	width: 35px;
	height: 35px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	border-radius: 50px;
	vertical-align: middle;
	margin-right: 10px;
	margin-right: 6px; */
  display: inline-block;
  width: 35px;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  vertical-align: middle;
  margin-right: 11px;
  float: left;
  clear: left;
}

.notiul li span.notitime {
  float: right;
  font-size: 11px;
  margin-top: 11px;
  color: #9b9b9b;
  font-weight: 500;
}

.padzero {
  /*padding: 0px !important*/
}

input.question,
textarea.question {
  font-size: 14px;
  font-weight: 300;
  border-radius: 2px;
  margin: 0;
  border: none;
  width: 100%;
  background: rgba(0, 0, 0, 0);
  transition: padding-top 0.2s ease, margin-top 0.2s ease;
  box-shadow: unset !important;
  overflow-x: hidden;
  /* Hack to make "rows" attribute apply in Firefox. */
  padding-bottom: 1px;
  padding-left: 1px;
}

/* Underline and Placeholder */

input.question + label,
textarea.question + label {
  display: block;
  position: relative;
  white-space: nowrap;
  padding: 0;
  margin: 0;
  width: 100%;
  border-top: 1px solid #d2d2d2;
  -webkit-transition: width 0.4s ease;
  transition: width 0.4s ease;
  height: 0px;
}

input.question:focus + label,
textarea.question:focus + label {
  border-color: #2cae4a;
}

input.question:focus,
input.question:valid {
}

textarea.question:valid,
textarea.question:focus {
  margin-top: 35px;
}

input.question:focus + label > span,
input.question:valid + label > span {
  top: -55px;
  font-size: 14px;
  color: #2cae4a;
}

textarea.question:focus + label > span,
textarea.question:valid + label > span {
  top: -150px;
  font-size: 22px;
  color: #333;
}

input.question:valid + label,
textarea.question:valid + label {
  border-color: green;
}

input.question + label > span,
textarea.question + label > span {
  font-weight: 500;
  margin: 0;
  position: absolute;
  color: #333;
  font-size: 15px;
  top: -34px;
  left: 0px;
  z-index: -1;
  -webkit-transition: top 0.2s ease, font-size 0.2s ease, color 0.2s ease;
  transition: top 0.2s ease, font-size 0.2s ease, color 0.2s ease;
  top: -30px;
  color: #7d7d7d;
  font-size: 13px !important;
}

.loginbg {
  /*padding: 35px 85px 85px 85px;*/
  height: 100vh;
  background-position: center;
  background-size: cover;
  color: #fff;
}

.loginlogo_text {
  display: inline-block;
}

.loginlogo_text img {
  width: 282px;
}

.logintext {
  position: absolute;
  top: 45%;
  transform: translateY(-50%);
}

.logintext h1 {
  font-size: 70px;
  font-weight: 600 !important;
  line-height: 81px;
  margin-bottom: 0;
  letter-spacing: 4.2px;
}

.logintext p {
  color: #f9e81b;
  font-weight: 500;
  letter-spacing: 0.4px;
  line-height: 27px;
  margin-top: 11px;
  font-size: 17px;
}

.downloaddiv {
  margin-top: 43px;
}

.downloaddiv button {
  background-color: transparent;
  border: 0;
  display: inline-block;
  vertical-align: middle;
  margin-right: 21px;
}

.downloaddiv button:hover {
  transform: scale(1.2);
}

.downloaddiv button img {
  width: 190px;
}

.loginformul {
  display: flex;
  padding: 15px;
}

.loginformli1 {
  flex: 1;
}

.loginformli2 a {
  font-size: 16px;
  font-weight: 600;
  color: #2cae4a;
  margin-top: 8px;
  display: inline-block;
}

.loginformli2 a img {
  width: 19px;
  margin-left: 5px;
}

.googleplay {
}

.appleplay {
}

.loginform {
  /*height: 100vh;*/
  /*display: table-cell;*/
  vertical-align: middle;
}

.signinicon-d {
  padding-left: 3px;
}

.loginforgot button:hover {
}

.sportskilld {
  padding: 15px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.4);
}

.skillshead {
  font-size: 21px;
  font-weight: 600 !important;
  text-transform: capitalize;
  margin: 0;
  margin: 10px 0px 25px 0px;
}

.ss_search {
  float: right;
  position: relative;
}

.skillshead_div span {
  float: left;
  color: #fff;
  margin-top: 3px;
  font-size: 15px;
  margin-top: 12px;
  font-weight: 500;
}

.skillshead_div span b {
  color: #e6e433;
}

.skillshead_div input {
  width: 350px;
  height: 45px;
  border: 0;
  border-radius: 2px;
  padding: 0px 45px 0px 9px;
}

.skillshead_div img {
  position: absolute;
  right: 12px;
  top: 11px;
  width: 23px;
}

.skillshead_div {
  background-color: #2cae4a;
  border-radius: 3px;
  display: inline-block;
  width: 100%;
  padding: 10px 15px;
}

.styled-input-single {
  position: relative;
  padding: 20px 0 20px 40px;
  text-align: left;
}

.styled-input-single {
  display: inline-block;
  position: absolute;
  right: -6px;
  top: 4px;
  width: 25px;
  height: 25px;
}

.styled-input-single label {
  cursor: pointer;
}

.styled-input-single label:before,
.styled-input-single label:after {
  content: "";
  position: absolute;
  top: 50%;
  border-radius: 50%;
}

.styled-input-single label:before {
  left: 4px;
  width: 20px;
  height: 20px;
  margin: -15px 0 0;
  background: #f7f7f7;
  box-shadow: 0 0 1px grey;
  background: #f0f0f0;
}

.styled-input-single label:after {
  left: 4px;
  width: 20px;
  height: 20px;
  margin: -10px 0 0;
  opacity: 0;
  background-image: url(../img/check.jpg);
  -webkit-transform: translate3d(-40px, 0, 0) scale(0.5);
  transform: translate3d(-40px, 0, 0) scale(0.5);
  -webkit-transition: opacity 0.25s ease-in-out,
    -webkit-transform 0.25s ease-in-out;
  transition: opacity 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out;
  transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out;
  transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out,
    -webkit-transform 0.25s ease-in-out;
  top: 15px;
  background-position: center;
  background-size: contain;
  background-size: 26px;
}

.styled-input-single input[type="radio"],
.styled-input-single input[type="checkbox"] {
  position: absolute;
  top: 0;
  left: -9999px;
  visibility: hidden;
}

.styled-input-single input[type="radio"]:checked + label:after,
.styled-input-single input[type="checkbox"]:checked + label:after {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

::selection {
  color: #fff;
  background: #2cae4a;
}

.sportsul {
  display: inline-block;
  width: 100%;
  border-top: 1px solid #d9d9d9;
  padding: 15px;
  box-sizing: border-box;
  height: 83vh;
  overflow: auto;
  scrollbar-width: none;
}

.sportsul::-webkit-scrollbar {
  display: none;
}

.sportsul li {
  display: inline-block;
  border: 0px solid #999;
  text-align: center;
  padding: 10px;
  float: left;
  margin: 1.5%;
  border-radius: 5px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
  width: 21.8%;
  height: 160px;
  position: relative;
  background-color: #fff;
}

.sportsul li .ss_spansportsimg {
  display: inline-block;
  width: 77px;
  height: 78px;
  border-radius: 76px;
  border: 2px solid #2cae4a;
  display: block;
  margin: auto;
  margin-top: auto;
  margin-top: auto;
  margin-top: 17px;
}

.sportsul li .ss_spansportsimg img {
  height: 50px;
  margin-top: 12px;
}

.sportsul li .ss_sportstile {
  margin-top: 15px;
  font-size: 15px;
  font-weight: 500;
  display: block;
}

.sportsul li .styled-input-single {
  display: inline-block;
}

.sportsdiv {
  background-color: #f2f2f2;
}

.livescoreguest {
  float: unset;
  top: 10px;
  margin-right: 12px;
  color: #ec2424;
}

.scoreboxul {
  display: inline-block;
  width: 100%;
  text-align: center;
  margin-top: 10px;
}

.scoreboxul > li {
  display: inline-block;
  width: 44%;
  margin: 2%;
  border: 1px solid #d8d8d8;
}

.scoreboxul > li > h3 {
  font-size: 16px;
  color: #2cae4a;
  font-weight: 600 !important;
  border-bottom: 1px solid #e1e1e1;
  padding-bottom: 10px;
  background-color: #edf8ef;
  margin: 0;
  padding-top: 10px;
}

.teamdiv {
  padding-top: 14px;
}

.teamdiv ul {
  display: flex;
  padding: 5px;
  margin-bottom: 8px;
  border-bottom: 1px solid #e3e3e3;
}

.teamdiv ul li {
  display: inline-block;
  flex: auto;
}

.teamdiv ul li .lt_img {
  display: inline-block;
  width: 44px;
  height: 36px;
  background-position: center;
  background-size: contain;
  display: block;
  margin: auto;
  background-repeat: no-repeat;
}

.teamdiv ul li .it_flgname {
  font-weight: 600 !important;
  text-transform: capitalize;
  color: #555;
  margin-top: 6px;
  margin-bottom: 1px;
  min-width: 95px;
}

.teamdiv ul li .it_flgscore {
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 0;
  color: #555;
  white-space: nowrap;
}

.vsicon {
  margin-top: 9px;
}

.datentime_para {
  margin: 0;
  font-weight: 500;
  font-size: 15px;
  line-height: 11px;
}

.scoreupdate_para {
  font-size: 12px;
  line-height: 17px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.it_flgscore.active {
  color: #2cae4a !important;
}

.ls_datetime {
}

.ls_datetime {
  padding: 15px;
  font-weight: 600;
}

.ls_datetime b {
  color: #e39633;
  font-weight: 600 !important;
  margin-right: 4px;
}

.team_match {
  padding: 0px 15px;
  display: inline-block;
  width: 100%;
  margin-bottom: 0px;
}

.team_match {
}

.team_match li {
  text-align: right;
  display: inline-block;
  width: 100%;
  margin-bottom: 16px;
}

.scoreflag_div {
  display: inline-block;
  float: left;
}

.score_flagimg {
  display: inline-block;
  width: 37px;
  height: 31px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  vertical-align: middle;
  margin-right: 8px;
}

.score_flagtext {
  font-size: 15px;
  font-weight: 600 !important;
}

.score_flagtext {
}

.teamwin {
  color: #2cae4a;
  padding: 0px 15px;
  margin-top: -8px;
  margin-bottom: -1px;
  background-color: #f0f0f0;
  width: 100%;
  padding: 5px 13px;
  font-weight: 600;
}

.teamscore {
  margin-top: 10px;
}

.team_match_acc {
  padding: 0;
}

.team_match_acc li {
  margin: 0px;
}

.team_match_acc .score_flagtext {
  font-weight: 500 !important;
  color: #fff;
  display: inline-block;
  position: relative;
  top: 1px;
}

.panel-default > .panel-heading {
  background-color: #2cae4a !important;
  border-color: #2cae4a !important;
  border-radius: 0px !important;
  border: 0px !important;
}

.team_match_acc .teamscore {
  margin-top: 10px;
  color: #fff;
}

.panelnew {
}

.panelnew thead {
  background-color: #e3f5e7;
}

.panelnew thead th {
  background-color: #e3f5e7;
  font-weight: 600;
}

.panelnew tbody td {
  font-size: 13px;
  color: #333;
}

.matchtotle {
}

.matchtotle ul {
}

.matchtotle ul {
  margin: 0;
}

.matchtotle ul li {
  display: inline-block;
  width: 100%;
  border-top: 1px solid #d9d9d9;
  padding: 7px 10px 2px 10px;
}

.matchtotle ul li b {
  color: #2cae4a;
}

.matchtotle ul li label {
  float: right;
  font-weight: 600;
  font-size: 13px;
  letter-spacing: 0.5px;
}

.panelnew table {
  margin: 0px;
}

.buddiesul {
  display: inline-block;
  width: 100%;
  border-bottom: 1px solid #e3e3e3;
  padding-bottom: 16px;
  margin-top: -5px;
}

.buddiesul li {
  display: inline-block;
  margin: 0px 3px;
}

.buddiesul li span {
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  border: 1px solid #ddd;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.buddieshead {
  text-align: left;
  display: inline-block;
  width: 100%;
  padding: 1px 10px;
  margin-bottom: 16px;
}

.buddieshead label {
  font-weight: 500 !important;
  color: #666;
  font-size: 16px;
}

.buddieshead a {
  float: right;
  color: #2cae4a;
  font-size: 12px;
}

table thead th,
table tbody td {
}

.commentinner2 {
  text-align: center;
}

.commentinner3 {
  text-align: right;
}

.userlikeinner {
}

.userlikeinner b {
  color: #2cae4a;
  font-weight: 500 !important;
}

.commentinner3 img {
  margin-right: 1px;
  margin-top: -2px;
}

.Smartactionsbottom,
.Smartactionstop {
  display: none !important;
}

.sharecontainer {
  margin-top: 60px !important;
}

.socialmodel {
}

.socialmodel .modal-content {
  border-radius: 0px;
}

.modal-header {
  padding: 15px;
  border-bottom: 1px solid #e5e5e5;
  background-color: #2cae4a;
  color: #fff;
  font-weight: 600 !important;
}

.modal-header .close {
  margin-top: -2px;
  color: #ddd;
  opacity: 1;
  color: #fff;
  font-size: 26px;
}

.showclose {
  opacity: 1 !important;
}

.modal-body ul li {
  display: inline-block;
  text-align: center;
  margin: 16px 20px;
  margin-bottom: 16px;
  margin-bottom: 9px;
}

.modal-body ul li a {
  display: block;
}

.modal-body ul {
  display: inline-block;
  width: 100%;
  text-align: center;
}

.modal-body ul li a img {
  width: 50px;
  display: block;
  margin: auto;
  margin-bottom: 8px;
}

.modal-body ul li a span {
  border: 1px solid #e6e6e6;
  width: 61px;
  display: inline-block;
  border-radius: 100px;
  background-color: #eee;
  color: #373737;
  font-size: 12px;
  height: 60px;
}

.modal-body ul li a {
}

.modal-body ul li a label {
  display: inline-block;
  width: 100%;
  font-weight: 600;
  color: #3c3c3c;
}

.commentinner3 a {
  color: #6b786e;
}

.matchdatetime {
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.4px;
  position: relative;
  top: -3px;
  color: #444;
}

.live_teamul1 .it_flgscore {
  color: #e8c812 !important;
}

.scoreboxul_football {
  padding: 15px;
  margin: 0;
}

.scoreboxul_football li {
  width: 100%;
  margin: 0px;
}

.scoreboxul_football .teamdiv ul li .it_flgname {
  display: block;
}

.scoreboxul_football > li > h3 {
  font-size: 17px !important;
}

.team_match_acc1 .score_flagtext {
  top: 1px;
}

.scoreboxul_football .teamdiv ul li .lt_img {
  width: 68px !important;
  height: 56px !important;
}

.leftsecprof {
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 47px !important;
}

.userleft {
  top: 105px !important;
}

.userlikeinner img {
  width: 20px;
  margin-right: 7px;
}

.commentul {
}

.commentul li {
  flex: 1;
  text-align: center;
}

.commentul li.likkli {
  text-align: left;
}

.commentul li.sharetext {
  text-align: right;
}

.postdata img {
  margin-bottom: 9px;
  height: 55vh;
}

.topsearch input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #fff;
}

.topsearch input::-moz-placeholder {
  /* Firefox 19+ */
  color: #fff;
}

.topsearch input:-ms-input-placeholder {
  /* IE 10+ */
  color: #fff;
}

.topsearch input:-moz-placeholder {
  /* Firefox 18- */
  color: #fff;
}

.btn-bs-file {
  position: relative;
}

.btn-bs-file input[type="file"] {
  position: absolute;
  top: -9999999;
  filter: alpha(opacity=0);
  opacity: 0;
  width: 0;
  height: 0;
  outline: none;
  cursor: inherit;
}

.taguser_div {
  display: none;
}

.taguser_div {
  position: relative;
  margin-top: 14px;
}

.taguser_div ul {
  display: inline-block;
  width: 100%;
  border: 0;
  background-color: #edf8ef;
  padding: 10px 10px 5px 10px;
  color: #616161;
  border-radius: 0px;
  border: 1px solid #2cae4a45;
  padding-right: 26px;
  margin: 0;
  padding-left: 34px;
}

.taguser_div ul li {
  display: inline-block;
  border: 1px solid #999;
  padding: 4px 11px;
  border-radius: 50px;
  margin: 0px 3px 7px 3px;
}

.taguser_div ul li span {
}

.taguser_div ul li a {
  margin-left: 7px;
  color: #2cae4a;
}

.taguser_div #closetag {
  position: absolute;
  right: 0px;
  top: 0px;
  background-color: #d94040;
  color: #fff;
  border-bottom-left-radius: 11px;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 19px;
}

.taguser_div #closetag:hover,
#closecheckin:hover {
  background-color: #2cae4a;
  color: #fff !important;
}

.tagicon {
  position: absolute;
  left: 10px;
  top: 18px;
}

.tagcheckin_div {
  display: inline-block;
  width: 100%;
  border: 0;
  background-color: #edf8ef;
  padding: 10px 10px 5px 10px;
  padding-right: 10px;
  padding-left: 10px;
  color: #616161;
  border-radius: 0px;
  border: 1px solid #2cae4a45;
  padding-right: 26px;
  margin: 0;
  padding-left: 34px;
  position: relative;
  min-height: 44px;
  margin-top: 15px;
  display: none;
}

.tagcheckin_div .checkinicon {
  position: absolute;
  left: 10px;
  top: 9px;
}

#closecheckin {
  position: absolute;
  right: -1px;
  top: -1px;
  background-color: #d94040;
  color: #fff;
  border-bottom-left-radius: 11px;
  width: 22px;
  height: 21px;
  text-align: center;
  line-height: 19px;
}

.checkininner {
}

.checkininner span {
}

.showlocation {
  display: inline-block;
  width: 100%;
  padding: 9px 105px 6px 14px;
  color: #69756c;
}

.showlocation b {
  font-weight: 500;
  color: #2cae4a;
}

.uploadimg {
}

.uploadimg span.overlay {
}

.uploadimg .editlink {
}

.uploadimg .editlink img {
}

.uploadimg .deleteimg {
}

.uploadvideo_div {
}

.uploadvideo_div .overlay,
.uploadimg span.overlay {
  background-color: rgba(0, 0, 0, 0.26);
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.uploadvideo_div .editlink {
}

.uploadvideo_div .deleteimg {
}

.uploadvideo_div {
}

.uploadvideo_div img.playbtn {
  width: 35px;
  position: relative;
  left: 0px;
  top: 26px;
  z-index: 1;
}

.postul_imgvdo {
  padding: 0px 10px;
  margin-bottom: 4px;
}

.postul_imgvdo li {
  display: inline-block;
  vertical-align: middle;
  margin: 4px;
}

.postul_imgvdo li div {
  display: inline-block;
  width: 90px;
  height: 90px;
  border-radius: 9px;
  border: 1px solid #2cae4a;
  text-align: center;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
}

.editlink {
  position: absolute;
  z-index: 1;
  left: 6px;
  top: 6px;
}

.editlink img {
  width: 17px;
}

.deleteimg {
  position: absolute;
  right: 3px;
  top: 5px;
  z-index: 1;
}

.deleteimg img {
  width: 17px;
}

.srachdiv_top {
  position: absolute;
  left: -15px;
  top: 67px;
  width: 107%;
  padding: 0 15px;
}

.srachdiv_bg {
  background-color: #fff;
  width: 100%;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.32);
  border-bottom: 3px solid #2cae4a;
}

.nav-tabs > li > a {
  padding: 5px 14px !important;
  background-color: #cbecd2 !important;
  border-radius: 0px !important;
  color: #248e3d !important;
  font-weight: 500;
  font-size: 13px;
  border-radius: 33px !important;
  border: 1px solid #c0d9c5 !important;
}

.nav-tabs > li {
  float: left;
  margin: 5px 5px;
  width: 40%;
}

.nav-tabs {
  border-bottom: 1px solid #ddd;
  padding: 0px 7px;
}

.pad15 {
  padding: 0px 15px;
}

.nav-tabs {
  border-bottom: 1px solid #ddd;
  padding: 12px 0px;
  display: flex;
  overflow: auto;
  text-align: center;
}

.srachbuddyul {
  display: inline-block;
  width: 100%;
  height: 300px;
  overflow: auto;
  scrollbar-width: none;
}

.srachbuddyul::-webkit-scrollbar {
  display: none;
}

.srachbuddyul li {
  display: flex;
  align-items: center;
  margin: 10px 0px;
  border-bottom: 1px solid #d7d7d7;
  padding-bottom: 10px;
}

.srachbuddyul li span.sb_bgimg {
  display: inline-block;
  width: 40px;
  height: 40px;
  border: 1px solid #9899;
  overflow: hidden;
  border-radius: 120px;
  background-position: center;
  background-size: cover;
  margin-right: 10px;
}

.srachbuddyul li div {
  flex: auto;
}

.srachbuddyul li div span {
  display: block;
  color: #2cae4a;
  font-size: 13px;
}

.srachbuddyul li div label {
  margin: 0;
  font-weight: 500 !important;
  font-size: 14px;
  color: #333;
}

button.btnadd {
  border: 1px solid #c0d9c5;
  background-color: #cbecd2;
  color: #2cae4a;
  font-weight: 500;
  font-size: 12px;
  padding: 3px 10px;
  border-radius: 40px;
  float: right;
}

button.btnadd:hover {
  color: #fff !important;
  background: #2cae4a !important;
  border-color: #2cae4a !important;
}

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li > a:hover {
  color: #fff !important;
  background: #2cae4a !important;
  border-color: #2cae4a !important;
}

.teallocation_das {
  color: #666 !important;
}

.teallocation_das img {
  width: 11px;
  margin-right: 5px;
  vertical-align: -3px;
}

#searchdiv {
  display: none;
}

.profilesection {
  min-height: 364px;
  margin-top: 64px;
  display: inline-block;
  width: 100%;
  position: relative;
  text-align: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.overlayprofie {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.4);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.profilesec_inner {
  /* display: inline-block;
	position: relative; */
  display: inline-block;
  position: relative;
  top: 15px;
}

.profilesec_inner a {
  display: inline-block;
  width: 35px;
  height: 35px;
  border-radius: 40px;
  background-color: #1b732f;
  position: absolute;
  bottom: 0;
  right: 0;
  border: 2px solid #2cae4a;
  padding-top: 9px;
  padding-left: 1px;
}

.profilesec_inner a span {
}

.profilesec_inner span {
  display: inline-block;
  width: 100px;
  height: 100px;
  border-radius: 110px;
  background-position: center;
  background-size: cover;
  border: 2px solid #2cae4a;
}

.profilesec a {
}

.profilesec a img {
  width: 14px;
}

.profilesec {
  /* 	margin-top: 30px; */
}

.followingul {
  display: inline-block;
  width: 100%;
  margin-top: 5px;
}

.followingul li {
  display: inline-block;
  margin: 0px 15px;
}

.followingul li a {
  display: block;
}

.followingul li label {
  display: block;
  font-size: 22px;
  color: #31d557;
}

.followingul li span {
  color: #fff;
  font-size: 16px;
  display: inline-block;
  width: 100%;
}

.uploadbanner {
  /* float: right;
	position: relative;
	z-index: 1;
	margin-right: 19px;
	margin-top: 15px;
	border: 0;
	background-color: #e13939;
	color: #fff;
	font-size: 13px;
	font-weight: 600;
	padding: 7px 14px;
	border-radius: 4px;
	box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5); */
  float: right;
  position: relative;
  z-index: 1;
  margin-right: 105px;
  margin-top: 27px;
  border: 0;
  background-color: #e13939;
  color: #fff;
  font-size: 13px;
  font-weight: 600;
  padding: 5px 15px;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
}

.uploadbanner:hover {
  background-color: #2cae4a;
}

.skills {
  display: inline-block;
  max-width: 100%;
  background-color: #fff;
  margin-top: -42px;
  padding: 5px 0px;
  margin-bottom: -60px;
  border: 1px solid #e4e4e4;
  border-bottom: 3px solid #2cae4a;
  width: 100%;
}

.skills ul {
  display: flex;
  overflow-x: scroll;
  max-width: 100%;
  margin: 0;
}

.skills ul li {
  flex: 1;
  min-width: 97px;
}

.skills ul li a {
  /* text-align: center;
	display: block;
	color: #333;
	font-weight: 500;
	font-size: 11px; */
  text-align: center;
  display: block;
  color: #333;
  font-weight: 500;
  font-size: 11px;
  padding: 2px;
}

.skills ul li a .skillsimg {
  display: block;
  width: 50px;
  height: 50px;
  background-color: #fff;
  border: 2px solid #2cae4a;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 35px;
  border-radius: 100px;
  margin: auto;
  margin-bottom: auto;
  margin-bottom: auto;
  margin-bottom: 5px;
}

.sportsname {
  /* display: inline-block;
	width: 100%;
	font-size: 11px;
	height: 19px;
	overflow: hidden;
	text-align: center; */
  display: inline-block;
  width: 100%;
  font-size: 12px;
  height: 29px;
  overflow: hidden;
  text-align: center;
}

.pad7 {
}

.profilecontainer {
  margin-top: 30px;
}

.teampost_inner {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  text-align: center;
}

.teamhead {
  text-align: center;
  padding: 15px;
  display: inline-block;
  width: 100%;
  font-size: 18px;
  color: #248e3d;
  border-bottom: 1px solid rgba(0, 0, 0, 0.11);
  font-weight: 500 !important;
}

.postteam {
}

.postteam {
  min-width: 100%;
  display: inline-block;
  width: 100%;
  margin-bottom: 0;
}

.postteam li {
  display: inline-block;
  margin: 0px 24px;
  vertical-align: middle;
}

.vsimg {
  width: 44px;
  display: block;
  margin: auto;
  margin-bottom: auto;
  margin-bottom: 5px;
}

.teamimg {
  display: block;
  border: 1px solid #2cae4a;
  width: 80px;
  height: 80px;
  border-radius: 490px;
  margin: auto;
  margin-bottom: auto;
  margin-bottom: auto;
  margin-bottom: 8px;
  background-repeat: no-repeat;
  background-size: cover;
}

.teamname {
  font-size: 15px;
  display: block;
  font-weight: 500 !important;
  color: #222;
  margin: 0;
  /* margin-bottom: 5px; */
  white-space: nowrap;
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 14px 0px;
}

.teamname_winner {
  display: block;
  font-weight: 600 !important;
  font-size: 17px;
  letter-spacing: 1.5px;
  color: #e35c19;
}

.imgwinner {
  width: 35px;
  margin-bottom: -20px;
}

.imgwinner1 {
  width: 35px;
  margin-bottom: -20px;
}

.matchscore_text {
  display: block;
  font-size: 14px;
  font-weight: 500 !important;
}

.matchscore {
  font-weight: 600 !important;
  font-size: 20px;
  color: #e35c19;
}

.matchdate {
  display: inline-block;
  width: 100%;
  color: #2cae4a;
  font-size: 17px;
  font-weight: 400 !important;
  margin: 0;
  margin-bottom: 6px;
}

.matchdate span {
  font-size: 17px;
  font-weight: 500 !important;
  margin: 0;
  color: #4c4c4c;
}

.teamlocation {
  display: inline-block;
  position: relative;
  padding-left: 23px;
  margin: 0;
  font-size: 16px;
  padding: 0px 15px 10px 22px;
  font-weight: 400 !important;
  margin: 0 16px;
  white-space: nowrap;
  width: -webkit-fill-available;
  overflow: hidden;
  text-overflow: ellipsis;
}

.teamlocation img {
  margin-right: 6px;

  left: -1px;
  width: 14px;
  top: 3px;
}

.actiondiv {
  display: inline-block;
  width: 100%;
  padding: 15px 0px;
  border-top: 1px solid rgba(0, 0, 0, 0.13);
  margin-top: 12px;
}

.actiondiv button {
  display: inline-block;
  border: 1px solid #2cae4a;
  background-color: #2cae4a;
  color: #fff;
  padding: 5px 15px;
  border-radius: 4px;
  margin: 0px 10px;
  font-weight: 500 !important;
  font-size: 14px;
}

.actiondiv button:hover {
  background: #248e3d;
}

.useraccount {
  margin-top: -47px;
}

.useraccount {
}

.useraccount h3 a {
  color: #2cae4a;
  float: right;
  margin-top: 0px;
  font-size: 15px;
}

.aboutleftdata {
  display: inline-block;
  width: 100%;
  padding: 6px 15px;
}

.aboutleftdata p {
  margin: 0;
}

.aboutbuddies {
  display: inline-block;
  width: 100%;
  text-align: center;
  padding-top: 13px;
}

.aboutbuddies li {
  display: inline-block;
  margin: 2px 0px;
  width: 58px;
}

.aboutbuddies li:hover span {
  color: #2cae4a;
}

.aboutbuddies li a {
  display: inline-block;
  width: 50px;
  height: 50px;
  border-radius: 60px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border: 2px solid #248e3d;
  transition: all 0.4s linear 0s;
}

.aboutbuddies li a:hover {
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.5);
  border-color: #fff;
}

.aboutteamsul {
  display: inline-block;
  width: 100%;
  text-align: center;
  padding-top: 10px;
}

.aboutteamsul li {
  display: inline-block;
  margin: 6px;
}

.aboutteamsul li a {
  display: inline-block;
  width: 65px;
  height: 65px;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50px;
  transition: all 0.4s linear 0s;
  border: 1px solid #2e9346;
}

.aboutbroadcast {
  display: flex;
}

.aboutbroadcast li {
  flex: auto;
  margin: 16px 10px;
  text-align: center;
}

.aboutbroadcast li a {
  display: inline-block;
  width: 100%;
  min-height: 80px;
  border-radius: 4px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 25px;
  transition: all 0.4s linear 0s;
  margin-bottom: 6px;
}

.aboutbroadcast li a img {
  width: 33px;
}

.editprofile {
  padding: 11px 15px !important;
  margin-bottom: 5px;
}

.editprofile .commaninputdiv {
  margin-top: 11px;
}

.selectdiv {
  display: inline-block;
  float: right;
  position: relative;
  margin-top: -21px;
  margin-bottom: -8px;
}

.selectdiv select {
  float: right;
  border: 0;
  border-bottom-color: currentcolor;
  border-bottom-style: none;
  border-bottom-width: 0px;
  border-bottom-color: currentcolor;
  border-bottom-style: none;
  border-bottom-width: 0px;
  border-bottom: 1px solid #d2d2d2;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 101px;
  padding: 3px 0px;
  font-size: 13px;
}

.selectdiv i {
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 5px solid #8c8c8c;
  float: right;
  position: absolute;
  right: 2px;
  top: 18px;
}

.editprofile label {
  margin: 0;
}

.editprofile input {
  /* padding-bottom: 9px;
	font-size: 15px;
	color: #666; */
  padding-bottom: 0px;
  font-size: 15px;
  color: #666;
  border-bottom: 1px solid #dadada;
  margin-top: -11px;
}

.editcomman_first {
  margin-top: 25px !important;
}

/* 
#editprofile {
	display: ;
}

#profileteams {
	display: ;
}

#profilebuddies {
	display: ;
} */

#profilebuddies {
}

.commanbox .commanhead button {
  border: 1px solid #2cae4a;
  font-weight: 500;
  font-size: 12px;
  padding: 5px 13px;
  color: #2cae4a;
  background-color: #cbecd2;
  border-radius: 5px;
  float: right;
  position: relative;
  top: -4px;
}

.commanbox .commanhead button:hover {
  background-color: #2cae4a;
  color: #fff;
}

.cmnbutton {
  border: 1px solid #2cae4a;
  font-weight: 500;
  font-size: 13px;
  padding: 5px 23px;
  color: rgb(255, 255, 255);
  background-color: #2cae4a;
  border-radius: 5px;
  text-transform: capitalize;
}

.cmnbutton1 {
  border: 1px solid #e19339;
  font-weight: 500;
  font-size: 13px;
  padding: 5px 23px;
  background-color: #e19339;
  border-radius: 5px;
  text-transform: capitalize;
}

.cmnbutton:hover {
  background-color: #1e8e38;
  border-color: #1e8e38;
}

.cmnbutton: {
}

.editbutton_div {
  text-align: right;
  padding: 0px 15px 15px 15px;
}

.editbutton_div button {
}

.buddieslist_ul {
  padding-left: 10px;
  margin-top: 7px;
}

.buddieslist_ul li,
.profileteam_ul li {
    width: 117px;
    display: inline-block;
    border: 1px solid #eaeaea;
    background-color: #fff;
    border-radius: 10px;
    margin: 7px;
    background-color: #f7f7f7;
    position: relative;
    text-align: center;
}

.buddieslist_ul li a {
  display: inline-block;
  text-align: center;
  padding: 14px 10px 10px 10px;
}

.buddieslist_ul li:hover {
  background-color: #2cae4a;
  border: 1px solid #2cae4a;
}

#profilebuddies .buddieslist_ul li:hover label {
  color: #fff;
}

.buddieslist_ul li a:hover span {
  border-color: #fff !important;
}

.buddieslist_ul li a span {
  display: inline-block;
  width: 60px;
  height: 60px;
  background-position: center;
  border-radius: 70px;
  border: 2px solid #248e3d;
  background-size: cover;
}

.buddieslist_ul li label {
  display: inline-block;
  width: 100%;
  text-align: center;
  margin-top: 6px;
  color: #222;
  font-size: 14px;
  height: 20px;
  overflow: hidden;
  margin-bottom: 0;
}

.profusername {
  display: inline-block;
  width: 100%;
  font-size: 18px;
  margin-top: 12px;
  color: #fff;
  margin-bottom: 0px;
}

.changesports {
  border: 1px solid #d96a19;
  color: #fff;
  background-color: #d96a19;
  font-size: 14px;
  padding: 2px 24px;
  border-radius: 3px;
  font-weight: 500;
  /* margin-top: 10px; */
}

.changesports:hover {
  color: #d96a19;
  background-color: #fff;
}

.aboutbuddies li span,
.aboutteamsul li span,
.aboutbroadcast li label {
  font-size: 12px;
  line-height: 15px;
  display: inline-block;
  height: 17px;
  overflow: hidden;
  display: block;
  margin-top: 9px;
}

.profileteams {
}

.profileteam_ul {
}

.profileteam_ul {
  text-align: center;
  padding-top: 9px;
}

.profileteam_ul li {
  width: 29%;
  margin: 1.3%;
}

.profileteam_ul li a {
  display: block;
  text-align: center;
}

.profileteam_ul li span {
  display: block;
  width: 68px;
  height: 68px;
  background-position: center;
  margin: auto;
  margin-top: auto;
  margin-bottom: auto;
  margin-top: 11px;
  margin-bottom: 6px;
}

.profileteam_ul li label {
  height: 20px;
  overflow: hidden;
  color: #333;
}

.profileteam_ul li:hover {
  border: 1px solid #2cae4a;
  background-color: #2cae4a;
}

.profileteam_ul li:hover label {
  color: #fff;
}

.profileteam_ul li:hover {
}

.leftarrow {
  z-index: 9;
  position: absolute;
  left: 15px;
  top: 17px;
}

.leftarrow img {
  width: 22px;
}

.leftarrow {
  display: none;
}

.aboutleftdata p label {
  /* display: none; */
}

.uploadbanner img {
  display: none;
}

.uploadbanner span {
}

.topnav {
}

.profilesection .affix {
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  min-height: 53px;
  z-index: 999999;
  transition: all 0.4s ease 0s;
}

.topnav.affix span,
.topnav span {
  position: absolute;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  z-index: 1;
  text-align: center;
  display: block;
  width: 100%;
  margin-top: 12px;
  letter-spacing: 0.7px;
}

.topnav {
  display: none;
}

.uploadimg_div {
  display: inline-block;
  position: relative;
  margin-top: 62px;
}

.uploadimg_div span {
  border: 2px solid #2cae4a;
  width: 110px;
  display: inline-block;
  position: relative;
  height: 110px;
  border-radius: 90px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #fff;
}

.uploadimg_div a {
  display: inline-block;
  width: 35px;
  height: 35px;
  border-radius: 40px;
  background-color: #1b732f;
  position: absolute;
  bottom: 0;
  right: 0;
  border: 2px solid #2cae4a;
  padding-top: 9px;
  padding-left: 1px;
}

.uploadimg_div a img {
  width: 16px;
}

.createteam_div {
  min-height: 260px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  text-align: center;
}

.createteam_div button {
  float: right;
  position: relative;
  z-index: 1;
  margin-right: 15px;
  margin-top: 15px;
  border: 0;
  background-color: #e13939;
  color: #fff;
  font-size: 13px;
  font-weight: 600;
  padding: 0px 14px;
  border-radius: 4px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.24);
  text-transform: capitalize;
  position: absolute;
  left: 15px;
  bottom: 15px;

}

.createteamform {
  display: inline-block;
  width: 100%;
  background-color: #fff;
  padding: 0px 15px;
}

.createteamform ul {
  position: relative;
  z-index: 8;
}

.createteamform ul button {
}

.createteamform ul li .createinput {
  position: relative;
  width: 100%;
  margin-bottom: -7px;
  right: 0 !important;
}

.createteamform ul li .createinput label {
  font-size: 14px;
  margin-top: -22px;
  font-weight: 500 !important;
}

.createredio {
  margin-top: 16px;
}

.creategender {
  margin-top: 26px;
  margin-bottom: 66px;
  position: relative;
}

.creategender label {
  color: #333;
  font-size: 15px;
  font-weight: 500 !important;
}

.createnext {
  float: right;
  position: relative;
  z-index: 1;
  border: 0;
  background-color: #2cae4a;
  color: #fff;
  font-size: 13px;
  font-weight: 600;
  padding: 5px 14px;
  border-radius: 4px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.24);
  text-transform: capitalize;
  margin-bottom: 20px;
  margin-top: 11px;
  min-width: 100px;
}

.createnext:hover {
  background-color: #218638;
}

.createteamform ul li .createinput label {
  margin-top: -14px;
  font-size: 14px;
  /* margin-top: -15px; */
  font-weight: 500 !important;
  line-height: 1.5;
}

.location_space-d {
  margin-top: 43px !important;
}

.creategender {
}

.creategender {
}

.creategenderdiv {
  margin-top: -13px;
  margin-bottom: 58px;
}

.creategenderdiv .createinput {
  display: inline list-item;
  left: 0px;
  right: 0px;
  top: 0;
  padding: 0px;
  width: 190px !important;
  margin-right: 70px;
}

.creategenderdiv .createinput.styled-input-single label::before {
  width: 60px !important;
  height: 60px !important;
  border-radius: 3px;
  background-size: 38px;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #fff;
  box-shadow: unset;
  border: 1px solid rgba(0, 0, 0, 0.32);
}

.creategenderdiv .male.styled-input-single label::before {
  background-image: url(../img/malegray.png);
}

.creategenderdiv .male.styled-input-single label::after {
  background-image: url(../img/malewhite.png);
  width: 60px;
  height: 60px;
  border-radius: 3px;
  top: 4px;
  background-size: 38px;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #2cae4a;
  top: 8px;
}

.creategenderdiv .female.styled-input-single label::before {
  background-image: url(../img/femalegray.png);
}

.creategenderdiv .female.styled-input-single label::after {
  background-image: url(../img/femalewhite.png);
  width: 60px;
  height: 60px;
  border-radius: 3px;
  top: 4px;
  background-size: 38px;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #2cae4a;
  top: 8px;
}

.creategenderdiv .mix.styled-input-single label::before {
  background-image: url(../img/mixgray.png);
  background-size: 56px;
}

.creategenderdiv .mix.styled-input-single label::after {
  background-image: url(../img/mixwhite.png);
  background-size: 50px;
  width: 60px;
  height: 60px;
  border-radius: 3px;
  top: 4px;
  background-size: 38px;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #2cae4a;
  top: 8px;
}

.createselect_team {
  position: absolute;
  background-color: #fff;
  width: 100%;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.52);
  padding: 14px;
  min-height: 300px;
  z-index: 9;
  display: none;
}

.creategenderdiv .createinput {
  width: 0px !important;
}

.createselect_search {
}

.createselect_search input {
  border: 0;
  border-bottom-color: currentcolor;
  border-bottom-style: none;
  border-bottom-width: 0px;
  border-bottom: 1px solid #ddd9d9;
  width: 100%;
  background-color: #f5f5f5;
  padding: 13px;
  padding-left: 45px;
}

.createselect_search a {
  position: absolute;
  left: 27px;
  top: 26px;
}

.createselect_search a img {
  width: 23px;
}

.ct_selectsports {
}

.ct_selectsports li {
  display: flex;
  align-items: end;
}

.ct_selectsports li span {
  display: inline-block;
  border: 1px solid #999;
  min-width: 50px;
  min-height: 50px;
  border-radius: 51px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: 16px;
  min-width: 45px;
  min-height: 45px;
  background-size: 35px;
  margin-top: 16px;
}

.ct_selectsports li p {
  width: 100%;
  border-bottom: 1px solid #e6dfdf;
  padding-bottom: 16px;
  position: relative;
  top: 17px;
  padding-top: 5px;
}

.ct_selectsports li p:hover {
  color: #2cae4a;
}

.createselect_team .ct_selectsports {
  height: 245px;
  overflow-y: auto;
}

.addalert {
  position: relative;
  background-color: #edf8ef;
  border: 1px solid #2cae4a;
  border-radius: 4px;
  position: relative;
  padding: 15px;
  margin-top: 15px;
  margin-bottom: 11px;
}

.addalert label {
  font-size: 17px;
  font-weight: 500 !important;
  padding-right: 15px;
  margin-bottom: 12px;
  margin-top: 6px;
}

.addalert p {
  margin: 0;
  margin-bottom: 0px;
  color: #333;
  margin-bottom: 5px;
}

.addalert a {
  background-color: #e32e2e;
  display: inline-block;
  padding: 2px;
  border-radius: 3px;
  float: right;
  margin-top: 4px;
  margin-right: 5px;
  position: absolute;
  right: 0;
  top: 0;
}

.addalert a img {
  width: 17px;
}

.ct_buddies {
}

.ct_buddies li {
  background-color: #f7f7f7 !important;
}

.ct_buddies li .styled-input-single {
  right: -11px;
  top: -1px;
}

.ct_buddies li a .styled-input-single {
}

.ct_searchbuddies {
  position: relative;
  padding: 0px 15px;
  margin-bottom: 10px;
  margin-top: 15px;
}

.ct_searchbuddies input {
  border: 0;
  border-bottom-color: currentcolor;
  border-bottom-style: none;
  border-bottom-width: 0px;
  border-bottom: 1px solid #248e3d;
  width: 100%;
  padding: 12px 14px 12px 47px;
  background-color: #f5f5f5;
}

.ct_searchbuddies input:focus {
  background-color: #edf8ef;
}

.ct_searchbuddies a {
  position: absolute;
  left: 26px;
  top: 11px;
}

.ct_searchbuddies a img {
  width: 23px;
}

.addbuddies_count {
  padding: 0px 16px;
  display: inline-block;
  width: 100%;
  font-weight: 500 !important;
  font-size: 14px;
}

.addbuddies_count label {
  color: #2cae4a;
  font-weight: 500 !important;
  font-size: 14px;
  margin-left: 9px;
}

.ct_filter {
  float: right;
}

.ct_filter button {
  float: unset !important;
}

.ct_filter button {
}

.ct_drop a {
  float: right !important;
  position: relative;
  top: 1px;
  margin-left: 14px;
  border-left: 1px solid rgba(0, 0, 0, 0.18);
  padding-left: 10px;
  height: 29px;
  padding-top: 4px;
}

.ct_drop a img {
  width: 23px;
}

.at_ul {
  display: inline-block;
  width: 100%;
  text-align: center;
}

.at_ul li {
  float: left;
  width: 46%;
  border: 1px solid #248e3d;
  margin: 15px 0px 0px 15px;
  box-sizing: border-box;
  padding: 14px;
  border-radius: 10px;
  padding: 14px 14px 8px 14px;
}

.at_ul li .at_teamimg {
  display: block;
  width: 70px;
  height: 70px;
  border: 2px solid #2cae4a;
  border-radius: 80px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin: auto;
  margin-bottom: auto;
  margin-bottom: 8px;
}

.at_ul li label {
  color: #2cae4a;
  font-size: 16px;
  font-weight: 500 !important;
  margin-bottom: 1px;
}

.at_ul li p {
  margin: 0px;
  line-height: 17px;
  font-size: 13px;
  font-size: 14px;
}

.at_ul li p.at_date {
  margin-top: 3px;
  color: #888;
  margin-top: 7px;
}


.at_link {
    width: 100%;
}

.at_teamowner {
  border-top: 1px solid #e0e0e0;
  padding-top: 5px;
  margin-top: 6px;
}

.at_buddy {
  float: left;
  color: #333;
}

.at_owner {
  float: right;
  color: #2cae4a !important;
}

.at_teamowner span {
  font-size: 13px;
  font-weight: 500 !important;
  color: #333;
}

.at_sportsname {
  font-weight: 500 !important;
}

.createteam_div,
.createteamform {
}

.skills {
  display: inline-block;
  max-width: 100%;
  background-color: #fff;
  margin-top: -56px;
  padding: 5px 0px;
  margin-bottom: -45px;
  border: 1px solid #e4e4e4;
  border-bottom: 3px solid #2cae4a;
  width: 100%;
}

.sportsdiv_profile {
  background-color: #fff;
}

.sportsdiv_profile .sportsul {
}

.sportsdiv_profile .sportsul li {
  float: left;
  width: 29%;
  margin: 2%;
  padding: 8px 5px 5px 5px;
  height: 118px;
}

.sportsdiv_profile li .ss_spansportsimg {
  width: 57px;
  height: 57px;
  margin-top: 6px;
}

.sportsdiv_profile li .ss_spansportsimg img {
  height: 39px;
  margin-top: 8px;
}

.sportsdiv_profile .sportsul {
  padding: 0px;
  padding: 0px;
  border: 0;
  height: 422px;
  background-color: #fff;
}

.sportsul li {
}

.sportsdiv_profile .sportsul li .ss_sportstile {
  margin-top: 15px;
  font-size: 13px;
}

.modalbottom {
  padding-bottom: 0px !important;
}

.modal {
  z-index: 9999999999 !important;
}

.modal-sports .modal-body {
  padding: 8px !important;
}

.teamprofile_span {
  text-align: center;
  display: block;
  font-weight: 600;
  font-size: 18px;
  position: relative;
  z-index: 1;
  margin-bottom: -25px;
  top: 18px;
}

.editteamsec {
  float: right;
  display: inline-block;
  margin-right: 15px;
  position: relative;
  top: -45px;
  margin-right: 20px;
  margin-bottom: -32px;
}

.editteamsec a {
  display: inline-block;
  background-color: #c3c3c3;
  padding: 4px;
  width: 31px;
  height: 30px;
  text-align: center;
  padding: 0;
  border-radius: 40px;
  padding-top: 5px;
  padding-right: 0px;
}

.editteamsec a img {
  width: 5px;
  vertical-align: -4px;
  opacity: 0.8;
}

.editteamsec button {
  border: 0px;
  background-color: transparent;
  padding: 5px;
  border-radius: 5px;
  font-size: 16px;
  color: #2cae4a;
  margin-right: 8px;
}

.editteamsec button img {
  width: 20px;
  margin-right: 5px;
  vertical-align: -4px;
}

.teamprofiletab {
  border: 0px !important;
  display: flex;
}

.teamprofiletab li {
  width: 50%;
  float: left !important;
  margin: 0;
  text-align: center;
}

div.createteamform ul.teamprofiletab li a {
  border-radius: 0px !important;
  padding: 3px 3px 12px 1px !important;
  border: 0px !important;
  border-bottom: 2px solid #e6e6e6 !important;
  background-color: #fff !important;
  color: #333 !important;
  font-size: 16px;
}

.teamprofiletab li.active a,
.teamprofiletab li:focus a,
.createteamform ul.teamprofiletab li a:hover {
  background-color: #fff !important;
  color: #2cae4a !important;
}

.tp_content {
}

.teambox {
  background-color: #f7f7f7;
  border: 1px solid #dadada;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 15px 15px;
  display: inline-block;
  width: 100%;
  margin: 7px 0px 10px 0px;
}

.teambox ul {
  margin: 0;
}

.teambox ul li {
  float: left;
  width: 50%;
  min-height: 57px;
}

.teambox ul li span {
  display: block;
  font-size: 15px;
  color: #222;
  font-weight: 500;
  margin-bottom: 3px;
}

.teambox ul li label {
  font-weight: 300 !important;
  font-size: 14px;
  color: #666;
  letter-spacing: 0.4px;
  margin-top: -5px;
  line-height: 1.4;
}

.teambox ul li span label {
  font-weight: 600 !important;
  font-size: 15px;
}

.teambox ul li button.assigncaptain {
  border: 1px solid #ff9800;
  background-color: #ff9800;
  padding: 0px 10px;
  color: #fff;
  border-radius: 5px;
  margin-top: 8px;
}

.teambox ul li button.assigncaptain:hover {
  border: 1px solid #ff9800;
  color: #ff9800;
  background-color: transparent;
}

.tp_selected {
}

.tp_selected li {
  width: 29% !important;
}

.tp_selected li span {
  width: 30%;
}

.deletebuddy {
  position: absolute;
  right: 7px;
  top: 10px;
}

.deletebuddy img {
  width: 14px;
}

.ac_search {
  display: inline-block;
  width: 100%;
  position: relative;
}

.ac_search input {
  border: 0;
  border-bottom: 1px solid #dadada;
  width: 100%;
  padding-bottom: 14px;
}

.ac_search a {
  position: absolute;
  right: 3px;
  top: 2px;
}

.ac_search a img {
  width: 23px;
}

.playerlist {
  margin-top: 14px;
  overflow: scroll;
  height: 300px;
  overflow-x: hidden;
}

.playerlist li {
  width: 100%;
  position: relative;
  text-align: left !important;
  box-sizing: border-box;
  margin: 0px !important;
  padding: 5px 0px 5px 0px;
  border-bottom: 1px solid #eaeaea;
}

.playerlist .assignimg {
  display: inline-block;
  width: 45px;
  height: 45px;
  border: 1px solid #999;
  background-size: cover;
  border-radius: 50px;
  vertical-align: middle;
  margin-right: 12px;
}

.ac_name {
}

.playerlist .createinput {
  top: 13px;
}

.team_addmembers {
  display: flex;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 13px;
  padding-top: 7px;
}

.team_addmembers .teammember {
  flex: 1;
  font-size: 15px;
  font-weight: 500;
  color: #666 !important;
}

.team_addmembers .teammember span {
  font-size: 15px;
  font-weight: 500;
  color: #2cae4a;
}

.team_addmembers button {
  float: right;
  position: relative;
  z-index: 1;
  border: 0;
  background-color: #ff9800;
  color: #fff;
  font-size: 13px;
  padding: 5px 14px;
  border-radius: 4px;
  text-transform: capitalize;
  margin-top: -6px;
  border: 1px solid #fff;
}

.team_addmembers button:hover {
  background-color: #fff;
  color: #ff9800;
  border: 1px solid #ff9800;
}

.teamprofiletab {
}

#team_addplayers .ct_searchbuddies {
  position: relative;
  padding: 0px 0px;
  margin-bottom: 10px;
  margin-top: 0px;
}

#team_addplayers .ct_searchbuddies a {
  position: absolute;
  left: 9px;
  top: 11px;
}

#team_addplayers .ct_buddies li {
  width: 22%;
  float: left;
  margin: 1.5%;
}

#team_addplayers .addbuddies_count {
  padding: 0px !important;
}

#team_addplayers .ct_buddies {
  height: 390px;
  overflow: scroll;
  overflow-x: hidden;
}

.selectul {
  margin-bottom: 0;
  position: relative;
  top: 3px;
}

.selectul li {
  width: 100%;
  position: relative;
  margin: 0px !important;
  min-height: 36px;
}

.selectul li div {
  width: 100%;
  left: 0;
  top: 0;
  height: auto;
  padding: 4px 3px 9px 36px;
}

.selectul li label {
}

.btnclose {
  margin-left: 11px;
  background-color: #ababab;
  border: 1px solid #ababab;
}

.btnclose:hover {
  background-color: #666;
  border: 1px solid #666;
}

.modal-title {
  margin: 0;
  line-height: 1.42857143;
  font-weight: 500 !important;
  letter-spacing: 0.5px;
  color: #fff;
}

.confirmationdiv {
  text-align: center;
}

.confirmationdiv img {
  width: 70px;
  display: block;
  margin: auto;
  margin-bottom: 14px;
}

.confirmationdiv label {
  font-size: 18px;
}

.ct_filter {
  float: right;
}

.ct_filter .dropdown {
  display: inline-block;
  vertical-align: -12px;
}

.ct_drop .dropdown-menu {
  border-bottom: 3px solid #2cae4a;
  border-top: 3px solid #2cae4a;
  padding-bottom: 0;
  padding-top: 11px;
}

.ct_drop .dropdown-menu li {
}

.ct_drop .dropdown-menu li a {
  width: 100%;
  border: 0;
  padding: 6px 14px 3px 14px;
  border-bottom: 1px solid #d4d4d4;
}

.ct_drop .dropdown-menu li a:hover {
  background-color: #2cae4a !important;
  color: #fff !important;
}

.commenttime {
  display: block;
  font-size: 11px;
  padding-top: 5px;
  color: #848484;
  font-weight: 500;
  text-align: right;
  padding-right: 12px;
  text-transform: uppercase;
  letter-spacing: 0.2px;
}

.allcomemntul {
  height: 300px;
  overflow-x: hidden;
  overflow-y: scroll;
  text-align: center;
  padding: 0px 11px 0px 6px;
}

.allcomemntul li {
  margin: 0px 0px !important;
}

.commentext {
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 9px;
  margin-bottom: 0px;
  font-size: 17px;
  padding: 11px;
}

.commentext img {
  width: 20px;
  margin-right: 9px;
}

.commentext span {
  color: #2cae4a;
  font-weight: 500;
  font-size: 15px;
  margin-right: 2px;
}

.poupcomment {
  position: relative;
  width: 100%;
  display: inline-block;
  width: 100%;
  padding: 9px 65px 9px 9px;
  border-top: 1px solid #e0e0e0;
}

.poupcomment span {
  display: inline-block;
  width: 100%;
  position: relative;
}

.poupcomment img.poupupsimly {
  width: 27px;
  opacity: 0.6;
  position: absolute;
  left: 9px;
  top: 10px;
}

.poupcomment input {
  width: 100%;
  border: 1px solid #85ca94;
  height: 47px;
  border-radius: 50px;
  padding-left: 44px;
  padding-right: 18px;
}

.poupcomment button {
  border: 0;
  background-color: transparent;
  position: absolute;
  right: 10px;
  top: 8px;
}

.poupcomment button img {
  width: 46px;
}

.modalallcomment {
  padding: 0px !important;
}

.likedbuddybtn {
  float: right;
  background-color: #efefef;
  width: 80px;
  border: 1px solid #999;
  padding: 3px;
  border-radius: 5px;
  margin-top: 9px;
}

.alreadybuddy {
  background-color: #2cae4a;
  border: 1px solid #999;
  color: #fff;
}

.likedbuddy .ac_search a {
  top: 13px;
  right: 13px;
}

.likedbuddy .ac_search input {
  border-bottom: 1px solid #2cae4a;
}

.likedbuddy .ac_search input {
  border-bottom: 1px solid #2cae4a;
  background-color: #f3f3f3;
  padding-top: 13px;
  padding-left: 12px;
}

.likedbuddy .ac_search {
}

.profileabout {
  text-align: center;
  padding-top: 6px;
  display: inline-block;
  width: 100%;
}

.profileabout li {
  display: inline-block;
  width: 44.8%;
  margin: 1.5%;
}

.profileabout li a {
  min-height: 170px !important;
}

.profileabout li a img {
  width: 53px;
  margin-top: 38px;
}

ul.profileabout li label {
  margin: 0px !important;
  font-size: 14px !important;
  font-weight: 100 !important;
}

.commanhead span {
  color: #2cae4a;
  margin-left: 3px;
  font-weight: 500;
}

.switch {
  position: relative;
  display: inline-block;
  width: 44px;
  height: 14px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #bbbbbb;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 23px;
  width: 23px;
  left: -1px;
  bottom: -4px;
  background-color: dimgrey !important;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.5);
}

input:checked + .slider {
  background-color: #2cae4a;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

input:checked + .slider::before {
  background-color: #2cae4a !important;
}

.switchdiv {
  display: flex;
  margin-top: 15px;
}

.locationlabel {
  margin-left: 12px;
  position: relative;
  top: -2px;
  flex: 1;
}

.btncroadcast {
  float: right;
  margin-bottom: 15px;
  margin-top: 15px;
}

.createbroadcast_ul li .commaninputdiv {
  margin-top: 36px;
}

.switchli {
  margin-top: 26px;
}

.createbroadcast_ul li:last-child {
  /* 	border-top: 1px solid #e2e2e2; */
  /* margin-top: 31px; */
}

.broadcast_box {
  padding: 0px 0px;
  position: relative;
  /* z-index: 1; */
}

.createteamform ul.nav-tabs .active a {
  color: #2cae4a !important;
  border-bottom: 2px solid #2cae4a !important;
}

.broadcastdiv {
  display: flex;
}

.broadcastdrop .dropdown-toggle {
  position: relative;
  top: 15px;
  padding-left: 0px;
  margin-left: 11px;
  display: inline-block;
}

.broadcastdrop .dropdown-toggle img {
  width: 27px;
}

.broadcastdiv .teamprofiletab {
  flex: 1;
}

.broadcastdrop .dropdown-menu {
  position: absolute;
}

.broadcastlist_ul {
  display: inline-block;
  width: 100%;
}

.broadcastlist_ul li {
  float: left;
  width: 50%;
  min-height: 150px;
}

.broadcastlist_ul li:nth-child(odd) {
  padding-right: 7.5px;
  padding-bottom: 15px;
}

.broadcastlist_ul li:nth-child(even) {
  padding-left: 7.5px;
  padding-bottom: 15px;
}

.broadcastlist_inner {
  text-align: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}

.broadcastlist_inner::before {
  content: "";
  display: inline-block;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.46);
  z-index: 0;
}

.broadcastlist_inner .broad_dattime {
  display: block;
  color: #fff;
  font-size: 12px;
  letter-spacing: 0.4px;
  text-align: right;
  margin-right: 9px;
  padding-top: 6px;
  position: relative;
}

.broad_play {
  display: inline-block;
  margin: auto;
  margin-top: 30px;
  position: relative;
}

.broad_play img {
  width: 45px;
}

.broad_location {
  display: block;
  color: #fff;
  font-weight: 100 !important;
  font-size: 12px;
  letter-spacing: 0.4px;
  position: relative;
  padding-left: 26px;
  margin-top: 10px;
  text-align: left;
  padding-bottom: 7px;
}

.broad_location img {
  position: absolute;
  left: 8px;
  top: 2px;
}

.broadmap iframe {
  margin-bottom: 15px;
}

.bf_div {
  width: 270px !important;
  box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.5) !important;
  text-align: center !important;
}

.bf_div h3 {
  font-size: 17px;
  font-weight: 600 !important;
  margin-top: 7px;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 11px;
}

.bf_ul {
  padding: 0px 15px;
}

.bf_ul .commaninputdiv {
  margin-top: 27px;
}

.bf_button {
  padding-top: 15px;
}

.bf_ul li button {
  margin: 0px 6px;
}

.bf_ul .btncancel {
  background-color: #888;
  border: 1px solid #888;
}

.bf_ul .btncancel:hover {
  background-color: #444;
  border: 1px solid #444;
}

.sm_maindiv {
  text-align: center;
  display: inline-block;
  background-color: rgb(44, 174, 74, 0.2);
  width: 100%;
  border: 1px solid #bcdcc4;
  border-radius: 10px;
  overflow: hidden;
  background-color: #ebf9ef;
}

.sm_maindiv h3 {
  font-weight: 600 !important;
  font-size: 15px;
  margin: 0;
  color: #2cae4a;
  padding: 8px 2px;
  margin-bottom: 14px;
  border-bottom: 1px solid #c4deca;
}

.sm_ul {
    position: relative;
    z-index: 1;
    min-width: 100%;
}

.sm_ul li {
  display: inline-block;
  margin: 0px 17px;
  vertical-align: middle;
}

.sm_user {
  display: block;
  width: 55px;
  height: 55px;
  border-radius: 50px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 11px;
  margin: auto;
}

.sm_vs {
  display: block;
  margin: auto;
  margin-bottom: 4px;
  width: 39px;
}

.sm_ul li label {
  font-weight: 500 !important;
  white-space: nowrap;
  margin-top: 7px;
  white-space: pre-wrap;
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sm_timeinfo {
  border-top: 1px solid #c7d2c9;
  margin-top: 5px;
  padding: 7px 12px;
  display: inline-block;
  width: 100%;
}

.sm_timeleft {
  float: left;
  font-weight: 600;
  letter-spacing: 0.4px;
}

.sm_starttime {
  color: #d94040;
  font-weight: 600;
  float: right;
}

.upcommingmatches > li {
  margin-bottom: 10px;
}

.cc_inner_box .commaninputdiv {
  margin-top: 0px !important;
}

.cc_inner_box {
  margin-top: 14px;
  background-color: #ebf9ef;
  padding: 4px 10px 10px;
  border-radius: 10px;
  border: 1px solid #deece2;
}

.cc_box-head {
  font-weight: 600 !important;
  font-size: 14px;
  border-bottom: 1px solid #c3daca;
  width: 100%;
  padding-bottom: 1px;
}

.opponent_inner .createinput {
  width: unset !important;
  margin-right: 26px;
}

.createchallange li {
  /* margin-bottom: 20px; */
}

.cc_datetimeli {
  margin-bottom: 20px;
  display: flex;
}

.cc_datetimeli .commaninputdiv {
    flex: 1;
    margin-top: 17px;
}

.cc_datetimeli .ccdatetime1 {
  margin-right: 50px;
}

.crt_chalange_tprt {
  display: block;
}

.crt_chalange_tprt .ct_selectsports li {
  margin-bottom: -4px !important;
  position: relative;
}

.crt_chalange_tprt .ct_selectsports li:last-child {
  border-top: 0;
  margin-top: 0;
}

.crt_chalange_tprt .ct_selectsports li .createinput {
  position: absolute;
  right: 0 !important;
  width: 5px;
  margin-top: 17px;
  padding: 23px 0 20px 26px;
}

.cc_genderli .creategender {
  margin-top: 16px !important;
  margin-bottom: 62px !important;
}

.btndiv {
  display: inline-block;
  width: 100%;
  padding: 15px;
  border-top: 1px solid #e4e4e4;
}

.btnleft {
  float: left;
}

.btnright {
  float: right;
}

.challangebutton {
  display: flex;
}

.challangebutton > li {
  position: relative;
  flex: auto;
  text-align: center;
  margin-top: 11px;
  padding: 0px 0px 0px 15px;
}

.challangebutton > li a.cb_link {
  background-color: #2cae4a;
  padding: 5px 10px;
  border-radius: 5px;
  color: #fff !important;
  width: 100%;
  display: inline-block;
}

.challangebutton > li a.cb_link:hover {
  background-color: #248e3d;
}

.challangebutton > li .ct_drop {
  margin: 0;
  margin-right: 13px;
  position: relative;
  top: 11px;
}

.challangebutton > li:last-child {
  width: 10px;
  display: contents;
}

.challangebutton > li .ct_drop a {
  border-left: 1px solid rgba(0, 0, 0, 0.18);
}

.challangebutton .ct_drop .dropdown-menu {
  padding-top: 8px;
  margin-top: 34px;
  padding-bottom: 0px;
}

.cl_box_ul {
  display: inline-block;
  width: 100%;
  padding: 15px 15px 0px 15px;
  border-top: 1px solid #eaeaea;
  margin-bottom: 0;
}

.cl_box_ul li {
  background-color: #ebf9ef;
  padding: 12px 11px 6px 11px;
  border-radius: 15px;
  margin-bottom: 15px;
  border: 1px solid #eae7e7;
}

.cl_info_div {
  display: inline-block;
  width: 100%;
  position: relative;
  padding-left: 73px;
  border-bottom: 1px solid #e6e6e6;
  min-height: 74px;
}

.cl_info_div .cl_userbg {
  display: inline-block;
  width: 60px;
  height: 60px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border: 1px solid #e2e2e2;
  border-radius: 80px;
  position: absolute;
  left: 0;
  top: 0;
}

.cl_info_div .cl_title {
  font-size: 14px;
  font-weight: 500 !important;
  display: inline-block;
  width: 100%;
  margin-top: 8px;
  line-height: 1.5;
}

.cl_info_div .cl_time {
}

.cl_info_div .cl_time img {
  width: 16px;
  margin-right: 8px;
  vertical-align: -2px;
}

.cl_info_div .cl_type {
  float: right;
}

.cl_info_div .cl_type span {
  color: #2cae4a;
}

.cl_location_div {
  display: flex;
  margin-top: 5px;
}

.cl_location_div p {
  flex: 1 1;
  margin: 0;
  position: relative;
  padding-left: 19px;
  line-height: 1.6;
  margin-bottom: 5px;
}

.cl_location_div p img {
  width: 12px;
  margin-right: 5px;
  position: absolute;
  left: 0px;
  top: 5px;
}

.cl_location_div span {
  margin-top: 4px;
}

.cl_location_div span span {
  color: #2cae4a;
}

.cp_profile {
}

.cp_profile .divpost {
  margin-top: 0;
}

.cp_profile .teambox {
}

.cp_profile .teambox > h3 {
  margin: 0;
  font-weight: 600 !important;
  font-size: 18px;
  border-bottom: 1px solid #dadada;
  padding-bottom: 12px;
  margin-bottom: 13px;
}

.teamprofile_div {
  padding: 0px 15px 5px 15px;
  margin-top: 9px;
}

.cp_uploadimg_div {
  text-align: center;
  margin-top: 25px;
}

.cp_uploadimg_div button {
}

.cp_uploadimg_div p {
  margin: 0;
  margin-top: 3px;
  color: #222;
  font-size: 15px;
}

.cpg_span {
  display: inline-block;
  width: 88px;
  height: 88px;
  background-position: center;
  border-radius: 11px;
  border: 1px solid #c4c7c4;
  background-size: cover;
  margin: 0px 7px 6px 0px;
}

.cpg_span a {
  display: inline-block;
  float: right;
  margin-right: 5px;
  margin-top: 4px;
}

.cpg_span a img {
  width: 16px;
}

.cpg_span a img {
}

.cpg_gallery {
  text-align: center;
}

.cpg_gallery h3 {
  text-align: left;
}

.cp_profile .sm_ul {
  display: inline-block;
  width: 100%;
  margin-top: 70px;
}

.cp_profile .sm_ul li label {
}

.multiplelabe {
  margin-top: 11px;
  color: #2cae4a;
  font-size: 15px;
  font-weight: 500 !important;
}

.cp_profilediv_new {
  display: inline-block;
  width: 100%;
  text-align: center;
  border-top: 1px solid #d0d0d0;
  margin-top: 23px;
  padding-top: 7px;
  margin-bottom: 15px;
}

.cp_profilediv_new button.requested {
  background-color: #e19339;
  float: unset !important;
  /*  margin: auto;
    display: block;  */
  position: relative;
  border: 1px solid #e19339;
  letter-spacing: 0.5px;
  left: 19px;
  top: 0px;
  font-weight: 400;
  font-size: 15px;
}

.cp_profilediv_new button.requested:hover {
  background-color: #fff !important;
  color: #e19339;
}

.cp_header {
}

.tournamentform {
}

.tournamentform .commaninputdiv {
  margin-top: 35px;
}

.tournamentform .creategender {
  margin-top: 16px !important;
}

.tournamentform .cc_box {
  margin-top: 24px;
}

.tournamentform .cc_datetimeli {
  margin-top: 15px;
}

.ct_textarea textarea.question + label > span {
  top: -86px;
  font-size: 15px;
}

textarea.question:valid + label {
  border-color: #d2d2d2;
}

.ct_textarea .commaninputdiv {
  margin-top: 66px;
}

.ct_textarea textarea {
  font-size: 14px;
  padding: unset !important;
}

.tourament_name {
  text-align: center;
  display: block;
  font-weight: 600;
  font-size: 18px;
  position: relative;
  z-index: 1;
  top: 16px;
}

.tourprofile {
  padding: 0px 15px;
}

.fullwidth {
  min-width: 100%;
}

.invite_tournament {
  display: inline-block;
  width: 100%;
  padding: 0px 15px 0px 0px;
  margin-bottom: 16px;
}

.invite_tournament label {
  display: inline-block;
  width: auto;
  position: relative;
  top: 2px;
}

.invite_tournament button {
  float: right;
}

.tp_removebuddy {
  position: absolute;
  right: 8px;
  top: 7px;
}

.tp_removebuddy img {
  width: 17px;
}

.modal-content {
  overflow: hidden;
}

.publicpost {
  float: right;
  position: relative;
  top: -26px;
  right: 10px;
  border-radius: 4px;
  background-color: #fff;
}

.publicpost option {
}

.videomodel {
  text-align: center;
}

.videomodel label {
  display: inline-block;
  margin: 0px 20px;
  font-size: 16px;
}

.videomodel label img,
.youtubelink_span img {
  display: block;
  width: 70px;
}

.tourdate {
  color: #2cae4a;
}

.youtubdiv {
  text-align: center;
  display: flex;
  background-color: #f3f3f3;
  padding: 15px;
  margin-top: 20px;
  border: 1px solid #e8e8e8;
}

.youtubdiv input {
  width: 100%;
  height: 47px;
  border-radius: 5px;
  border: 1px solid #e4e4e4;
  padding: 15px;
  margin-right: 15px;
}

.youtubdiv button {
}

.youtubelink_span {
  display: inline-block;
  margin: 0px 20px;
  font-size: 16px;
}

.btndark {
  border: 0px;
  background-color: #929292;
  color: #fff;
  padding: 5px 23px;
  border-radius: 5px;
}

.playdiatorprof {
}

.playdiatorprof .pro_username {
  font-size: 31px;
  font-weight: 600 !important;
  margin-top: 10px;
}

.aboutus {
  margin-top: 29px;
  position: relative;
  min-height: auto;
}

.aboutus button {
  position: absolute;
  right: 0px;
}

.pladiatorabout {
  padding: 15px;
}

.pladiatorabout h2 {
  margin: 0;
  font-size: 19px;
  font-weight: 600 !important;
  border-bottom: 1px solid #d8d8d8;
  padding-bottom: 10px;
  margin-top: 5px;
  margin-bottom: 13px;
}

.pladiatorabout h3 {
  margin-top: 30px;
  font-size: 18px;
  font-weight: 600 !important;
  color: #2cae4a;
  margin-bottom: 14px;
}

.pladiatorabout ul {
}

.aboutul {
  border-bottom: 0px;
  margin-bottom: 0px;
}

.pladiatorabout ul li {
  position: relative;
  padding-left: 28px;
  margin-bottom: 14px;
}

.pladiatorabout ul li::before {
  content: "";
  display: inline-block;
  position: absolute;
  left: 0;
  width: 15px;
  height: 6px;
  background-color: #ede029;
  top: 6px;
  border-radius: 10px;
}

.contactus_sec {
}

.containercontact {
  margin: 0;
  background-color: #fff;
  border: 1px solid #e4e4e4;
  border-radius: 5px;
  margin-top: -180px;
  padding: 41px 0px;
  box-shadow: 0px 3px 22px rgba(0, 0, 1, 0.08);
}

.contactbg {
  background-color: #ceefd6;
  min-height: 430px;
}

.getintouch {
  margin: 0;
  text-align: center;
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 40px;
  padding-bottom: 19px;
  font-weight: 600 !important;
  color: #333;
  font-size: 24px;
}

.contactul li input,
.contactul li textarea {
  width: 100%;
  background-color: #f1f1f1;
  border: 0;
  padding: 14px 16px;
  border: 1px solid #e6e6e6;
  margin-bottom: 20px;
  resize: none;
}

.contactul li input:focus,
.contactul li textarea:focus {
  background-color: #ffffff;
  border: 1px solid #2cae4a;
}

.contactul li textarea {
  min-height: 130px;
}

.contactul li button {
  font-size: 14px;
  padding: 7px 51px;
  margin-top: 30px;
}

.contactinfo li a {
  color: #444;
  font-size: 15px;
  position: relative;
  display: block;
  padding-left: 29px;
  margin-bottom: 15px;
}

.contactinfo li a i {
  position: absolute;
  left: 0px;
  top: 3px;
  font-size: 15px;
  color: #2cae4a;
}

.c_socialul {
  margin-bottom: 34px;
}

.c_socialul li {
  display: inline-block;
  margin-right: 10px;
}

.c_socialul li a {
  display: inline-block;
  width: 30px;
  height: 30px;
  text-align: center;
  border: 1px solid #666;
  line-height: 29px;
  border-radius: 60px;
  color: #666;
}

.c_socialul li a:hover {
  border: 1px solid #2cae4a;
  color: #2cae4a;
}

.contactifream {
  height: 251px;
  margin-bottom: 30px;
}

.fixturebox {
  border: 1px solid #2cae4a;
  border-radius: 9px;
  padding: 15px;
  margin: 28px 15px 28px 15px;
  position: relative;
  background-color: #f2fdf4;
  min-width: 216px;
  display: block;
  position: relative;
  z-index: 1;
}

.fixture_team {
  background-color: #fff;
  min-width: 100%;
  height: 37px;
  border: 1px solid #dae4dc;
  border-radius: 4px;
  margin-bottom: 12px;
  padding: 8px 7px 7px 41px;
  position: relative;
}

.fixture_team span {
  display: inline-block;
  width: 42px;
  height: 42px;
  position: absolute;
  left: -7px;
  top: -5px;
  border-radius: 50px;
  background-position: center;
  background-size: cover;
  border: 2px solid #fff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
}

.fixture_team label {
  height: 26px;
  overflow: hidden;
}

.fixture_date {
  position: relative;
}

.fixture_date input {
  border: 0;
  background-color: transparent;
  border-bottom: 1.5px solid #2cae4a;
  width: 100%;
  margin-top: 5px;
  padding-bottom: 8px;
  font-size: 13px;
  padding-right: 30px;
}

.fixture_date img {
  position: absolute;
  right: 0;
  top: 2px;
  width: 19px;
}

.fixturelvl_inline {
  display: block;
  vertical-align: middle;
  position: relative;
}

.fixtureul {
  display: flex;
  overflow-x: auto;
}

.fixtureul li {
  display: inline-block;
  position: relative;
}

.fixturelvl_li1 {
}

.fixturelvl_li2 {
}

.fixturelvl_li2 .fixturelvl_inline {
}

.fixturelvl_li2 .fixturebox {
  margin: 121px 0px 218px 0px;
}

.fixturelvl_li3 {
}

.fixturelvl_li3 .fixturebox {
  margin: 312px 0px 598px 0px;
}

.fixturelvl_li4 .fixturebox {
  margin: 688px 0px 1358px 0px;
}

.fixturelvl_li5 .fixturebox {
  margin-top: 1490px;
}

.fixturelvl_li1 .fixturelvl_inline::before,
.fixturelvl_li2 .fixturelvl_inline::before,
.fixturelvl_li3 .fixturelvl_inline::before,
.fixturelvl_li4 .fixturelvl_inline::before,
.fixturelvl_li5 .fixturelvl_inline::before {
  content: "";
  display: inline-block;
  height: 56px;
  position: absolute;
  right: -120px;
  width: 188px;
  top: 70px;
  z-index: 0;
  border: 1px solid #2cae4a;
  min-height: 208px;
  border-left: 0;
  border-bottom-right-radius: 11px;
  border-top-right-radius: 11px;
}

.fixturelvl_li2 .fixturelvl_inline::before {
  min-height: 388px;
}

.fixturelvl_li3 .fixturelvl_inline::before {
  min-height: 774px;
}

.fixturelvl_li4 .fixturelvl_inline::before {
  min-height: 1532px;
}

.fixtureul li:last-child .fixturelvl_inline:last-child::before {
  display: none;
}

.tournamentprofile_div {
  padding-right: 76px !important;
}

.livematch_feed {
  text-align: center;
  display: inline-block;
  width: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 15px;
  margin-bottom: 11px;
}

.livematch_name {
  display: block;
  font-weight: 600 !important;
  font-size: 21px;
}

.livematch_name span {
  color: #2cae4a;
  font-weight: 600 !important;
  font-size: 21px;
}

.livematch_date {
  display: block;
  font-weight: 600 !important;
  font-size: 16px;
  letter-spacing: 0.5px;
  font-weight: 500 !important;
  color: #2cae4a;
}

.livescoretag {
  font-size: 18px;
  font-weight: 500;
  border-radius: 6px;
  padding: 5px 18px;
  border: 1px solid #dd2121;
  display: block;
  width: 160px;
  letter-spacing: 0;
  margin: 8px auto 16px auto;
  color: #dd2121;
}

.livematch_ul {
  display: inline-block;
  width: 100%;
  margin-top: 25px;
  margin-bottom: 30px;
}

.livematch_ul li {
  display: inline-block;
  margin: 0px 23px;
  text-align: center;
  vertical-align: middle;
}

.livematch_ul li span {
  width: 70px;
  height: 50px;
  display: block;
  margin-bottom: 10px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: auto;
  margin-bottom: 9px;
  border: 1px solid #d8d8d8;
  border-radius: 6px;
}

.livematch_ul li label {
  font-size: 17px;
  font-weight: 500 !important;
  white-space: nowrap;
}

.livematch_ul li img {
  width: 37px !important;
}

.matchlocation {
  display: inline-block;
  font-size: 16px;
  font-weight: 400 !important;
  margin-bottom: 0px;
}

.matchdesc {
  font-size: 16px;
  font-weight: 400 !important;
  border-bottom: 1px solid #dedede;
  padding-bottom: 12px;
  margin-top: 0px;
}

.matchlocation img {
  margin-right: 5px;
  width: 14px;
}

.broadcast_date {
  margin-top: 29px;
  display: inline-block;
  font-size: 13px;
  color: #666;
}

.broadcast_label {
  color: #2cae4a;
  font-size: 15px;
}

.broadcast_maininfo {
  min-height: 340px;
  background-position: center;
  background-size: cover;
  margin-bottom: 10px;
  position: relative;
}

.broad_info {
  background-color: rgba(0, 0, 0, 0.63);
  position: absolute;
  right: 0;
  width: 100%;
  bottom: 0;
  padding: 15px;
  padding: 7px 9px 7px 10px;
}

.broadcast_title {
  color: #faea18;
  font-size: 16px;
  margin: 0;
}

.broadcast_decp {
  padding: 0px !important;
  color: #fff !important;
  margin-bottom: 2px !important;
}

.broadcast_location {
  padding: 0px !important;
  color: #fff !important;
  position: relative;
  padding-left: 21px !important;
}

.broadcast_location img {
  position: absolute;
  left: 0;
  top: 2px;
  width: 11px;
}

.broadcast_video .vidplaydiv {
  top: 40%;
}

.searchopacity {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.51);
  left: 0;
  top: 0;
  z-index: -1;
}

.livematch_feed {
}

.newsmain {
}

.newstitle {
  font-size: 16px;
  font-weight: 600 !important;
  padding: 12px 15px 0px 15px;
}

.newspara {
  padding: 0px 15px 0px 15px;
  line-height: 20px;
}

.newsimg_div {
  position: relative;
  margin-bottom: 9px;
}

.newsimg_div img {
  width: 100%;
  font-size: 16px;
  font-weight: 600 !important;
}

.newsimg_div span {
  display: inline-block;
  position: absolute;
  right: 10px;
  bottom: 10px;
  background-color: rgba(0, 0, 0, 0.64);
  border: 1px solid #999;
  color: #ddd;
  padding: 3px 14px;
  border-radius: 6px;
}

.schedulebox {
  margin-left: -8px;
  margin-top: 6px;
}

.schedulebox .styled-input-single {
  position: relative;
  width: 100%;
  margin-bottom: -5px;
}

.schedulebox .styled-input-single label {
  margin-top: -14px;
}

.tour_player {
  margin-top: 11px;
  margin-left: 10px;
}

.tourhr {
  margin-top: 15px;
  margin-bottom: 4px;
  border-top: 1px solid #d6ecdd;
}

.schedule_pad .cc_inner_box {
  margin: 0px 15px 15px 15px;
}

.invite_tournament label.tourpop_count {
  top: 4px;
  font-size: 17px;
  color: #2cae4a;
  text-align: center;
  width: 100%;
  margin-bottom: 19px;
}

.modal-backdrop {
  z-index: 999999 !important;
}

.tour_selectplayers li {
  width: 22% !important;
  margin: 1% !important;
}

.sche_tourbtn {
  text-align: right;
  padding-right: 0px;
  border-bottom: 1px solid #ececec;
  margin: 0px 15px 0px 15px;
  padding-bottom: 12px;
}

.sche_tourbtn .cmnbutton {
  margin-left: 15px;
}

.sche_tourbtn .cmnbutton img {
  width: 18px;
  margin-right: 9px;
}

.invite_tournament {
  margin-bottom: 0px;
}

.poollabel {
  top: 4px !important;
  font-size: 17px;
  color: #2cae4a !important;
  text-align: center;
  width: 100% !important;
  background-color: #2cae4a;
  padding: 8px;
  color: #fff !important;
  margin-bottom: 0px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.scoretable,
.scoretable thead th {
  text-align: center;
}

.scoretable .table > thead > tr > th:first-child {
  text-align: left;
}

.scoretable .table > tbody > tr > td:first-child {
  text-align: left !important;
}

.pooltable_tour {
  margin-bottom: 13px;
  background-color: #f1f1f1;
}

.pointstable_tour {
  padding-right: 0;
}

.scoretable .table > tbody > tr > td:first-child > span {
  background-image: url("../img/team.png");
  display: inline-block;
  width: 30px;
  height: 30px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  vertical-align: middle;
  margin-right: 4px;
  border-radius: 41px;
  border: 1px solid #cacaca;
}

.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th {
  vertical-align: middle !important;
  text-align: center;
}

.tourcompleted_match {
}

.tourcompleted_match li {
  width: 100% !important;
  margin: 0;
}

.tourcompleted_match .it_flgscore {
  color: #2cae4a !important;
  display: block;
  font-weight: 600 !important;
  font-size: 16px !important;
}

.tourcompleted_match ul li .lt_img {
  width: 90px;
  height: 90px;
  background-size: cover;
  border-radius: 63px;
  border: 2px solid #fff;
  box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.27);
}

.tourcompleted_match ul li .it_flgname {
  font-size: 16px;
  margin-top: 14px;
}

.tourcompleted_match .matchdatetime {
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.4px;
  position: relative;
  top: -3px;
  color: #2cae4a;
  background-color: #999;
  width: 100%;
  display: inline-block;
  background-color: #d9f7e0;
  margin-bottom: 15px;
  font-size: 17px;
  padding: 7px 0px;
  margin-top: -11px;
}

.completed_info {
  display: flex;
  padding: 7px 15px 15px 15px;
}

.completed_info p {
  flex: 1;
  margin: 0;
  line-height: 16px;
  font-weight: 500 !important;
}

.completed_date {
  text-align: left;
}

.completed_time {
  text-align: right;
}

.scoretour_match {
  display: block;
  color: #b7b7b7;
  font-size: 15px;
}

.tourcompleted_match .vsicon {
  margin-top: 40px;
  width: 46px;
}

.tour_matchwinner {
  position: absolute;
  left: 15.7%;
  width: 36px;
  margin-top: -16px;
}

div.createteamform ul.teamprofiletab li a {
  white-space: nowrap;
}

/* .createteamform .creategenderdiv {
    margin-top: 8px!important;
} */

.createteamform .creategender {
  margin-top: 14px !important;
}

.tourcompleted_match > li {
  border-radius: 12px;
  overflow: hidden;
}

.tour_dateul {
  margin-top: 20px;
}

.tour_dateul li {
  display: inline-block;
  background-color: #fff;
  padding: 2px 22px;
  border: 1px solid #c3c3c3;
  border-radius: 6px;
  margin: 4px 6px;
}

.tour_dateul li label {
  display: block;
  font-size: 12px;
  margin-bottom: -2px;
  font-weight: 500 !important;
}

.tour_dateul li span {
  font-size: 13px;
  color: #2cae4a;
  font-weight: 100;
}

.tour_feed_title {
  font-weight: 500 !important;
  font-size: 17px;
  width: 80%;
  color: #2cae4a;
}

.tour_feed_title span {
  font-weight: 500 !important;
  font-size: 16px;
  color: #555;
}

.tour_feed_actiondiv button {
  margin: 0px 10px;
}

.tour_inner_feed {
  margin-bottom: 10px;
}

.tour_inner_feed .teamimg {
  margin-top: 10px;
  margin-bottom: 7px;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.5);
  width: 90px;
  height: 90px;
}

.tour_inner_feed .tour_dateul {
  margin-top: 14px;
  margin-bottom: 3px;
}

.tournament_winner_div {
}

.tournament_winner_div h4 {
  font-weight: 500 !important;
  font-size: 20px;
  color: #2cae4a;
}

.tournament_winner_div label {
  font-weight: 500 !important;
  font-size: 16px;
  color: #444;
  width: 70%;
}

.tour_inner_feed .teamname {
  color: #4c4c4c;
}

.tour_inner_feed .teamname_winner {
  font-size: 20px;
}

.tour_inner_feed .teamlocation {
  font-size: 16px;
  padding: 0px 15px 10px 22px;
  font-weight: 400 !important;
}

.tour_inner_feed .teamlocation img {
  width: 13px;
  top: 1px;
  left: 0;
}

.tour_win_logo {
  margin-bottom: -30px !important;
}

.tour_mos .teamimg {
  margin-bottom: 17px;
}

.tour_mos .teamname_winner {
  letter-spacing: 0.4px;
  color: #2cae4a;
  font-size: 16px;
}

.dropbel {
  position: relative;
  margin-top: 20px;
}

.notificationbox {
}

.dropbel .bellnotify {
  /*margin-top: 14px;*/
}

.notiul {
}

.notiul li {
  color: #888;
  align-items: center;
  text-align: left;
  padding: 11px 10px 7px 10px;
  border-bottom: 1px solid #e0e0e0;
}

.notificationbox {
  padding: 0px;
  position: absolute;
  background-color: #fff;
  top: 38px;
  right: 27px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.5);
  min-width: 300px;
}

.notificationbox h3 {
  text-align: left;
  color: #2cae4a;
  font-size: 15px;
  background-color: #ffffff;
  padding: 12px 15px 9px 10px;
  font-weight: 500 !important;
  border-bottom: 1px solid #c1c1c1;
}

.dropbel button img {
  height: 21px;
  width: auto;
}

.notiul li a.notilink {
  display: block;
  padding-right: 28px;
  /* display: flex;
	padding-right: 26px; */
}

.notiul li a.notilink span {
  min-width: 38px;
  height: 38px;
  border-radius: 30px;
  border: 1px solid #c5c5c5;
}

.notiul li a.notilink label {
  /* color: #444;
	font-size: 12px;
	letter-spacing: 0.2px;
	line-height: 14px;
	height: 31px;
	overflow: hidden; */
  color: #444;
  font-size: 12px;
  letter-spacing: 0.2px;
  line-height: 14px;
  overflow: hidden;
  display: block;
}

.notificationbox .dropaccount {
  position: relative;
  margin-top: -42px !important;
}

.noti_action {
  text-align: left;
  margin-top: -5px;
  position: relative;
  right: -51px;
}

.noti_action a {
  margin-left: 13px;
  font-size: 13px;
  font-weight: 500;
}

.notificationbox .dropdown-toggle {
  margin-right: 10px;
}

.linkview {
  color: #2691ec;
}

.linkaccept {
  color: #2cae4a;
}

.linkreject {
  color: #e03a3a;
}
.messagedrop {
  margin-top: -65px !important;
}

.messagedrop a {
  padding: 6px 8px 6px 8px !important;
}

.messagedrop a:hover {
  background-color: #2cae4a !important;
  color: #fff !important;
}

.messagedrop li {
  padding: 0px;
}

.messagedrop .dropdown-menu {
  padding: 0px 0;
}

.overlaytoggle {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0);
  left: 0;
  z-index: -1;
  background: rgba(0, 0, 0, 0);
}

.notiloadmore {
  text-align: center;
  display: block;
  color: #1dac40;
  font-size: 14px;
  background-color: #edf8ef;
  padding: 5px;
  border-top: 1px solid #bae4c4;
}

.notiloadmore:hover {
  background-color: #1dac40;
  color: #fff !important;
}

.winnerul {
}

.winnerul li {
  width: 100% !important;
  text-align: center;
  min-height: auto !important;
}

.winner_user {
}

.winner_user img {
  width: 35px;
  margin: auto;
  display: block;
  margin-bottom: -20px;
}

.teamprofile_div .winner_user span {
  display: block !important;
  width: 100px;
  height: 100px;
  background-size: cover;
  background-position: center;
  border: 1px solid #2cae4a;
  border-bottom: 4px solid #2cae4a;
  border-radius: 370px;
  margin: auto;
}

.winnername {
  text-align: center;
  font-size: 17px !important;
  color: #333 !important;
  margin-bottom: 0px !important;
  margin-top: 8px;
}

.winnertext {
  color: #e19339 !important;
  font-size: 20px !important;
}

.winnerbox {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.broadcast_video {
  text-align: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 340px;
  margin-bottom: 15px;
}

.broadcast_video span {
  background-color: rgba(0, 0, 0, 0.5);
  border: 1px solid rgba(183, 183, 183, 0.48);
  color: #fff;
  padding: 2px 15px;
  border-radius: 40px;
  display: block;
  width: 100px;
  margin: auto;
  position: relative;
  top: 16px;
}

.tour_broadcast_update {
  display: inline-block;
  position: relative;
  top: 118px;
}

.tour_broadcast_update img {
}

.updatescore_action {
}

.updatescore_action .backbtn {
}

.updatescore_action .endgame {
}

.tableresponsive {
  padding: 0px 15px 15px 15px;
}

.tableresponsive table {
  width: 100%;
}

.tableresponsive td {
  padding: 2px 3px;
  border-top: 1px solid #2cae4a;
  border-right: 1px solid #2cae4a;
  text-align: center;
}

.tableresponsive td input {
  border: 0;
  border-bottom: 1px solid #999;
  width: 60px;
  text-align: center;
  padding: 0px 0px 0px 0px;
  margin-bottom: 4px;
}

.tableresponsive td input:focus {
  border-bottom: 1px solid #2cae4a;
  color: #2cae4a;
}

.tableresponsive th {
  vertical-align: middle !important;
  background-color: #2cae4a;
  color: #fff;
  font-weight: 400 !important;
  text-align: left;
  padding: 5px;
  padding: 9px !important;
  white-space: unset !important;
  text-align: center;
  min-width: 80px;
  white-space: nowrap !important;
}

.tableresponsive td {
  min-width: 80px;
  padding: 8px 0px;
}

.multi_plyr_ul {
  display: flex;
  overflow-x: scroll;
}

.multi_plyr_ul li label:nth-child(3) {
  margin-top: -15px;
}

.multi_plyr_ul li {
  margin: 0 auto;
}

.multi_plyr_ul li .sm_vs {
  margin-top: 24px;
}

.tableinner {
  border: 1px solid #2cae4a;
  border-radius: 11px;
  overflow-x: auto;
  overflow-y: hidden;
}

.table_actions {
  border-top: 1px solid #d4d4d4;
  margin-top: 16px;
  display: inline-block;
  width: 100%;
  padding: 15px 15px 15px 15px;
}

.table_actions a,
.table_actions button {
}

.table_actions a {
  background-color: #ed1919;
  border-color: #ed1919;
  margin-top: -1px;
  display: inline-block;
}

.table_actions a:hover {
  background-color: #a51111;
  border-color: #a51111;
  color: #fff !important;
}

.table_actions button {
  background-color: #2cae4a;
  float: right;
}

.tableinner table tbody tr td:first-child {
  font-weight: 500;
}

.reslutmodel {
}

.resultlogo {
  text-align: center;
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 4px;
  margin-bottom: 15px;
}

.resultlogo img {
  display: block;
  margin: auto;
  height: 92px;
}

.resultlogo label {
  font-size: 18px;
  text-transform: uppercase;
  color: #2cae4a;
}

.reslutmodel .modal-dialog {
  width: 400px;
}

.resultul {
}

.resultul li {
  width: 100%;
  margin: 0px !important;
}

.resultul li a {
  width: 100%;
  background-color: #e0e0e0;
  padding: 6px;
  display: flex !important;
  align-items: center;
  text-align: left;
  border-radius: 7px;
}

.resuserimg {
  background-position: center;
  background-size: cover;
  float: left;
  margin-right: 11px;
  width: 50px !important;
  height: 50px !important;
  border: 2px solid #e6e6e6 !important;
}

.resinfo {
}

.resinfo label {
  font-size: 16px;
  margin: 0;
}

.resinfo small {
  color: #8a8a8a;
  font-size: 12px;
}

.lostmsg {
  margin-top: 16px;
  text-align: left;
  display: block;
  color: #ed1919;
}

.lostmatch {
  background-color: #8a8a8a !important;
}

.lostmatch {
}

.modal-footer.table_actions .cmnbutton {
  float: left;
}

.btnsubmitright {
  float: right !important;
}

.winnerselect {
  background-color: #258e3d !important;
}

.winnerselect .resinfo label {
  color: #ffffff;
}

.winnerselect .resinfo small {
  color: #ffffff;
}

.reslutmodel .modal-dialog {
  margin-top: 58px;
}

.broadcast_box {
}

.winnerimg_td {
  width: 22px;
  margin-right: 3px;
}

.scorehead {
  margin: 0;
  padding: 0px 16px 15px 16px;
  font-weight: 600 !important;
  font-size: 18px;
}

.matchscore_table {
}

.broadcast_video {
  text-align: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 340px;
  margin-bottom: 15px;
}

.winnerdropdown_ul {
  display: inline-block;
  width: 100%;
  padding: 0px 15px;
  min-height: 140px;
}

.winnerdropdown_ul li label {
  display: block;
  font-size: 18px;
  color: #2cae4a;
  font-weight: 500 !important;
}

.winnerdropdown_ul li select,
.winnerdropdown_ul li input {
  display: inline-block;
  width: 100%;
  border: 0;
  border-bottom: 1px solid #999;
  padding: 0px 0px 8px 0px;
  text-align: left;
  font-size: 14px;
}

.winnerdropdown_ul li input:focus {
  border-bottom: 1px solid #2cae4a;
}

.winnerdropdown_ul li {
  margin-bottom: 17px;
}

.alertmsg {
  font-size: 16px;
  text-align: center;
  margin-top: 19px;
  letter-spacing: 0.4px;
}

.teambox .winner_user span {
  display: block !important;
  width: 100px;
  height: 100px;
  background-size: cover;
  background-position: center;
  border: 1px solid #2cae4a;
  border-bottom: 4px solid #2cae4a;
  border-radius: 370px;
  margin: auto;
}

.view_winner .winnerdropdown_ul {
  min-height: 72px;
}

.winnerbox_view {
  padding: 0px 15px;
}

.fr {
  float: right;
}

.fl {
  float: unset !important;
  float: left !important;
}

.winner_actions {
}

.backbtn {
  background-color: #a0a0a0 !important;
  border-color: #a0a0a0 !important;
}

.backbtn:hover {
  background-color: #848484 !important;
  border-color: #848484 !important;
}

.winner_actions .btred {
  margin-left: 15px;
  margin-top: 0px;
}

.editbtn {
  background-color: #ed9819 !important;
  border-color: #ed9819 !important;
}

.editbtn:hover {
  background-color: #ce8a24 !important;
  border-color: #ce8a24 !important;
}

input[disabled] {
  cursor: default;
  background-color: transparent;
}

.valueul {
  padding-left: 4px;
  display: flex !important;
}

.valueul li {
  text-align: left !important;
  width: 50%;
  margin: 0px 2% !important;
}

.valueul li label {
  display: block;
  color: #333;
  font-size: 15px;
  padding-left: 4px;
}

.valueul li select {
  display: block;
  width: 100%;
  border: 0;
  border-bottom: 1px solid #bbb;
  padding-bottom: 6px;
}

.loginformul {
}

.loginformul li {
  flex: 1;
}

.loginformul {
  padding: 10px;
  display: flex;
}

div.question {
  border-bottom: 1px solid #439439;
}

.modal-footer .cmnbutton {
  margin-left: 10px;
}

.reportul {
  text-align: left !important;
  margin-bottom: 17px;
}

.reportul li {
  margin: 0px !important;
  min-width: 116px;
  margin-right: 9px !important;
}

.reportul li .styled-input-single {
  position: relative;
  width: 100%;
  right: 11px;
}

.reportul .styled-input-single label:before {
  margin-top: 2px;
  left: 11px;
  top: 17px;
}

.reportul .styled-input-single label:after {
  left: 11px;
  top: 29px;
}

.reportlabel {
  display: block;
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 10px;
}

.reportlabel img {
  width: 23px;
  margin-right: 10px;
  vertical-align: -4px;
}

.reportfeed {
  display: flex;
  padding: 15px 10px;
}

.reportfeed li {
  flex: auto;
  margin-right: 5px;
}

.reportfeed li img {
  width: 37px;
  margin-top: 11px;
  position: relative;
  left: 1px;
  margin-right: -19px;
}

.reportfeed label {
  font-size: 18px;
  color: #444;
  margin: 0;
  font-weight: 500 !important;
}

.reportfeed p {
  margin: 0;
  font-size: 16px;
  margin-bottom: -12px;
  color: #2cae4a;
}

.reportfeed button {
  float: right;
  border: 0;
  background-color: #2cae4a;
  padding: 4px 24px;
  border-radius: 50px;
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  margin-top: 13px;
}

.matchanalysisover h3 {
  font-weight: 500 !important;
  font-size: 19px;
  margin: 0;
  padding: 0px 15px;
  margin-top: 15px;
  color: #333;
}

.chartheight {
  width: 97% !important;
  height: 500px;
  border: 1px solid #dedede;
  margin: auto;
  margin-top: 13px;
  border-radius: 8px;
  background-color: #f4fbf6;
  margin-bottom: 34px;
}

/*----------- D-106 css by Deepak-------- */

table tbody tr td div {
  display: inline-flex;
  margin-top: 0px;
  align-items: center;
}

table tbody tr .plusaction-D {
  width: 100%;
  padding: 0px 10px;
  border-radius: 3px;
  background-color: #2cae4a;
}

.plusaction-D label {
  font-size: 17px;
  font-weight: 400 !important;
  color: #fff;
  text-align: center;
  align-items: center;
}

table tbody tr td div img {
  width: 31px;
  margin-left: 15px;
}

.modal-bodyD {
  padding: 0px !important;
  padding-top: 10px !important;
  overflow-y: auto;
}

.list-D {
  display: inline-block;
  width: 100%;
  text-align: center;
}

.list-D li {
  margin-bottom: -26px !important;
  display: block !important;
}

.list-D li span {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: block;
  height: 51px;
  width: 50px;
  border: 1px solid #2cae4a;
  border-radius: 100px;
}

.list-D li p {
  border-bottom: 1px solid #e6dfdf;
  padding-bottom: 5px;
  position: relative;
  top: -33px;
  margin-left: 59px;
  text-align: left;
  padding-left: 15px;
}

.userlistgoal-d li {
  display: block !important;
  margin: 0px 0px !important;
  padding-left: 9px;
  padding-right: 10px;
}

.userlistgoal-d li span {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: inline-block;
  height: 51px;
  width: 50px;
  border: 1px solid #2cae4a;
  border-radius: 100px;
  float: left;
}

.userlistgoal-d li p {
  /*width: 81%; */
  border-bottom: 1px solid #e6dfdf;
  /* padding-bottom: 5px; */
  position: relative;
  top: 20px;
  /* padding-top: 5px; */
  margin-left: 59px;
  text-align: left;
  /* padding-left: 15px;*/
}

.modal-content {
  height: 480px;
  overflow-y: auto;
}

.search-D input {
  width: 92%;
  border: none;
  border-bottom: 1px solid #d2d2d2;
  margin-left: 18px;
  margin-bottom: 10px;
  padding-bottom: 7px;
}

.search-D {
  width: 100%;
  display: flex;
}

.search-D a {
  position: relative;
  top: auto;
  right: 15px;
}

.cardimg-d {
  display: inline-block;
  position: relative;
  top: -32px;
  right: -90px;
}

.cardimg-d img {
  width: 23px;
  height: 36px;
  float: right;
}

.redcard-d {
  margin-left: 10px;
}

.bachnum-d {
  width: 19px !important;
  height: 19px !important;
  background-color: #2cae4a;
  position: relative;
  top: -1em;
  left: 17em;
}

span.bachnum-d label {
  color: #fff;
}

.divborder-d {
  border: 1px solid #2cae4a;
  height: 340px;
  border-radius: 5px;
  overflow-y: auto;
}

/* .teamnamehead-d {
	text-align: center;
	background-color: #2cae4a;
	color: #ffffff;
} */

.teamnamehead-d {
  text-align: center;
  background-color: #2cae4a;
  position: sticky !important;
  top: 0;
  z-index: 1;
}

.teamnamehead-d p {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  padding-top: 8px;
}

.teamnamelist-d {
}

.teamnamelist-d img {
  border-radius: 39px;
  width: 42px;
  height: 42px;
  display: inline-block;
  margin-top: 17px;
  margin-left: -9px;
}

.teamnamelist-d p {
  display: inline-block;
  vertical-align: bottom;
  padding-left: 10px;
}

.teamnamelist-d div ul {
  margin-top: -14px;
}

.teamnamelist-d div li:first-child {
  margin-left: 56px;
  margin-top: -10px;
  font-size: 12px;
  display: inline-block;
  padding-top: 12px;
}

.colorgreen-d {
  display: contents !important;
  color: #2cae4a;
}

.teamnamelist-d div li:nth-child(2) {
  display: inline-block;
  margin-left: 8px;
}

.card-d {
  height: 30px;
  width: 20px;
  float: right;
  background-position: center;
  border-radius: 3px;
  margin-left: 6px;
  background-color: #dbdb04;
  position: relative;
  top: -27px;
  left: -54px;
}

.card-r {
  height: 30px;
  width: 20px;
  float: right;
  background-position: center;
  border-radius: 3px;
  margin-left: 6px;
  background-color: #bb3500;
  position: relative;
  top: -27px;
  left: -54px;
}

/* ::-webkit-scrollbar {
	display: none;
} */

.flex-container {
  width: 100%;
  min-height: 300px;
  margin: 0 auto;
  display: inline-blocks;
  /* Safari */
  display: flex;
  /* Standard syntax */
}

.flex-container .column {
  -webkit-flex: 1;
  /* Safari */
  -ms-flex: 1;
  /* IE 10 */
  flex: 1;
  /* Standard syntax */
}

.column.bg-alt {
  border-left: 1px solid #2cae4a;
}

.set {
  display: inline-block;
}

/*----------- D-106 end css by Deepak-------- */

/*----------- D-107 css by Deepak-------- */

.colorbg-d {
  color: #ed1919;
}

.btndone-d {
  width: 43px;
  height: 28px;
  border: none;
  border-radius: 3px;
  background-color: #2cae4a;
  color: #fff;
}

.btnblank-d {
  width: 43px;
  height: 28px;
  border: none;
  border-radius: 3px;
  background-color: #616161;
  color: #fff;
}

.conformmodel-d {
  height: 203px;
}

.editbtn-d {
  margin-left: unset !important;
  margin-left: unset !important;
  background-color: #27a2d2 !important;
  border: none;
}

.addbtn-d {
  width: 23px;
  height: 24px;
  background-color: #2cae4a;
  float: right;
  margin-right: 12px;
  font-size: 18px;
  color: #fff;
  border-radius: 3px;
}

.inputsize-d {
  width: 33px !important;
}

.baseballviolet-d {
  width: 114px;
  height: 26px;
  background-color: #6400a2;
  text-align: center;
  color: #fff;
  padding: 3px 34px 4px 34px;
  border-radius: 3px;
  margin: 10px 7px 10px 7px;
}

.baseballgreen-d {
  width: 114px;
  height: 26px;
  background-color: #2cae4a;
  text-align: center;
  color: #fff;
  padding: 3px 34px 4px 34px;
  border-radius: 3px;
  margin: 10px 7px 10px 7px;
}

.baseballred-d {
  width: 114px;
  height: 26px;
  background-color: #f53a3a;
  text-align: center;
  color: #fff;
  padding: 3px 34px 4px 34px;
  border-radius: 3px;
  margin: 10px 7px 10px 7px;
}

/* --------cricket--------- */

/* USER PROFILE PAGE */

.card {
  margin-top: 20px;
  padding: 30px;
  background-color: rgba(214, 224, 226, 0.2);
  -webkit-border-top-left-radius: 5px;
  -moz-border-top-left-radius: 5px;
  border-top-left-radius: 5px;
  -webkit-border-top-right-radius: 5px;
  -moz-border-top-right-radius: 5px;
  border-top-right-radius: 5px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.card.hovercard {
  position: relative;
  padding-top: 0;
  overflow: hidden;
  text-align: center;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 1);
}

.card.hovercard .card-background {
  height: 130px;
}

.card-background img {
  -webkit-filter: blur(25px);
  -moz-filter: blur(25px);
  -o-filter: blur(25px);
  -ms-filter: blur(25px);
  filter: blur(25px);
  margin-left: -100px;
  margin-top: -200px;
  min-width: 130%;
}

.card.hovercard .useravatar {
  position: absolute;
  top: 15px;
  left: 0;
  right: 0;
}

.card.hovercard .useravatar img {
  width: 100px;
  height: 100px;
  max-width: 100px;
  max-height: 100px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  border: 5px solid rgba(255, 255, 255, 0.5);
}

.card.hovercard .card-info {
  position: absolute;
  bottom: 14px;
  left: 0;
  right: 0;
}

.card.hovercard .card-info .card-title {
  padding: 0 5px;
  font-size: 20px;
  line-height: 1;
  color: #262626;
  background-color: rgba(255, 255, 255, 0.1);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.card.hovercard .card-info {
  overflow: hidden;
  font-size: 12px;
  line-height: 20px;
  color: #737373;
  text-overflow: ellipsis;
}

.card.hovercard .bottom {
  padding: 0 20px;
  margin-bottom: 17px;
}

.btn-pref .btn {
  -webkit-border-radius: 0 !important;
}

/* ------login form------- */

.sportsimg-d {
  text-align: center;
}

.sportsimg-d img {
  margin: 0 auto;
  margin-top: 39px;
}

.logoshield {
  text-align: center;
}

.logoshield img {
  width: 45%;
  margin: 0 auto;
}

.loginformul {
  display: flex;
}

.loginformul li {
}

.loginhead {
  display: block;
  text-align: center;
}

.loginhead label {
  padding-top: 22px;
  line-height: normal;
  margin: 0;
  color: #707070;
  padding-top: 10px;
  font-weight: 600 !important;
}

.loginhead h2 {
  font-size: 42px;
  color: #2cae4a;
  font-weight: 600 !important;
  line-height: 46px;
}

.loginhead p {
  text-align: center;
  color: #707070;
  line-height: 21px;
}

.loginul_form {
  position: relative;
  z-index: 9;
  box-sizing: border-box;
  width: 100%;
}

.inputicon-d {
  position: relative;
  top: -29px;
  left: 10px;
  border-right: 1px solid #989898;
  padding-right: 15px;
}

.inputiconlock-d {
  position: relative;
  top: -31px;
  left: 14px;
  border-right: 1px solid #989898;
  padding-right: 13px;
}

.loginul_form li input {
  padding-bottom: 6px;
}

.loginul_form li input label {
}

.commaninputdiv {
  /* margin-top: 50px; */
}

.loginhead_div {
  width: 100%;
}

.loginforgot {
  text-align: right;
}

.loginforgot a {
  display: inline-block;
  font-weight: 500;
  color: #888;
}

.loginforgot button {
  display: block;
  padding: 9px 5px;
  text-transform: uppercase;
  font-weight: 500;
  width: 235px;
  font-size: 15px;
  margin: auto;
  margin-top: auto;
  margin-top: 40px;
  box-shadow: 0px 3px 6px #00000029;
  color: #fff;
  border-radius: 70px;
  border: 0;
  background-image: linear-gradient(to right, #00af59, #d0df68);
}

.twobtn-d {
  display: block;
  margin-top: 21px;
}

.facebookbtn {
  display: inline-block;
}

.googlebtn {
  display: inline-block;
  float: right;
}

.facebookbtn-d {
  color: #fff;
  width: 229px;
  height: 39px;
  border: none;
  border-radius: 100px;
  background-color: #4267b2;
  text-align: center;
}

.facebookbtn-d img {
  float: left;
  padding-left: 13px;
  border-right: 1px solid #fff;
  padding-right: 12px;
}

.googlebtn-d {
  color: #fff;
  width: 229px;
  height: 39px;
  border: none;
  border-radius: 100px;
  background-color: #db4437;
  text-align: center;
}

.googlebtn-d img {
  float: left;
  padding-left: 12px;
  border-right: 1px solid #fff;
  padding-right: 12px;
  padding-top: 1px;
}

.signuptag {
  margin: 18px 0px;
  text-align: center;
  display: block;
}

.signuptag span {
  font-weight: 600;
  padding-left: 3px;
}

.storeimg-d {
  display: block;
  margin-bottom: 16px;
}

.appleimg {
  float: right;
}

.inputflag-d {
  position: relative;
  top: -30px;
  left: 10px;
  border-right: 1px solid #989898;
  padding-right: 9px;
}

input.question-d,
textarea.question-d {
  color: black;
  padding-left: 50px !important;
  font-size: 18px;
  font-weight: 300;
  border-radius: 2px;
  margin: 0;
  border: none;
  width: 100%;
  background: rgba(0, 0, 0, 0);
  transition: padding-top 0.2s ease, margin-top 0.2s ease;
  box-shadow: unset !important;
  overflow-x: hidden;
  /* Hack to make "rows" attribute apply in Firefox. */
  padding-bottom: 4px;
}

/* Underline and Placeholder */

input.question-d + label,
textarea.question-d + label {
  display: block;
  position: relative;
  white-space: nowrap;
  padding: 0;
  margin: 0;
  width: 100%;
  border-top: 1px solid #d2d2d2;
  -webkit-transition: width 0.4s ease;
  transition: width 0.4s ease;
  height: 0px;
}

input.question-d:focus + label,
textarea.question-d:focus + label {
  border-color: #2cae4a;
}

input.question-d:focus,
input.question-d:valid {
}

textarea.question-d:valid,
textarea.question-d:focus {
  margin-top: 35px;
}

input.question-d:focus + label > span,
input.question-d:valid + label > span {
  top: -65px !important;
  font-size: 14px;
  color: #2cae4a;
}

textarea.question-d:focus + label > span,
textarea.question-d:valid + label > span {
  top: -150px;
  font-size: 22px;
  color: #333;
}

input.question-d:valid + label,
textarea.question-d:valid + label {
  border-color: green;
}

input.question-d + label > span,
textarea.question-d + label > span {
  padding-left: 54px;
  font-weight: 500;
  margin: 0;
  position: absolute;
  color: #333;
  font-size: 15px;
  top: -35px !important;
  /* top: -34px; */
  left: 0px;
  z-index: -1;
  -webkit-transition: top 0.2s ease, font-size 0.2s ease, color 0.2s ease;
  transition: top 0.2s ease, font-size 0.2s ease, color 0.2s ease;
  top: -30px;
  color: #7d7d7d;
}

.loginlogo_text {
  display: inline-block;
}

.loginlogo_text img {
  width: 282px;
}

.logintext {
  position: absolute;
  top: 45%;
  transform: translateY(-50%);
}

.logintext h1 {
  font-size: 70px;
  font-weight: 600 !important;
  line-height: 81px;
  margin-bottom: 0;
  letter-spacing: 4.2px;
}

.logintext p {
  color: #f9e81b;
  font-weight: 500;
  letter-spacing: 0.4px;
  line-height: 27px;
  margin-top: 11px;
  font-size: 17px;
}

.downloaddiv {
  margin-top: 43px;
}

.downloaddiv button {
  background-color: transparent;
  border: 0;
  display: inline-block;
  vertical-align: middle;
  margin-right: 21px;
}

.downloaddiv button:hover {
  transform: scale(1.2);
}

.downloaddiv button img {
  width: 190px;
}

.loginformul {
  display: flex;
  padding: 15px;
}

.loginformli1 {
  flex: 1;
}

.loginformli2 a {
  font-size: 16px;
  font-weight: 600;
  color: #2cae4a;
  margin-top: 8px;
  display: inline-block;
}

.loginformli2 a img {
  width: 19px;
  margin-left: 5px;
}

/* ---------login form end--------- */

/* --------- Modal Box CSS -------------*/

.checkbox input[type="checkbox"],
.checkbox-inline input[type="checkbox"],
.radio input[type="radio"],
.radio-inline input[type="radio"] {
  position: relative !important;
  margin-left: 0px !important;
}

/*.loginul_form li.commaninputdiv {
    padding: 0 30px;
}*/

.form {
  padding: 0 30px;
}

.modal-btn {
  display: block;
  margin: 40px 0;
}

.modal-btn button {
  /*display: inline-block;*/
  padding: 10px 70px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 15px;
  margin: auto;
  box-shadow: 0px 3px 6px #00000029;
  color: #fff;
  border-radius: 70px;
  border: 0;
  background-image: linear-gradient(to right, #00af59, #d0df68);
  float: right;
}

@keyframes click-wave {
  0% {
    height: 40px;
    width: 40px;
    opacity: 0.35;
    position: relative;
  }

  100% {
    height: 200px;
    width: 200px;
    margin-left: -80px;
    margin-top: -80px;
    opacity: 0;
  }
}

.option-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  position: relative;
  top: 13.33333px;
  right: 0;
  bottom: 0;
  left: 0;
  height: 40px;
  width: 40px;
  transition: all 0.15s ease-out 0s;
  background: #cbd1d8;
  border: none;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  margin-right: 0.5rem;
  outline: none;
  position: relative;
  z-index: 1000;
}

.option-input:hover {
  background: #9faab7;
}

.option-input:checked {
  background: rgb(2, 0, 36);
  background: linear-gradient(
    0deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(0, 175, 89, 1) 0%,
    rgba(208, 223, 104, 1) 100%
  );
}

.option-input:checked::before {
  height: 25px;
  width: 25px;
  position: absolute;
  content: "✔";
  display: inline-block;
  font-size: 13.66667px;
  text-align: center;
  line-height: 27px;
}

.option-input:checked::after {
  -webkit-animation: click-wave 0.65s;
  -moz-animation: click-wave 0.65s;
  animation: click-wave 0.65s;
  background: #40e0d0;
  content: "";
  display: block;
  position: relative;
  z-index: 100;
}

.option-input.radio {
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: inherit;
  vertical-align: super;
  margin: 0 10px;
}

.option-input.radio::after {
  border-radius: 50%;
}

input[type="file"]:focus,
input[type="checkbox"]:focus,
input[type="radio"]:focus {
  outline: 0 !important;
  outline-offset: 0 !important;
}

/* --------- Modal Box CSS end -------------*/

/***********OTP Verification**********/

.pincode-input-container {
  display: inline-block;
  margin: 0 auto;
  width: 100%;
  text-align: center;
  margin-top: 30px;
}

.pincode-input-container input.first {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.pincode-input-container input.last {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-left-width: 0px;
}

.pincode-input-container input.mid {
  border-radius: 5px;
  border-left-width: 0px;
}

.pincode-input-text,
.form-control.pincode-input-text {
  width: 50px;
  height: 50px;
  display: inline-block;
  border: none;
  margin-right: 6px;
  -webkit-box-shadow: 3px 8px 20px -8px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 3px 8px 20px -8px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 5px 2px -5px rgba(0, 0, 0, 0.75);
  text-align: center;
  background: transparent;
  outline: none;
  border-bottom: 1px solid #ccc;
}

.pincode-input-error {
  clear: both;
}

.mt-10 {
  margin-top: 10%;
}

.show-w {
  display: block;
}

.hide-w {
  display: none;
}

/***********OTP Verification End**********/

.setspopup-d {
  text-align: center;
  margin-top: 15px;
  margin-bottom: 15px;
}

.setspopup-d label::before {
  border-radius: 50px;
}

.setspopup-d label::after {
  border-radius: 50px;
}

/* --------CRICKET D-107 --------- */

#tab2 {
  display: none;
}

.btn-primary {
  color: #333333 !important;
  background-color: #fff !important;
  border: #248e3d !important;
}

.btn-default {
  color: #333 !important;
  background-color: #fff !important;
  border-color: #248e3d !important;
}

.card {
  margin-top: 20px;
  padding: 30px;
  background-color: rgba(214, 224, 226, 0.2);
  -webkit-border-top-left-radius: 5px;
  -moz-border-top-left-radius: 5px;
  border-top-left-radius: 5px;
  -webkit-border-top-right-radius: 5px;
  -moz-border-top-right-radius: 5px;
  border-top-right-radius: 5px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.card.hovercard {
  position: relative;
  padding-top: 0;
  overflow: hidden;
  text-align: center;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 1);
}

.card.hovercard .card-background {
  height: 130px;
}

.card-background img {
  -webkit-filter: blur(25px);
  -moz-filter: blur(25px);
  -o-filter: blur(25px);
  -ms-filter: blur(25px);
  filter: blur(25px);
  margin-left: -100px;
  margin-top: -200px;
  min-width: 130%;
}

.card.hovercard .useravatar {
  position: absolute;
  top: 15px;
  left: 0;
  right: 0;
}

.card.hovercard .useravatar img {
  width: 100px;
  height: 100px;
  max-width: 100px;
  max-height: 100px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  border: 5px solid rgba(255, 255, 255, 0.5);
}

.card.hovercard .card-info {
  position: absolute;
  bottom: 14px;
  left: 0;
  right: 0;
}

.card.hovercard .card-info .card-title {
  padding: 0 5px;
  font-size: 20px;
  line-height: 1;
  color: #262626;
  background-color: rgba(255, 255, 255, 0.1);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.card.hovercard .card-info {
  overflow: hidden;
  font-size: 12px;
  line-height: 20px;
  color: #737373;
  text-overflow: ellipsis;
}

.card.hovercard .bottom {
  padding: 0 20px;
  margin-bottom: 17px;
}

.btn-pref .btn {
  -webkit-border-radius: 0 !important;
}

.tabsbtn-d {
  padding: 10px !important;
}

.well-d {
  background-color: #fff !important;
  border: none !important;
  padding: 15px !important;
}

.scorebox-d {
  width: 100%;
  background-color: #248e3d;
  padding: 8px;
  border-radius: 8px;
}

.teamname-d {
  color: #fff;
  font-size: 15px;
  float: left;
  display: contents;
}

.teamscore-d {
  color: #fff;
  font-size: 15px;
  float: right;
}

.innings-d {
  color: #f8e523;
  font-size: 14px;
  margin-top: 2px;
}

.score-d {
  float: right;
  color: #f8e523;
  font-size: 14px;
  margin-top: 2px;
}

.inningsscore-d {
}

.rundiv-d {
  width: 100%;
  border: 1px solid #248e3d;
  margin-top: 12px;
  padding: 12px;
  border-radius: 8px;
  overflow: hidden;
}

.rundiv-d label {
  display: block;
  border-bottom: 1px solid gainsboro;
  padding-bottom: 6px;
  font-size: 14px;
  font-weight: 600 !important;
}

.runball-d {
  display: inline-block;
  width: 30px;
  border: 1px solid #1e9039;
  text-align: center;
  margin-top: 10px;
  border-radius: 119px;
  height: 30px;
  vertical-align: middle;
  margin-right: 15px;
  padding-top: 5px;
  color: #333333;
  margin: 20px;
}

.thisOver {
  border: none !important;
  margin-top: 10px !important;
}

.scoreinput-d {
  width: 100%;
  height: 159px;
  border: 1px solid #248e3d;
  margin-top: 15px;
  border-radius: 8px;
}

.marg-d {
  border-bottom: 1px solid #d2d4d2;
  padding-bottom: 14px;
  margin: 8px 8px 0 8px !important;
  text-align: center;
}

.styled-input-single-d {
  position: relative;
  padding: 0px 0 0px 18px;
  text-align: left;
  margin-right: 26px;
}

.styled-input-single-d {
  display: inline-block;
  /* position: absolute; */
  right: -6px;
  top: 4px;
  /* width: 25px; */
  height: 25px;
}

.styled-input-single-d input[type="radio"],
.styled-input-single-d input[type="checkbox"] {
  /* position: absolute;  */
  top: 0;
  left: -9999px;
  visibility: hidden;
}

input[type="checkbox"],
input[type="radio"] {
  /* margin: 4px 0 0; */
  margin-top: 1px\9;
  line-height: normal;
}

.styled-input-single-d label {
  cursor: pointer;
}

.styled-input-single-d label::before,
.styled-input-single-d label::after {
  content: "";
  position: absolute;
  top: 50%;
  /* border-radius: 50%; */
}

.styled-input-single-d label::before {
  left: 4px;
  width: 20px;
  height: 20px;
  margin: -9px 0 0;
  background: #f7f7f7;
  box-shadow: 0 0 1px grey;
  background: #f0f0f0;
}

.styled-input-single-d input[type="radio"]:checked + label::after,
.styled-input-single-d input[type="checkbox"]:checked + label::after {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

.styled-input-single-d label::before,
.styled-input-single-d label::after {
  content: "";
  position: absolute;
  top: 50%;
  /* border-radius: 50%; */
}

.styled-input-single-d label::after {
  left: 4px;
  width: 20px;
  height: 20px;
  margin: -12px 0 0;
  opacity: 0;
  background-image: url(../img/check.jpg);
  -webkit-transform: translate3d(-40px, 0, 0) scale(0.5);
  transform: translate3d(-40px, 0, 0) scale(0.5);
  -webkit-transition: opacity 0.25s ease-in-out,
    -webkit-transform 0.25s ease-in-out;
  transition: opacity 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out;
  transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out;
  transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out,
    -webkit-transform 0.25s ease-in-out;
  top: 15px;
  background-position: center;
  background-size: contain;
}

.btnbatsman-d {
  border-right: 1px solid #d0d0d0;
  padding: 10px;
  text-align: center;
}

.swapbtn-d {
  background-color: #245470;
  color: #fff;
  padding: 6px;
  border: none;
  font-size: 14px;
}

.undobtn-d {
  color: #fff;
  border: none;
  font-size: 14px;
  background-image: linear-gradient(#f19839, #e96f0d);
  margin-top: 9px;
  padding: 5px 40px 5px 34px;
}

.btnrun-d {
  margin-top: 19px;
  text-align: center;
}

.runinput-d {
  display: inline-block;
  width: 30px;
  border: 1px solid #1e9039;
  text-align: center;
  margin-top: 10px;
  border-radius: 119px;
  height: 30px;
  vertical-align: middle;
  margin-right: 10px;
  padding-top: 5px;
  color: #333333;
}

.tablepad-d {
  padding: 0 !important;
  margin-top: 15px !important;
}

.tablepad-d td {
  border-right: none !important;
}

.welltab2-d {
  background-color: #fff !important;
  border: none !important;
  padding: 15px !important;
}

.styled-input-single-d label {
  font-weight: 500;
}

/* --------END CRICKET D-107 --------- */

.Setscoreback-d {
  background-color: #dc9605;
  border: none;
  padding: 4px 25px;
  margin: 15px;
  border-radius: 3px;
  color: #fff;
  font-size: 14px;
}

.Setscoresave-d {
  background-color: #2cae4a;
  border: none;
  padding: 4px 25px;
  margin: 15px;
  border-radius: 3px;
  color: #fff;
  font-size: 14px;
  float: right;
}

.addpointtennis {
    padding: 3px 8px;
    border-radius: 3px;
    border: none;
    background-color: #2cae4a;
    color: #fff;
    margin-left: 8px;
}

/*--------open challenge css---------*/

.openchallenge-d {
  height: auto;
  width: 100%;
  background-color: #f3fef6;
  text-align: center;
  padding: 11px;
}

.openchallenge-d h5 {
  border-bottom: 1px solid #16ab3a;
  padding-bottom: 11px;
  font-size: 16px;
  font-weight: 600 !important;
  color: #1dac40;
}

.openchallenge-d p {
  margin-bottom: auto;
}

.openchallenge-d span {
  color: #1dac40;
  font-size: 14px;
  font-weight: 600;
}

.openchallenge-btn {
  float: unset !important;
  position: unset !important;
  display: block;
  margin: 12px auto !important;
  padding: 5px 17px !important;
  text-align: center;
  border: none !important;
  background-color: #1dac40 !important;
  color: #fff;
  border-radius: 3px;
  font-size: 13px;
}

.openchallenge-btn:hover {
  background-color: #189637;
}

.openchallenge-date {
  text-align: center;
  margin-top: 10px;
  margin-bottom: auto;
}

.openchallenge-date span {
  color: #1dac40;
}

.openchallenge-location {
  text-align: center;
  padding: 0 !important;
}

.openchallenge-location img {
  margin-right: 5px;
}

/*--------End open challenge css---------*/

.non-session-d {
  display: block !important;
  background-color: unset !important;
}

.closeicn-d:before {
  font-size: 14px;
  padding: 6px;
}

#cameraClick img {
  vertical-align: middle;
  margin-top: -9px;
}

/*--------Basketball D-107 including football css---------*/

.score_basketball-d {
  padding: 4px 10px;
  border-radius: 3px;
  background-color: #2cae4a;
  margin: 4px;
  color: #fff;
}

/*--------End Basketball D-107 including football css---------*/

/*--------Golf D-107 css---------*/

.add_course_icon-d {
  border: none;
}

.add_course_icon-d i {
  font-size: 22px;
  color: #2cae4a;
  float: right;
  padding-bottom: 10px;
}

.select_courselist-d li:hover {
  background-color: #2cae4a;
}

.select_courselist-d li:hover {
  color: #ffffff;
}

.select_course-d {
  border: none;
}

.course_locationbg {
  background-color: #fff;
}

.select_course-d p {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 450;
  margin-top: 8px;
  color: #2cae4a;
}

.course_location-d {
  font-size: 16px;
  font-weight: 400;
  color: rgb(51, 51, 51);
}

.golf_inputvalue-d {
}

.golf_inputvalue-d td {
  border: none;
}

.holesno_golf-d {
  border: 1px solid #2cae4a;
  width: 31px;
  padding: 5px;
  border-radius: 65px;
  height: 31px;
  font-weight: 500;
  background-color: #e4f5e8;
}

/*--------END Golf D-107 css---------*/

/*--------challenge score feeds css---------*/

.feed_challenge-d {
  background-repeat: no-repeat;
  background-size: cover;
}

.feed_sportname {
  color: #333 !important;
}

.feeds_opponents_list {
  margin: 16px;
  text-align: center;
}

.feeds_opponents-d {
  display: flex;
  overflow-x: auto;
}

.feeds_opponents_list img {
  height: 60px;
  width: 60px;
  border-radius: 36px;
  display: none;
}

.feeds_opponents_list label {
  font-size: 14px;
  font-weight: 600;
  white-space: nowrap;
  width: 115px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.feeds_opponents_list p {
  margin-top: -3px;
  color: #1dac40;
  font-weight: 600;
}

.challenge_single_feeds {
  display: inline-flex;
}

.challenge_feeds_vs {
  margin-top: 26px;
}

.feeds_vsimg {
  height: 40px !important;
  border-radius: unset !important;
}

.feeds_vsimg + label {
  margin-top: 9px;
  color: #1dac40;
}

.single_opponent-d label {
  white-space: nowrap;
  width: 115px;
  overflow: hidden;
  text-overflow: ellipsis;
}

/*--------End challenge score feeds css---------*/

.tour_matchwinner2 {
  width: 36px;
  margin-top: -24px;
  position: absolute;
  left: 72%;
}

/*-------- tournament winner logo on team css (SSS) ---------*/

/* ------------------------ tournament  css by deepak ---------------------------*/

.back_white {
  height: auto;
  background-color: #ffffff;
  display: inline-block;
  width: 100%;
}

.straight_player {
  font-weight: 600 !important;
  text-transform: capitalize;
  color: #555;
  margin-top: 6px;
  margin-bottom: 1px;
  min-width: 95px;
  font-size: 16px;
  margin-top: 14px;
}

.straight_inputs {
  padding: 5px 26px 20px 26px;
}

.straight_ul {
  border-bottom: none !important;
}

.leauge_matchdiv {
  background-color: #f7f7f7;
}

.tourcompleted_match ul li .lt_img {
  width: 80px !important;
  height: 80px !important;
}

.straight_pad {
  margin-top: 11px;
}

.semi_headingdiv {
  margin: 20px 15px 15px 15px;
  background-color: #1dac40;
  padding: 9px;
  border-radius: 10px;
  border: 1px solid #deece2;
  text-align: center;
  color: white;
}

.semi_headingdiv label {
  font-size: 15px;
}

.pool-number {
  padding: 15px;
}

.pool-number label {
  width: 100%;
  border: 1px solid #a3e9b4;
  padding: 10px;
  background: #a3e9b4;
  text-align: center;
}

.organized_tour-btn {
  text-align: center;
  margin: 15px;
}

.organized_tour-btn button {
  font-weight: 500;
  width: 100%;
  text-align: center;
  display: block;
  background: #2cae4a;
  color: #fff;
  padding: 11px;
  text-transform: capitalize;
  font-size: 16px;
  border-radius: 3px;
  outline: none;
  border: none;
}

/*-------- End tournament  css by deepak ---------*/

/*--------tournament feeds css by deepak ---------*/

.tour-namefeeds {
  border: none !important;
}

.tour-winnertag {
  color: #ff6a00 !important;
  font-size: 17px;
}

.tour-feedsscore {
  color: #fb7f00;
  font-size: 18px;
}

.tour-feedsscore span {
  font-size: 17px;
  padding: 0px 20px;
  color: #fb7f00;
}

.toul-multiplayer {
  width: 100%;
  height: 207px;
  border: 1px solid #777;
  overflow: auto;
  border-radius: 10px;
  background: #ffffff78;
}

.tour-playerlist {
  display: inline-block;
  margin: 4px !important;
}

.tour-playerlist label {
  margin-top: 10px;
}

/*-------------- chat by deepak--------------*/

.imgchatme {
  height: 150px;
  width: 100% !important;
  border-radius: 5px;
}

.userimgthum {
  top: -57px !important;
}

.chatdetails {
  height: 341px;
  overflow-y: scroll;
}

.userchatdiv {
  width: 302px !important;
}

.infotag_chat {
  font-weight: 400;
  text-align: center;
  font-size: 10px;
  width: 75%;
  padding: 5px;
  background-color: #e5eaaa;
  border-radius: 10px;
  margin: 6px auto 0px;
  display: block;
  line-height: 17px;
}

.chattag_date {
  font-size: 10px;
  display: block;
  margin: 2px 0px 29px 0px;
  text-align: center;
  font-weight: 400;
}

.chatopenimg,
.chatdivopen {
  width: 380px;
}

.chatul li a span {
  vertical-align: top;
}

.namechat_side {
  display: inline-block;
}

.chatmsg_show {
  margin-top: -5px;
}

.namechat {
  margin: 0;
}

.chatside_tab {
  margin-bottom: 23px;
}

.chatuserli p {
  border-radius: 6px;
  border-bottom-left-radius: 0px !important;
}
.chatmeli p {
  border-radius: 6px;
  border-bottom-right-radius: 0px !important;
}

/*--------------End chat by deepak--------------*/

/*-------- End tournament feeds css by deepak ---------*/

/* -------tournament feeds responsive------- */

@media only screen and (max-width: 480px) {
  .tour-feedsscore span {
    display: contents;
  }

  .single_opponent-d {
    margin: unset !important;
  }
}

/* -------End tournament feeds responsive------- */

.addboard-d {
  background-color: #f33232;
  border: none;
  padding: 4px 16px;
  border-radius: 3px;
  color: #fff;
  font-size: 13px;
}
/* 
.carrominputdiv-d {
    height: auto;
}

.carromtapnum-d {
    display: inline-block !important;
    border: 1px solid #2cae4a; 
    padding: 5px 18px;
    border-radius: 4px;
    font-size: 14px;
    color: #2cae4a;
    box-shadow: 1px 2px 6px #00000029;
    margin: 9px !important
}

.carrombackbtn-d {
    background-color: #dc9605;
    border: none;
    padding: 4px 25px;
    margin: 15px;
    border-radius: 3px;
    color: #fff;
    font-size: 14px;
}

.carromsavebtn-d {
    background-color: #2cae4a;
    border: none;
    padding: 4px 25px;
    margin: 15px;
    border-radius: 3px;
    color: #fff;
    font-size: 14px;
    float: right;
} */

.carromtapnum-d:visited {
  background-color: #00af59;
}

/*--------END CARROM D-107---------*/

/*--------tournament  css by deepak ---------*/

.back_white {
  height: auto;
  background-color: #ffffff;
  display: inline-block;
  width: 100%;
}

.straight_player {
  font-weight: 600 !important;
  text-transform: capitalize;
  color: #555;
  margin-top: 6px;
  margin-bottom: 1px;
  min-width: 95px;
  font-size: 16px;
  margin-top: 14px;
}

.straight_inputs {
  padding: 5px 26px 20px 26px;
}

.straight_ul {
  border-bottom: none !important;
}

.leauge_matchdiv {
  background-color: #f7f7f7;
}

.tourcompleted_match ul li .lt_img {
  width: 80px !important;
  height: 80px !important;
}

.straight_pad {
  margin-top: 11px;
  clear: both;
}

.semi_headingdiv {
  margin: 20px 15px 15px 15px;
  background-color: #1dac40;
  padding: 9px;
  border-radius: 10px;
  border: 1px solid #deece2;
  text-align: center;
  color: white;
}

.semi_headingdiv label {
  font-size: 15px;
}

.pool-number {
  padding: 15px;
}

.pool-number label {
  width: 100%;
  border: 1px solid #a3e9b4;
  padding: 10px;
  background: #a3e9b4;
  text-align: center;
}

.organized_tour-btn {
  text-align: center;
  margin: 15px;
}

.organized_tour-btn button {
  font-weight: 500;
  width: 100%;
  text-align: center;
  display: block;
  background: #2cae4a;
  color: #fff;
  padding: 11px;
  text-transform: capitalize;
  font-size: 16px;
  border-radius: 3px;
  outline: none;
  border: none;
}

.allballsover {
  display: grid;
  overflow-x: scroll;
  grid-auto-flow: column;
  overflow-y: hidden;
  height: 100px;
  grid-auto-columns: max-content;
}

/*-------- End tournament  css by deepak ---------*/

/*-------------- chat by deepak--------------*/

.imgchatme {
  height: 150px;
  width: 100% !important;
  border-radius: 5px;
}

.userimgthum {
  top: -57px !important;
}

.chatdetails {
  height: 341px;
  overflow-y: scroll;
}

.userchatdiv {
  width: 302px !important;
}

.chattag_date {
  font-size: 10px;
  display: block;
  margin: 2px 0px 29px 0px;
  text-align: center;
  font-weight: 400;
}

.chatopenimg,
.chatdivopen {
  width: 380px;
}

.chatul li a span {
  vertical-align: top;
}

.namechat_side {
  display: inline-block;
}

.chatmsg_show {
  margin-top: -5px;
}

.namechat {
  margin: 0;
}

.chatside_tab {
  margin-bottom: 23px;
}

.chatuserli p,
.chatmeli p {
  border-radius: 6px;
}

/*--------------End chat by deepak--------------*/

/*--------------tt1 fixture by deepak--------------*/

.tournament-container {
  width: 100%;
  height: 75vh;
  overflow-x: scroll;
  overflow-y: scroll;
}

.tournament-headers {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  border-bottom: 1px solid #ccc;
}

.tournament-headers h3 {
  width: 25%;
  text-align: center;
  font-weight: 400;
  border-right: 1px dashed #ccc;
  margin: 0;
  padding: 1rem;
}

.tournament-brackets {
  display: flex;
  flex-direction: row;
  list-style-type: none;
  background: #fdfdfd;
  margin-bottom: 50px;
}

.bracket {
  padding-left: 0;
  display: flex;
  margin: 0;
  padding: 30px 0;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-around;
  list-style-type: none;
  flex: 1;
}

.team-item {
  background-color: #e8e8e8b3;
  padding: 10px;
  display: block;
  margin: 1.5rem 2.2rem;
  position: relative;
  vertical-align: middle;
  line-height: 2;
  text-align: center;
  width: 259px;
}

.team-item:after {
  content: "";
  border-color: #4f7a38;
  border-width: 2px;
  position: absolute;
  display: block;
  width: 29px;
  right: -29px;
}

.team-item:nth-of-type(odd):after {
  border-right-style: solid;
  border-top-style: solid;
  height: 100%;
  top: 50%;
}

.team-item:nth-of-type(even):after {
  border-right-style: solid;
  border-bottom-style: solid;
  height: 100%;
  top: -50%;
}

.team-item:before {
  content: "";
  border-top: 2px solid #4f7a38;
  position: absolute;
  height: 2px;
  width: 17px;
  left: -16px;
  top: 50%;
}

.bracket-2 .team-item:nth-of-type(odd):after {
  height: 200%;
  top: 50%;
}

.bracket-2 .team-item:nth-of-type(even):after {
  height: 200%;
  top: -150%;
}

.bracket-3 .team-item:nth-of-type(odd):after {
  height: 350%;
  top: 50%;
}

.bracket-3 .team-item:nth-of-type(even):after {
  height: 350%;
  top: -300%;
}

.bracket-4 .team-item:nth-of-type(odd):after {
  height: 700%;
  top: 50%;
}

.bracket-4 .team-item:nth-of-type(even):after {
  height: 700%;
  top: -650%;
}

.bracket:first-of-type .team-item:before {
  display: none;
}

.bracket-4 .team-item:after {
  display: none;
}

.bracket:last-of-type .team-item:before,
.bracket:last-of-type .team-item:after {
  display: none;
}

.team-item time {
  display: inline-block;
  background-color: #dbdbdb;
  font-size: 0.8rem;
  padding: 0 0.6rem;
}

#style-4::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

#style-4::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
  height: 7px;
}

#style-4::-webkit-scrollbar-thumb {
  background-color: #0d4756;
  border: 2px solid #16596b;
}

/*-------------- end tt1 fixture by deepak--------------*/

/* ----------ui fixed css date 10oct---------------- */

.scheduleDate {
  margin: 0 4px !important;
  padding: 10px !important;
}

.scheduleDate label {
  margin-bottom: 9px;
}

.scheduleDate span {
  line-height: 1.8;
  font-weight: 500 !important;
}

.tourna-date-schd {
  margin: 0 !important;
}

.uni_search::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.uni_search::-webkit-scrollbar {
  height: 6px;
  background-color: #f5f5f5;
}

.uni_search::-webkit-scrollbar-thumb {
  background-color: #2cae4a;
}

aside.emoji-picker-react {
  width: 100% !important;
  margin-top: 20px !important;
}

/*----full page notification----*/

.fullnoti_ul {
  height: 81vh;
  overflow-y: scroll;
  border: 1px solid #ffff;
  background: #fff;
}

.fullnoti_heading {
  padding-bottom: 30px !important;
  background: #fff;
  border-bottom: 1px solid #e6e6e6;
}

.unreadnoti {
  float: right;
}

.fullnoti_ul li {
  border-bottom: 1px solid #eaeaeadb;
  padding-top: 17px !important;
  min-height: 97px !important;
}

.full_notitime {
  position: relative;
  display: block;
  left: 50px;
  top: -8px;
}

.fullnoti_action {
  float: left;
  position: relative;
  right: -43px !important;
}

.accpt_datetime {
  display: block;
  margin-top: -7px;
}

.fullnoti_label {
  max-width: 400px;
}

.fullnoti_moreres {
  width: unset !important;
}
.Emogi123 {
  margin-top: -33px;
  margin-left: -14px;
  position: relative;
  top: 1px;
  left: 23px;
}

/* .cursorPointer
{
cursor: pointer;
} */

/*-------------- Page designing by deepak--------------*/

.club::before {
  background-image: url(../img/club.svg) !important;
  background-size: 41px !important;
}

.club::after {
  background-image: url(../img/club-white.svg) !important;
  background-size: 41px !important;
}

.corporate::after {
  background-image: url(../img/corporate-white.svg) !important;
  background-size: 41px !important;
}

.corporate::before {
  background-image: url(../img/corporate.svg) !important;
  background-size: 41px !important;
}

.educational::before {
  background-image: url(../img/educational.svg) !important;
  background-size: 41px !important;
}

.educational::after {
  background-image: url(../img/educational-white.svg) !important;
  background-size: 41px !important;
}

.create-page {
  margin: 0 auto;
  text-align: center;
  margin-top: 21px;
  /* margin-bottom: 17px; */
  border-bottom: 1px solid #dadada;
  padding-bottom: 19px;
}

.create-page button {
  color: #fff;
  background-color: #2cae4a;
  border: none;
  padding: 13px;
  width: 84%;
  border-radius: 48px;
}

/* ---------page profile----------- */

.bannerinner-page {
  background-image: url(../img/bannerimg.jpg);
  height: 43vh;
  margin-top: 21px;
  width: 100%;
  min-height: 364px;
  margin-top: 64px;
  display: inline-block;
  width: 100%;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bannerinner-page img {
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 364px;
  margin-top: 64px;
  display: inline-block;
  width: 100%;
  position: relative;
  text-align: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.pageprofile-img {
  top: -43px;
  left: 23px;
}

.pageprofile-img span {
  width: 150px;
  height: 150px;
  border: 6px solid #fff;
}

.pageprofile-img a {
  bottom: 6px;
  right: 10px;
  width: 40px;
  height: 40px;
  padding-left: 11px;
}

.page-heading {
  display: inline-block;
  position: absolute;
  /* width: 100%; */
  margin-left: 55px;
  margin-top: 3px;
}

.page-heading h4 {
  color: #1b732f;
  font-size: 29px !important;
}

.page-heading label {
  font-size: 17px;
  color: #656565;
  font-weight: 600;
  margin-bottom: 0;
}

.page-heading p {
  color: #888;
  font-size: 15px;
}

.btnlikepage {
  background: #139e33;
  border: none;
  padding: 8px 43px;
  font-size: 16px;
  color: #fff;
  border-radius: 40px;
  float: right;
  margin-top: 21px;
  margin-right: 13px;
}

.img-info-page {
  border-bottom: 1px solid #eaeaea;
  padding-bottom: -1px;
  height: 130px;
  margin-top: 5px;
  background: #fff;
}

.bottomsec-page {
  margin-top: 57px;
  padding: 0 !important;
}

.right-page-sec {
  margin-top: -46px;
  padding: 0 !important;
}

.uploadbanner-page {
  float: right;
  position: absolute;
  z-index: 1;
  margin-right: 19px;
  margin-top: 15px;
  border: 0;
  background-color: #e13939;
  color: #fff;
  font-size: 13px;
  font-weight: 600;
  padding: 7px 14px;
  border-radius: 4px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5);
  top: 67px;
  right: 15px;
}

.uploadbanner-page span {
  margin-right: 10px;
}

.left-page-sec {
  padding: 0 !important;
  padding-right: 10px !important;
}

.page-foll-name {
  font-size: 14px !important;
  margin-top: 8px;
  text-overflow: ellipsis;
  width: 72px;
}

.page-foll-img {
  width: 65px !important;
}

.page-team-li a {
  border-radius: 50px !important;
}

.add-icon-page {
  margin-top: 19px;
}

.add-team-page {
  box-shadow: 0px 0px 20px -4px #00000052;
}

.add-teamtxt-d {
  color: #2cae4a;
  font-weight: 500;
}

.md-top {
  margin-top: 10px !important;
}

.page-aboutbuddies {
  text-align: left;
  margin-left: 10px;
}

.page-aboutbuddies li {
  text-align: center;
}

.page_of_post {
  padding: 0 !important;
}

.page-info-left {
  font-size: 14px !important;
  margin-top: 10px !important;
}

.page-info-left + p {
  font-size: 14px !important;
}

.page-info-left label {
  min-width: 80px !important;
}

.page-info-left + p label {
  min-width: 80px !important;
}

.chall-view_show {
  display: inline-block;
}

.challng-img-view {
  width: 49px !important;
  height: 36px !important;
  border-radius: unset !important;
  margin-top: -48px;
}

/* .sticky-left
    {
        position:sticky;
        top: 88px;
    } */
.sticky-right {
  position: sticky;
  top: 88px;
}

/* Award winning responsive */

.award-main {
  display: block;
  border: 1px solid #2cae4a;
  margin: 15px;
  padding: 10px;
  border-radius: 5px;
}

.award-main p {
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  border-bottom: 1px solid #e4e4e4;
  padding-bottom: 7px;
  margin-bottom: 25px;
}

.award_btn {
  border: 1px solid #2cae4a;
  margin: 5px;
  width: 47%;
  padding: 8px;
  background: #2cae4a1f;
  border-radius: 5px;
  font-size: 14px;
  color: #000000;
  margin-left: 7px;
}

.greyout {
  border: 1px solid #cecece;
  background-color: #f9f9f9;
}

.award_btn:focus {
  border: 1px solid #fff;
  padding: 8px;
  background: #2cae4a;
  color: #fff;
}
.showWinnerInput {
  float: right;
  position: relative;
  top: -4px;

  width: 28px !important;
  margin-left: 20px;
}

.createPage_div {
  min-height: 260px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  text-align: center;
}

.createPage_div button {
    float: right;
    position: relative;
    z-index: 1;
    margin-right: 15px;
    margin-top: 15px;
    border: 0;
    background-color: #e13939;
    color: #fff;
    font-size: 13px;
    font-weight: 600;
    padding: 0px 14px;
    border-radius: 4px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.24);
    text-transform: capitalize;
    position: absolute;
    left: 15px;
    bottom: 15px;
    padding: 7px;

}

.pageCatName {
  position: absolute;
  top: 61px;
  left: 5px;
  font-size: 13px;
  font-weight: 500;
}
.pageCatSpace {
  margin-bottom: 88px;
}
.pa_img {
  background-size: cover !important;
}
.tt4scorefeeds {
  margin-top: -46px !important;
}
.smallnotiaction {
  margin-left: -8px;
}
.largenoti-date {
  display: block;
  outline: none;
  border: none !important;
  margin-top: 7px;
  font-size: 13px;
  font-weight: 500;
  color: #808080;
}
.largesize-action {
  text-align: left;
  position: relative;
  top: -26px;
  margin-left: 49px;
  float: left;
}
.largesize-action div a {
  margin-right: 9px;
}

/* -----intra-inters css---- */

.part-white {
  height: 160px;
  background: #fff;
}
.part-green {
  height: 160px;
  background-color: #4caf50;
}
.intra-txtdiv {
  display: inline-block;
  margin-left: 54px;
  width: 50% !important;
}
.inter-txtdiv {
  display: inline-block;
  margin-left: 0px !important;
  width: 50% !important;
}
.img-txtdiv {
  position: absolute;
  top: 95px;
  margin-left: 27px;
}
.img-txtdiv img {
  width: 201px;
}
.intra-inters {
  position: relative;
  /*box-shadow: 0px 0px 12px -6px;*/
  /*margin-top: 28px;*/
}
.intra-txtdiv h3 {
  color: #4caf50;
}
.intra-txtdiv p {
  margin-top: 47px;
  font-size: 15px;
  color: #fff;
}
.interimg {
  float: right !important;
  margin-right: 40px;
}
.part-offwhite {
  height: 160px;
  background-color: #f5f5f5;
}

/* -----intra-inters css End---- */

.btm-spcnone {
  margin-bottom: 0px !important;
}
.intra-enterbtn {
  padding: 6px 18px;
  border: none;
  background: #fff;
  color: #000;
  border-radius: 34px;
  font-size: 14px;
}
.timestamp {
  float: unset !important;
  position: unset !important;
  font-size: 11px;
  display: block;
  margin-top: 9px;
}
.timestamp1 {
  top: -1px !important;
  left: -2px !important;
}
.labelsmnoti {
  display: flex !important;
  line-height: unset !important;
}
.myprofile-post {
  margin-top: -4px !important;
}

/* -----page feeds css---- */

.pagefeedss label {
  white-space: unset !important;
  width: 100% !important;
  margin-top: 10px;
  font-size: 16px !important;
  font-weight: 600;
  color: #1dac40;
}
.page-feed-info {
  color: #333 !important;
  font-weight: 500 !important;
}
.pagefeedss-btn {
  display: inline-block !important;
  margin: 10px !important;
  width: 90px;
}

.page-feed-bg {
  background-image: url(../img/pagefeeds-bg.svg) !important;
  background-repeat: no-repeat;
  background-size: cover;
}
.team-namespace {
  padding-top: 15px;
}

/*-------QRCode model-----------*/

@media only screen and (min-width: 600px) {
  .Qrmodel-width {
    width: 850px !important;
  }
}
.qrblock {
  width: 100%;
  display: block;
  margin: 0 auto;
  text-align: center;
  margin-top: 63px;
}
.qrblock img {
  width: 75%;
}
.barcode-info img {
  width: 60px;
  border-radius: 82px;
  margin-right: 15px;
  height: 60px;
}
.barcode-info label{
  font-size: 16px;
  font-weight: 600;
  color: #333;
  position: relative;
  top: 15px;
}
.QrinfoLable {
  margin-top: 22px;
  font-size: 16px;
  font-weight: 550 !important;
}

.vertical-label-qr {
  vertical-align: 16px !important;
}
.qr-location {
  margin-top: 16px;
  font-weight: 550;
  font-size: 15px;
  display: block;
}
.qr-creator {
  color: #2cae4a;
  font-weight: 550;
  font-size: 15px;
  display: block;
}
.qr-left {
  background: #f5fff7;
  height: 404px;
}

.flexss {
  display: flex;
  align-items: center;
}
.app_btnn {
  width: 45%;
  color: #fff;
  margin: 20px 10px;
  text-align: left;
  border-radius: 5px;
  text-decoration: none;
  font-family: "Lucida Grande", sans-serif;
  font-size: 10px;
  text-transform: uppercase;
  margin-top: 0px;
  margin-left: 0px;
}
.app_btnn.blu {
  background-color: #101010;
  transition: background-color 0.25s linear;
}
.app_btnn.blu:hover {
  background-color: #454545;
}
.app_btnn i {
  width: 19%;
  text-align: center;
  font-size: 29px!important;
  margin-right: 25px;
}
.app_btnn .big-txt {
  font-size: 18px;
  text-transform: capitalize;
}
.app_btnn p {
  font-size: 12px;
  color: #fff;
  margin-bottom: 6px;
  margin-top: 5px;

}
.app_btnn:hover {
  color: #fff !important;
}
.qr-downloadbtn {
  border-top: 1px dashed #67cf7f;
  padding-top: 24px;
}
.heading-download {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 19px;
}
.Qr-sec-headng {
  display: none;
}

@media only screen and (max-width: 600px) {
  .qrcode-model {
    height: unset !important;
  }
}
@media only screen and (max-width: 992px) {
  .qrcode-model {
    height: auto !important;
    overflow-y: auto;
  }
  .qrblock img {
    width: 35%;
  }
  .Qr-model-body {
    display: flex !important;
    flex-direction: column-reverse;
  }
  .qrblock {
    margin-bottom: 51px;
  }
  .Qr-sec-headng {
    display: block;
    margin-top: -45px;
    font-size: 16px;
    font-weight: 500;
    color: #000;
    margin-bottom: 25px;
  }
}

.challengeQrButton:hover {
  background-color: #d9d9d9 !important;
  color: rgb(0, 0, 0);
}
.challengeQrButton {
  display: block;
  text-align: center;
  position: unset !important;
  background: #e8e8e8!important;
  color: rgb(45 41 41);
  width: 190px;
  font-size: 14px;
  float: left;
  padding: 5px !important;
  border-radius: 7px;
  height: 32px;
  margin-top: 1px;
  margin-bottom: 15px;
  border: 1px solid #e8e8e8;
}
.challengeQrButton i {
  border-right: none!important;
  margin-left: 10px;
  margin-right: -10px;
}
.teamQrButton {
  display: block;
  text-align: center;
  margin: 0 auto!important;
  float: unset !important;
  position: unset !important;
  padding: 7px!important;
  margin-top: -6px!important;
  background: #ed9900!important;
}
.teamQrButton i
{
  border-right: none!important;
}
.tournamentQRbtn
{
  display: block;
  text-align: center;
  margin: 0px auto!important;
  float: unset !important;
  position: unset !important;
  padding: 7px!important;
  margin-top: 28px!important;
  background: #ed9900!important;
}
.tournamentQRbtn i
{
border-right: none!important;
}

.team_buddy-remov
{
  width: unset!important;
}

.weightlifting-weight {
    margin-top: 37px !important;
}

.weightlifting-kilo {
    margin-top: 18px !important;
}

.weightlifting-kilo select {
    font-size: 13px !important;
    font-weight: 500 !important;
    margin-top: 12px !important;
    padding-bottom: 8px !important;
    border-bottom: 1px solid !important;
    border-color: #d2d2d2 !important;
    color: #7D7D7D !important;
}
.team-buddyTyp
{
  font-size: 13px!important;
  color: #05b52e!important;
}
.d_leftsec.d_leftsec_width::before
{
    content: "";
    background: #00000070;
    height: 100vh;
    width: 100%;
    position: fixed;
    z-index: -9999999;
    overflow: hidden;
    /* scroll-behavior: auto; */
    overflow-y: hidden;
}