.activityul li a {
    display: block;
    border-bottom: 1px solid #e0e0e0;
    padding: 13px 15px;
    color: #444444;
    font-size: 14px;
}

.activityul li a img {
    margin-right: 16px;
    width: 25px;
}

.profileul li a span {
    color: #2cae4a;
    display: inline-block;
    width: 100%;
    font-size: 18px;
}

a.livescore:hover,
.buddieshead a:hover,
.pro_useredit:hover,
.profileul li a:hover,
.pro_viewprofile:hover,
.loginformli2 a:hover {
    color: #333 !important;
}

.swiper-slide img {
    width: 100%;
}

.swiper-slide {
    align-items: start;
}

.swiper-container,
.swiper-wrapper {
    height: auto;
}

select.question {
    font-size: 18px;
    font-weight: 300;
    border-radius: 2px;
    margin: 0;
    border-left: none;
    border-top: none;
    border-right: none;
    border-bottom: 2px solid;
    border-color: #439439;
    padding-bottom: 14px;
    width: 100%;
    background: rgba(0, 0, 0, 0);
    transition: padding-top 0.2s ease, margin-top 0.2s ease;
    box-shadow: unset !important;
    overflow-x: hidden;
    /*color: #439439*/
}

.activityul li a:hover,
.dropdown-menu>li>a:hover {
    color: #000 !important;
    background-color: rgba(44, 174, 74, 0.13);
}

.topsearch input::placeholder {
    color: #fff;
}

.loginformul {
    padding: 3px;
}


/*.logintext p {
    color: #f9e81b;
    font-weight: 500;
    letter-spacing: 0.4px;
    line-height: 22px;
    margin-top: 11px;
    font-size: 15px;
    padding-right: 85px;
    text-align: justify;
}*/

.logintext {
    top: 50%;
}

.logintext p:last-child {
    padding-right: 85px;
    text-align: justify;
}

.logintext h1 {
    font-size: 35px;
    font-weight: 600 !important;
    line-height: 47px;
    margin-bottom: 0;
    letter-spacing: 4.2px;
}

div.question {
    font-size: 18px;
    font-weight: 300;
    border-radius: 2px;
    margin: 0;
    border-left: none;
    border-top: none;
    border-right: none;
    border-bottom: 1px solid #d2d2d2;
    border-color: #439439;
    /* padding-bottom: 14px; */
    width: 100%;
    background: rgba(0, 0, 0, 0);
    transition: padding-top 0.2s ease, margin-top 0.2s ease;
    box-shadow: unset !important;
    overflow-x: hidden;
}

.flag-select img {
    top: 1px !important;
}

.flag-select .arrow-down {
    float: right;
}

.flag-select .flag-options {
    position: relative !important;
}

.commaninputdiv {
    margin-top: 49px;
}

.teamdi:hover {
    cursor: pointer;
    box-shadow: 0 0 5px #333;
}

.login-page-alert {
    margin-bottom: 0;
    margin-top: 20px;
}


/*select.question option:nth-child(n){
color: #333;
}*/

.Demo__some-network {
    vertical-align: top;
    display: inline-block;
    margin-right: 15px;
    text-align: center;
}

.Demo__some-network__share-count {
    margin-top: 3px;
    font-size: 12px;
}

.Demo__some-network__share-button {
    cursor: pointer;
}

.Demo__some-network__share-button:hover:not(:active) {
    opacity: 0.75;
}

.Demo__some-network__custom-icon {
    width: 32px;
    height: 32px;
}

.affix {
    z-index: 99;
}

.editprofile .commaninputdiv label {
    color: #52af4a;
    margin-bottom: 14px;
}

.profilesec {
    position: relative;
    top: 70px
}

.editprofile .commaninputdiv {
    /* margin-top: 15px; */
}

.selectdiv select {
    background: transparent;
    border-bottom: 1px solid #f1f1f1;
    color: #666;
}

.react-datepicker-wrapper input {
    border: 0px !important;
}

.react-datepicker-wrapper {
    display: block !important;
}

.commanbox .commanhead button {
    padding: 6px 13px 4px 13px;
}

.editcomman_first {
    margin-top: 12px !important;
}

.commaninputdiv textarea {
    color: #666;
    font-size: 15px;
    padding-bottom: 6px;
}

input.question,
textarea.question {}

.Smartactionsbottom {
    z-index: 99;
}

#editprofile textarea {
    margin-top: 15px;
    resize: none;
}

.Smartactionstop {
    z-index: 99;
}

.selectdiv {
    margin-top: 0px;
}

.profilesec_inner button {
    z-index: 1;
    display: inline-block;
    width: 35px;
    height: 35px;
    border-radius: 40px;
    background-color: #1b732f;
    position: absolute;
    bottom: 0;
    right: 0;
    border: 2px solid #2cae4a;
    padding-top: 9px;
    padding-left: 1px;
    /* z-index: 1;
    display: inline-block;
    width: 35px;
    height: 35px;
    border-radius: 40px;
    background-color: #1b732f;
    position: absolute;
    bottom: 36px;
    right: 57px;
    border: 2px solid #2cae4a;
    padding-top: 9px;
    padding-left: 1px; */
}

@media only screen and (max-width: 1199px) {
    .skill {
        margin-top: -35px;
        margin-bottom: -19px;
    }

    .loginul_form {
        padding: 11px 14px;
        width: 100%;
    }
}

@media only screen and (max-width: 992px) {
    .skill {
        margin-top: -49px;
        margin-bottom: -19px;
    }
}

@media only screen and (max-width: 768px) {}

@media only screen and (max-width: 600px) {}

@media only screen and (max-width: 480px) {
    #searchLocation input {
        width: 100%;
        background-color: transparent;
        padding-left: 20px;
        font-size: 15px;
    }
}

.custom-file-input::-webkit-file-upload-button {
    visibility: hidden;
}

.custom-file-input::before {
    content: 'Select some files';
    display: inline-block;
    background: -webkit-linear-gradient(top, #f9f9f9, #e3e3e3);
    border: 1px solid #999;
    border-radius: 3px;
    padding: 5px 8px;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    text-shadow: 1px 1px #fff;
    font-weight: 700;
    font-size: 10pt;
}

.custom-file-input:hover::before {
    border-color: black;
}

.custom-file-input:active::before {
    background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

.skills {
    display: inline-block;
    max-width: 100%;
    background-color: #fff;
    margin-top: -56px;
    padding: 5px 0px;
    margin-bottom: -45px;
    border: 1px solid #e4e4e4;
    border-bottom: 3px solid #2cae4a;
    width: 100%;
}

.sportsdiv_profile {
    background-color: #fff;
}

.sportsdiv_profile .sportsul li {
    float: left;
    width: 29%;
    margin: 2%;
    padding: 8px 5px 5px 5px;
    height: 118px;
}

.sportsdiv_profile li .ss_spansportsimg {
    width: 57px;
    height: 57px;
    margin-top: 6px;
}

.sportsdiv_profile li .ss_spansportsimg img {
    height: 39px;
    margin-top: 8px;
}

.sportsdiv_profile .sportsul {
    padding: 0px;
    padding: 0px;
    border: 0;
    height: 422px;
    background-color: #fff;
}

.sportsdiv_profile .sportsul li .ss_sportstile {
    margin-top: 15px;
    font-size: 13px;
}

.modalbottom {
    padding-bottom: 0px !important;
}

.modal {
    z-index: 999999 !important;
}

.modal-sports .modal-body {
    padding: 8px !important;
}

.commanbox .commanhead a {
    padding: 6px 13px 4px 13px;
}

.commanbox .commanhead a {
    border: 1px solid #2cae4a;
    font-weight: 500;
    font-size: 12px;
    padding: 5px 13px;
    color: #2cae4a;
    background-color: #cbecd2;
    border-radius: 5px;
    float: right;
    position: relative;
    top: -4px;
}

.tagcheckin_div {
    padding-left: 15px !important;
}

#searchLocation input {
    width: 100%;
    background-color: transparent;
    border: 0px;
}


/* amit css*/

.postarea_div span {
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.4) !important;
    border: 1px solid #dcdcdc !important;
}

.showlocation {
    position: relative;
    top: 17px;
    padding: 5px;
}

.postul_imgvdo li div {
    top: 10px;
}

.livescore {
    top: -48px;
    margin-right: 15px;
    display: none;
}

.postarea_div textarea {
    margin-top: -1px;
    margin-bottom: -14px;
}

.postactiondiv ul li img {
    height: 30px;
}

.publicpost {
    float: unset;
    position: relative;
    top: 4px;
    right: 0px;
}

.postactiondiv button {
    padding: 3px 35px;
    margin-top: 1px;
}

.postarea_div span {
    background-color: #fff;
}

.publicpost {
    margin-left: 6px;
}

.tagcheckin_div {
    display: block;
}

.taguser_div {
    display: block;
}

@media only screen and (max-width: 1199px) {
    .postactiondiv ul li {
        margin-right: 3px;
    }

    .postactiondiv button {
        padding: 3px 35px;
    }
}

@media only screen and (max-width: 993px) {
    .postactiondiv button {
        float: left;
        margin-bottom: 12px;
    }
}

@media only screen and (max-width: 768px) {
    .postarea_div textarea {
        margin-top: 10px !important;
    }

    .postactiondiv button {
        float: right;
        margin-top: 4px!important;
    }
}

@media only screen and (max-width: 600px) {}

@media only screen and (max-width: 480px) {}

.error {
    color: #db2269;
    font-size: 1em;
    display: relative;
}


/* amit css*/


/* ------------------------------------------CUSTOM BY SSS------------------------------------*/

.ct_buddies li.deselect {
    background-color: #d9d9d9 !important
}

.buddieslist_ul li.deselect:hover {
    border: 1px solid #eaeaea !important;
}

.ct_buddies li {
    background-color: #ffffff !important;
}

.createteamform .creategender {
    margin-top: 26px !important;
}

.createteamform .creategenderdiv {
    margin-top: 11px;
}

.alert-shown {
    opacity: 1;
    transition: all 250ms linear;
}

.alert-hidden {
    opacity: 0;
    transition: all 250ms linear;
}

.creategenderdiv .male.styled-input-single label::before {
    background-image: url("../img/malegray.png");
}

.creategenderdiv .male.styled-input-single label::after {
    background-image: url("../img/malewhite.png");
}

.creategenderdiv .female.styled-input-single label::before {
    background-image: url("../img/femalegray.png");
}

.creategenderdiv .female.styled-input-single label::after {
    background-image: url("../img/femalewhite.png");
}

.creategenderdiv .mix.styled-input-single label::before {
    background-image: url("../img/mixgray.png");
    background-size: 56px;
}

.creategenderdiv .mix.styled-input-single label::after {
    background-image: url("../img/mixwhite.png");
    background-size: 50px;
}

.createselect_team {
    position: absolute;
    background-color: #fff;
    width: 100%;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.52);
    padding: 14px;
    min-height: 300px;
    z-index: 9;
    display: unset;
}

.newstitle {
    font-size: 16px;
    font-weight: 600 !important;
    padding: 12px 15px 0px 15px;
}

.newspara {
    padding: 0px 15px 0px 15px;
    line-height: 20px;
}

.newsimg_div {
    position: relative;
    margin-bottom: 9px;
}

.newsimg_div img {
    width: 100%;
    font-size: 16px;
    font-weight: 600 !important;
}

.newsimg_div span {
    display: inline-block;
    position: absolute;
    right: 10px;
    bottom: 10px;
    background-color: rgba(0, 0, 0, 0.64);
    border: 1px solid #999;
    color: #ddd;
    padding: 3px 14px;
    border-radius: 6px;
}

.react-confirm-alert-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    background: #333333f0 !important;
}

#searchdiv {
    display: unset;
}

.dropbel button {
    /* width: auto !important; */
}

.noti_action a {
    margin-left: 6px;
    font-size: 13px;
    font-weight: 500;
}

.noti_action span {
     float: unset!important;
    position: relative;
    top: -11px;
    left: 46px;
    font-size: 11px;
    display: block;
    margin-top: 9px;
}

.notificationbox .dropaccount {
    width: auto !important;
}




/* img upload css */

.ImgUp {
    font-family: sans-serif;
    text-align: center;
}

.alert-hidden {
    display: none;
}


/* ---------------------------------------CUSTOM BY SSS ----------------------------------------*/


/* Shivendra Share News Custom starts here*/

.teamdiv ul li .it_flgscore {
    white-space: unset !important;
}

.scoreupdate_para {
    margin-top: 0px;
}

.teamdiv ul {
    margin-bottom: 4px;
}

.scoreboxul>li>h3 {
    padding: 8px 5px;
}

.teamdi:hover {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
}

.scoreboxul>li {
    vertical-align: top;
}

.scoreboxul>li {
    border: 1px solid #efefef;
    border-radius: 5px;
    overflow: hidden;
}

.creategenderdiv .createinput.styled-input-single label::after {}


/* .createteamform .creategenderdiv {
    margin-top: 30px !important;
} */

/*.noti_action {*/
/*    text-align: right;*/
/*    margin-top: -5px;*/
/*    position: relative;*/
/*    right: -3px;*/
/*}*/

.reportfeed {
    display: flex;
    padding: 15px 10px;
}

.reportfeed li {
    flex: auto;
    margin-right: 5px;
}

.reportfeed li img {
    width: 37px;
    margin-top: 11px;
    position: relative;
    left: 1px;
    margin-right: -19px;
}

.reportfeed label {
    font-size: 18px;
    color: #444;
    margin: 0;
    font-weight: 500 !important;
}

.reportfeed p {
    margin: 0;
    font-size: 16px;
    margin-bottom: -12px;
    color: #2cae4a;
}

.reportfeed button {
    float: right;
    border: 0;
    background-color: #2cae4a;
    padding: 4px 24px;
    border-radius: 50px;
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    margin-top: 13px;
}

.btndark {
    border: 0px;
    background-color: #929292;
    color: #fff;
    padding: 6px 23px;
    border-radius: 5px;
}

.modal-footer .cmnbutton {
    margin-left: 10px;
}


/* Shivendra Share News Custom ends here*/


/*amit css starts here*/

.finalLogo {
    position: relative;
    height: 20px;
    width: 20px;
    top: 20px;
}


/*amit css ends here*/


/*Deepak Responsive Css*/


/* @media only screen and (max-width: 300px)
{
.livematch_ul li label {
font-size: 12px;
font-weight: 500!important;
white-space: unset;

}
.text-capitalize {
text-transform: capitalize;
font-size: 10px !important;
}

.text-capitalize {
text-transform: capitalize;
font-size: 12px !important;
display: none;
}
.dropaccount button img.t_accarrow {
margin-left: 6px;
}
.dropaccount {
margin-top: 1px !important;
width: 169px;
margin-left: -86px;
}

.topsearch input {

letter-spacing: 0.2px;
border-radius: 60px;
margin-top: 10px;
color: #fff;
font-size: 14px;
margin-bottom: 9px;
}
.dropnotify .bellnotify {
    display: inline-block;
    width: 50px !important;
    height: 50px;
    border-radius: 50px;
    background-color: #0d4252 !important;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.4) !important;
    padding: 17px 13px;
}

}



@media only screen and (max-width: 769px)
{
.livematch_ul li label {
font-size: 14px;
font-weight: 500 !important;
white-space: unset;
}
.teamscore_live
{
font-size: 12px !important;
}
.dropnotify .bellnotify {
    display: inline-block;
    width: 50px !important;
    height: 50px;
    border-radius: 50px;
    background-color: #0d4252 !important;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.4) !important;
    padding: 17px 13px;
}
}



@media only screen and (max-width: 992px)
{
.livematch_ul li label {
font-size: 16px;
font-weight: 500 !important;
white-space: unset;
}

.teamscore_live
{
font-size: 14px !important;
margin-top: 4px !important;
}


}



@media only screen and (max-width: 1199px)
{

.livematch_ul li label {
font-size: 14px;
font-weight: 500 !important;
white-space: unset !important;
max-width: 100px;
margin-bottom: 1px;
}
}


@media only screen and (max-width: 1366px)
{

.livematch_ul li label {
font-size: 16px;
font-weight: 500 !important;
white-space: unset !important;
max-width: 100px;
margin-bottom: 1px;
}
}
@media only screen and (max-width: 1604px)
{

.livematch_ul li label {
font-size: 16px;
font-weight: 500 !important;
white-space: unset !important;
max-width: 100px;
margin-bottom: 1px;
}
}

@media only screen and (min-width: 1605px)
{

.livematch_ul li label {
font-size: 16px;
font-weight: 500 !important;
white-space: unset !important;
max-width: 100px;
margin-bottom: 1px;
}
}
.explanerImg{
    max-width: 100%;
} */


/*Deepak Responsive Css END*/


/*Deepak Responsive Css*/

@media only screen and (min-width: 1605px) {
    .livematch_ul li label {
        font-size: 16px;
        font-weight: 500 !important;
        white-space: unset !important;
        max-width: 100px;
        margin-bottom: 1px;
    }
}

@media only screen and (max-width: 1604px) {
    .livematch_ul li label {
        font-size: 16px;
        font-weight: 500 !important;
        white-space: unset !important;
        max-width: 100px;
        margin-bottom: 1px;
    }

    .explainerImg {
        max-width: 100% !important;
    }
}

@media only screen and (max-width: 1366px) {
    .livematch_ul li label {
        font-size: 16px;
        font-weight: 500 !important;
        white-space: unset !important;
        max-width: 100px;
        margin-bottom: 1px;
    }
}

@media only screen and (max-width: 1199px) {
    .livematch_ul li label {
        font-size: 14px;
        font-weight: 500 !important;
        white-space: unset !important;
        max-width: 100px;
        margin-bottom: 1px;
    }
}

@media only screen and (max-width: 992px) {
    .livematch_ul li label {
        font-size: 16px;
        font-weight: 500 !important;
        white-space: unset;
    }

    .teamscore_live {
        font-size: 14px !important;
        margin-top: 4px !important;
    }

    .text-capitalize {
        text-transform: capitalize;
        display: none;
    }
}

@media only screen and (max-width: 769px) {
    .livematch_ul li label {
        font-size: 14px;
        font-weight: 500 !important;
        white-space: unset;
    }

    .teamscore_live {
        font-size: 12px !important;
    }

    .topsearch {
        position: relative;
        z-index: 9999;
    }

    .srachdiv_bg {
        padding: 0px 5px !important;
        margin-right: 11px;
    }

    .leftsecprof {
        z-index: -9;
    }
}

@media only screen and (max-width: 640px) {

    /* .topsearch input {
padding: 10px 42px 10px 15px;
letter-spacing: 0.2px;
border-radius: unset;
margin-top: 10px;
color: #fff;
font-size: 14px;
margin-bottom: 9px;
width: 192px;
}

.dropdiv .dropdown {
margin-top: 13px;
color: #fff;
margin-left: -76px;
}
*/
    .Smartactionstop {
        z-index: 9;
    }
}

@media only screen and (max-width: 600px) {
    .topsearch input {
        padding: 10px 42px 10px 15px;
        letter-spacing: 0.2px;
        border-radius: 0px !important;
        margin-top: 10px;
        color: #fff;
        font-size: 14px;
        margin-bottom: 9px;
    }

    .dropbel button {
        width: 50px !important;
    }
}

@media only screen and (max-width: 480px) {
    .topsearch input {
        border-radius: 0px;
    }

    .topsearch {
        margin: unset;
        margin-right: -96px;
    }

    .topsearch img {
        position: absolute;
        right: 8px;
        top: 28 px;
        width: 20px;
    }

    .dropdiv .dropdown {
        margin-top: -1px;
        color: #fff;

    }

    .dropbel button {
        width: 51px !important;
    }

    a:hover {
        /* color: #ffffff !important; */
    }
}

@media only screen and (max-width: 320px) {
    .livematch_ul li label {
        font-size: 12px;
        font-weight: 500 !important;
        white-space: unset;
    }

    .text-capitalize {
        text-transform: capitalize;
        font-size: 10px !important;
    }

    .text-capitalize {
        text-transform: capitalize;
        font-size: 12px !important;
        display: none;
    }

    .dropaccount button img.t_accarrow {
        margin-left: 6px;
    }

    .dropaccount {
        margin-top: 1px !important;
        width: 169px;
        margin-left: -86px;
    }

    .topsearch input {
        letter-spacing: 0.2px;
        border-radius: unset;
        margin-top: 10px;
        color: #fff;
        font-size: 12px;
        margin-bottom: 8px;
        padding-bottom: 12px
    }

    .topsearch {
        position: relative;
        z-index: 9999;
        margin: auto;
    }

    .srachdiv_bg {
        width: 255px;
        padding: 0px 5px !important;
    }

    .Smartactionstop {
        z-index: 9;
    }

    .topsearch img {
        position: absolute;
        right: 9px;
        top: 20px;
        width: 20px;
    }
}


/*Deepak Responsive Css END*/


/*deepak*/

.padzero-d {
    margin: 0 !important;
    padding: 0 !important;
}

.loginbg-d {
    width: 100%;
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    overflow-y: inherit;
}

.center-d {
    margin: auto;
    margin-top: -4px;
}

.loginlogo_text-d {
    display: block;
    align-items: center;
    text-align: center;
    margin-top: 0px;
    padding-top: 60px;
}

.loginlogo_text-d img {
    width: 340px;
}

.logintext-d {
    text-align: center;
}

.logintext-d h1 {
    text-align: center;
    margin-top: 70px;
    font-weight: 700;
    color: #fff;
    font-size: 46px;
    margin-bottom: -22px;
}

.logintext-d p {
    font-size: 24px;
    max-width: 529px;
    text-align: center;
    display: inline-block;
    margin-top: 51px;
    color: #faea1a;
    font-weight: 500;
}

.downloaddiv-d {
    margin-top: 14px;
}

.download-d {
    background-color: #fff;
    border: 1px solid #fff;
    display: inline-block;
    margin: 0px 15px;
    width: 166px;
    text-align: center;
    height: 47px;
    font-size: 17px;
    font-weight: 500;
    font-family: poppins;
    padding: 0px 15px;
    color: #016f0a;
    border-radius: 5px;
    transition: 0.3s;
}

.download-d:hover {
    transform: scale(1.2);
    cursor: pointer;
}

.backbtn-d {
    background-color: transparent;
    border: 2px solid #fff;
    display: inline-block;
    margin: 0px 15px;
    width: 166px;
    text-align: center;
    height: 47px;
    font-size: 17px;
    font-weight: 500;
    font-family: poppins;
    padding: 0px 15px;
    color: #ffffff;
    border-radius: 5px;
    transition: 0.3s;
}

.backbtn-d:hover {
    transform: scale(1.2);
    cursor: pointer;
}


/*responsive*/

@media screen and (max-width: 768px) {
    .backbtn-d {
        margin-left: 12px;
        margin-right: 0px;
    }

    .download-d {
        margin-right: 0px;
        margin-left: 12px;
    }
}

@media screen and (max-width: 600px) {
    .logintext-d h1 {
        margin-top: 55px;
        font-size: 35px;
    }

    .logintext-d p {
        font-size: 22px;
        /* margin-top: 51px; */
        margin: 51px 8px 13px 8px;
    }

    .download-d {
        margin-right: 12px;
    }

    .backbtn-d {
        margin-left: 0px;
    }
}

@media screen and (max-width: 480px) {
    .loginlogo_text-d img {
        width: 275px;
    }

    .logintext-d h1 {
        font-size: 28px;
        margin-left: 3px;
        margin-right: 3px;
        line-height: 36px;
    }

    .logintext-d p {
        font-size: 20px;
        margin-top: 40px;
        margin-left: 8px;
        margin-right: 8px;
    }

    .download-d {
        margin-right: 10px;
        margin-bottom: 10px;
        margin-left: 0px;
        width: 145px;
        font-size: 15px;
    }

    .backbtn-d {
        margin-right: 0;
        width: 145px;
        font-size: 15px;
        margin-left: 10px;
    }
}

@media screen and (max-width: 320px) {
    .loginlogo_text-d img {
        width: 227px;
    }

    .logintext-d h1 {
        text-align: center;
        margin-top: 49px;
        font-weight: 600;
        color: #fff;
        font-size: 34px;
        margin-bottom: -22px;
        line-height: 42px;
    }

    .logintext-d p {
        font-size: 17px;
        max-width: 529px;
        text-align: center;
        display: inline-block;
        margin-top: 51px;
        color: #faea1a;
        font-weight: 500;
        margin-right: 10px;
        margin-left: 10px;
    }

    .download-d {
        background-color: #fff;
        border: 1px solid #fff;
        display: inline-block;
        margin: 0px 15px;
        width: 158px;
        text-align: center;
        height: 47px;
        font-size: 16px;
        font-weight: 500;
        font-family: poppins;
        padding: 0px 15px;
        color: #016f0a;
        border-radius: 5px;
        transition: 0.3s;
        margin-bottom: 10px;
    }

    .backbtn-d {
        background-color: transparent;
        border: 2px solid #fff;
        display: inline-block;
        margin: 0px 15px;
        width: 158px;
        text-align: center;
        height: 47px;
        font-size: 16px;
        font-weight: 500;
        font-family: poppins;
        padding: 0px 15px;
        color: #ffffff;
        border-radius: 5px;
        transition: 0.3s;
    }
}

.explanerImg {
    max-width: 100%;
}

.cricket {
    width: 180px !important;
    height: auto !important;
}

.football {
    width: 125px !important;
    height: auto !important;
}

.download-d1 {
    background-color: rgb(252, 102, 83);
    border: 1px solid #fff;
    display: block;
    text-align: center;
    height: 45px;
    font-size: 15px;
    font-weight: 500;
    font-family: poppins;
    padding: 0 15px;
    color: #ffffff;
    border-radius: 5px;
    -webkit-transition: .3s;
    transition: .3s;
    margin: 0 auto;
    border-radius: 6px;
}

.download-d1:hover {
    transform: scale(1.2);
    cursor: pointer;
}

.downloaddiv-d1 {
    margin-top: 0px;
    padding-bottom: 17px;
    background-color: #440645;
    padding-top: 17px;
}

.scoretable .table>tbody>tr>td:first-child>span {
    background-image: unset;
    display: inline-block;
    width: unset;
    height: unset;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    vertical-align: middle;
    margin-right: 4px;
    border-radius: 41px;
    border: unset;
}

.button_div {
    display: flex;
    justify-content: space-around;
}

.cp_profilediv_new button.requested {
    margin: 7px !important;
    display: unset !important;
    position: unset!important;
}

.cl_box_ul li a {
    color: #333333;
}

.dropdown-menu li {
    min-height: 25px;
    display: block;
}

.winner {
    display: block;
    width: 70px;
    height: 70px;
    border-radius: 50px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 11px;
    margin: auto;
    border: 3px solid #2cae4a;
}

.winner-box {
    position: relative;
    z-index: 9;
    min-width: 100%;
    padding-top: 70px;
}

.winner-box h3 {
    color: #f9e721;
    font-weight: normal !important;
    margin: 0;
}

.winner-box li {
    display: inline-block;
    margin: 0px 17px;
    vertical-align: middle;
}

.winner-box label {
    color: #fff;
    font-size: 20px;
    margin-top: 20px;
}


/*amit*/


/* Chrome, Safari, Edge, Opera */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}


/* Firefox */

input[type=number] {
    -moz-appearance: textfield;
}

span {
    cursor: pointer;
}

.number {
    margin: 5px;
    display: flex;
    flex-direction: column-reverse;
}

.master {
    display: flex;
    margin-left: 30%;
}

.heading {
    padding: 5px 5px 5px 10px;
    color: green;
    text-transform: uppercase;
}

.minus,
.plus {
    background: #47cf73;
    width: 40px;
    height: 35px;
    border-radius: 4px;
    /* padding:8px 5px 8px 5px; */
    border: 1px solid #ddd;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    font-size: 25px;
    color: white;
}

.d104Input {
    height: 30px;
    width: 40px;
    text-align: center;
    font-size: 15px;
    /* padding:8px 5px 8px 5px; */
    border: 1px solid #ddd;
    border-radius: 4px;
    display: inline-block;
    vertical-align: middle;
}


/*amit*/


/* --------- Modal Box CSS -------------*/

.checkbox input[type=checkbox],
.checkbox-inline input[type=checkbox],
.radio input[type=radio],
.radio-inline input[type=radio] {
    position: relative !important;
    margin-left: 0px !important;
}


/*.loginul_form li.commaninputdiv {
    padding: 0 30px;
    }*/

.form {
    padding: 0 30px;
}

.modal-btn {
    display: block;
    margin: 40px 0;
}

.modal-btn button {
    /*display: inline-block;*/
    padding: 10px 70px;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 15px;
    margin: auto;
    box-shadow: 0px 3px 6px #00000029;
    color: #fff;
    border-radius: 70px;
    border: 0;
    background-image: linear-gradient(to right, #00AF59, #D0DF68);
    float: right;
}


/* --------- Modal Box CSS end -------------*/

.pac-container {
    z-index: 99999999 !important;
}

#searchLocation input {
    width: 100%;
    background-color: transparent;
    /* border-bottom: 1px solid green; */
    padding-left: 20px;
    font-size: 15px;
}

@keyframes click-wave {
    0% {
        height: 40px;
        width: 40px;
        opacity: 0.35;
        position: relative;
    }

    100% {
        height: 200px;
        width: 200px;
        margin-left: -80px;
        margin-top: -80px;
        opacity: 0;
    }
}

.option-input {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    position: relative;
    top: 13.33333px;
    right: 0;
    bottom: 0;
    left: 0;
    height: 40px;
    width: 40px;
    transition: all 0.15s ease-out 0s;
    background: #cbd1d8;
    border: none;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    margin-right: 0.5rem;
    outline: none;
    position: relative;
    z-index: 1000;
}

.option-input:hover {
    background: #9faab7;
}

.option-input:checked {
    background: rgb(2, 0, 36);
    background: linear-gradient(0deg, rgba(2, 0, 36, 1) 0%, rgba(0, 175, 89, 1) 0%, rgba(208, 223, 104, 1) 100%);
}

.option-input:checked::before {
    height: 25px;
    width: 25px;
    position: absolute;
    content: '✔';
    display: inline-block;
    font-size: 13.66667px;
    text-align: center;
    line-height: 27px;
}

.option-input:checked::after {
    -webkit-animation: click-wave 0.65s;
    -moz-animation: click-wave 0.65s;
    animation: click-wave 0.65s;
    background: #40e0d0;
    content: '';
    display: block;
    position: relative;
    z-index: 100;
}

.option-input.radio {
    border-radius: 50%;
    width: 25px;
    height: 25px;
    display: inherit;
    vertical-align: super;
    margin: 0 10px;
}

.option-input.radio::after {
    border-radius: 50%;
}

input[type=file]:focus,
input[type=checkbox]:focus,
input[type=radio]:focus {
    outline: 0 !important;
    outline-offset: 0 !important;
}

.modal-content {
    height: unset;
}

.fa {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: 19px !important;
    float: left;
    margin-left: 15px;
    /* padding: 5px; */
    border-right: 1px solid #FFF;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding-right: 13px;
}

.facebookbtn-d i {
    margin-top: 1px;
    color: #FFF;
}


/* .ReactModal__Content--after-open{
    border: 1px solid rgb(255, 255, 255, 0) !important;    
    background: rgba(255, 255, 255, 0) !important;
  } 
  .ReactModal__Overlay--after-open{
    background-color: rgba(255, 255, 255, 0.34) !important;
  }       */

.gender-d::before {
    width: 60px !important;
    height: 60px !important;
    border-radius: 3px;
    /* background-size: 38px; */
    background-position: center;
    background-repeat: no-repeat;
    background-color: #fff;
    box-shadow: unset;
    border: 1px solid
}

.gender-d::after {
    left: 4px;
    width: 60px !important;
    height: 60px !important;
    margin: -10px 0 0;
    opacity: 0;
    background-image: url(data:image/jpeg;base64,/9j/4QAYRXhpZgAASUkqAAgAAAAAAAAAAAAAAP/sABFEdWNreQAB…a+9me92LMzElmYnJZickkk5JPUnqZjZTOuMRGIjERiIxEYiMRGIjERiIxEYiMRGIjERiJ//9k=);
    transform: translate3d(-40px, 0, 0) scale(0.5);
    transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out;
    top: 8px !important;
    background-position: center;
    background-size: contain;
    background-color: green;
    background-repeat: no-repeat;
    border-radius: 3px !important;
}

.pac-target-input {
    border: none;
    width: 100%;
}

.errorMsg1 {
    color: red;
    line-height: 1;
}

.modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99 !important;
    background-color: #000000c7
}


/* .postdata img {
    margin-bottom: 9px;
    height: 55;
} */

.postdata img {
    height: unset;
}

.cmnbuttonf {
    border: 1px solid #2cae4a;
    font-weight: 500;
    font-size: 13px;
    padding: 5px 23px;
    color: #fff;
    background-color: #2cae4a;
    border-radius: 5px;
    text-transform: capitalize;
    margin-right: 285px;
}

.cmnbuttonup {
    border: 1px solid #2cae4a;
    font-weight: 500;
    font-size: 13px;
    padding: 5px 23px;
    color: #fff;
    background-color: #2cae4a;
    border-radius: 5px;
    text-transform: capitalize;
    margin-top: 15px;
}

.cmnbuttonup1 {
    border: 1px solid #e19339;
    font-weight: 500;
    font-size: 13px;
    padding: 5px 23px;
    color: #fff;
    background-color: #e19339;
    border-radius: 5px;
    text-transform: capitalize;
    margin-top: 15px;
    margin-right: 50px;
}

.scoreboxul>li {
    border: 1px solid #efefef;
    border-radius: 5px;
    overflow: scroll;
}

.react-datepicker__time-list {
    display: block !important;
}

.quater-final {
    background-color: #76fff1;
}

.semi-final {
    background-color: #9faab7;
}

.final {
    background-color: #48023cab;
}

.teamUserSelection .select1 {
    margin-bottom: 5px;
    margin-right: 15%;
    width: 35%;
    border: none;
    background: none;
    border-bottom: 2px solid #52af4a;
}

.teamUserSelection .select2 {
    margin-bottom: 5px;
    margin-left: 15%;
    width: 35%;
    border: none;
    background: none;
    border-bottom: 2px solid #52af4a;
}

.p_usertime {
    font-size: 12px;
    margin: 0px 0px;
    font-weight: 400;
    margin-bottom: -5px;

}

input.question:focus+label>span,
input.question:valid+label>span {
    top: -45px
}

.helpman {
    padding-top: 20px;
}


/* .modal-bodyD {
    
}

.li_selectsports {
	display: flex !important;
} */

.modal-bodySport {
    height: 350px !important;
}

.li_selectsports {
    display: flex !important;
}

.modal-bodyErrorPopup {
    padding: 0px;
    height: 58px;
    overflow: hidden;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 25px;
}

.disableddTeam {
    background-color: #e8e8e8;
}

.btn-pref btn-group btn-group-justified btn-group-lg {
    /* for cricket */
    z-index: 0;
}
.No_challenge
{
    font-size: 18px;
    line-height: 25px;
}
.padding12
{
    padding: 12px!important;
}
.pagecreate_ul ul li
{
    margin-top: 45px;
}
.pagecategory
{
    margin-top: -10px!important;
    margin-bottom: 108px!important;
}