*{ margin:0; padding: 0;}
.MenuStyle{
    margin-top:70px;
    width:100%;
    height:60px;
    
}
.MenuStyle ul{
    height:60px;
    display:flex;
    align-items: center;
    justify-content: space-around;
    
}     
.MenuStyle ul li{     
    list-style:none;  
    text-transform:uppercase;      
    
    
}
.MenuStyle ul li a{     
    font-size: 18px;
    color:#52af4a;  
   
}
/* a:hover {
    color: unset !important;
  } */