/*
* 1. common styles
*/
body {
  font-family: "Rubik";
  color: #74727a;
  font-size: 18px;
  /* line-height: 34px; */
  background-color: #ffffff;
}

a:active,
a:hover,
a:focus,
a:visited {
  text-decoration: none;
}
/*
* 2. header styles
*/
.header-navigation {
  background-color: transparent;
  margin-bottom: 0;
  border: none;
  border-radius: 0;
  padding: 0;
  position: relative;
}
.header-navigation .container {
  background: transparent;
  position: relative;
  display: block;
}
.header-navigation .container .logo-box {
  float: left;
  position: absolute;
  top: 50%;
  left: 15px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.header-navigation .container .navbar-brand {
  height: auto;
  margin: 0;
  line-height: 1;
}
.header-navigation .container .menu-toggler {
  display: none;
}
.header-navigation .container .right-side-box {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 15px;
}
.header-navigation .main-navigation {
  float: none;
  text-align: right;
  padding-right: 90px;
}
@media (min-width: 1200px) {
  .header-navigation .main-navigation {
    display: block !important;
  }
}

.header-navigation ul.navigation-box {
  margin: 0;
  padding: 0;
  list-style: none;
}
.header-navigation ul.navigation-box li a .sub-nav-toggler {
  display: none;
}
.header-navigation ul.navigation-box > li {
  position: relative;
  padding: 30px 0;
  display: inline-block;
  vertical-align: middle;
  /* Second Level Menu */
  /* Thrid Level Menu */
}
.header-navigation ul.navigation-box > li + li {
  margin-left: 50px;
}
.header-navigation ul.navigation-box > li:first-child {
  padding-left: 0;
}
.header-navigation ul.navigation-box > li:last-child {
  padding-right: 0;
}
.header-navigation ul.navigation-box > li > a {
    padding: 0;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
    position: relative;
    font-size: 16px;
    font-weight: 400;
    color: #74727a;
    position: relative;
    text-transform: uppercase;
}
.header-navigation ul.navigation-box > li.current > a, .header-navigation ul.navigation-box > li:hover > a {
  color: #2a2833;
  text-shadow: 1px 0 0 rgb(40, 173, 71);
}
.header-navigation ul.navigation-box > li > .sub-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 911;
  float: left;
  min-width: 220px;
  padding: 0px 0px;
  text-align: left;
  list-style: none;
  background-color:#28ad47;
  background-clip: padding-box;
  opacity: 0;
  border-radius: 0px;
  visibility: hidden;
  -webkit-transition: opacity .4s ease, visibility .4s ease;
  transition: opacity .4s ease, visibility .4s ease;
  -webkit-box-shadow: 0px 10px 18px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 10px 18px rgba(0, 0, 0, 0.1);
}
@media (min-width: 1200px) {
  .header-navigation ul.navigation-box > li > .sub-menu {
    display: block !important;
  }
}
.header-navigation ul.navigation-box > li > .sub-menu.right-align {
  left: auto;
  right: 0;
}
.header-navigation ul.navigation-box > li > .sub-menu.center-align {
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.header-navigation ul.navigation-box > li > .sub-menu > li {
  display: block;
  position: relative;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}
.header-navigation ul.navigation-box > li > .sub-menu > li + li {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}
.header-navigation ul.navigation-box > li > .sub-menu > li > a {
  font-size: 15px;
  color: #fff;
  /*font-weight: 500;*/
  padding: 12px 30px;
  display: block;
  line-height: 26px;
  white-space: nowrap;
  position: relative;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}
.header-navigation ul.navigation-box > li > .sub-menu > li:hover > a {
  color: #000;
  background: #f1e227;
}
.header-navigation ul.navigation-box > li:hover:before {
  -webkit-transform: scale(1, 1);
          transform: scale(1, 1);
  -webkit-transform-origin: left bottom;
          transform-origin: left bottom;
}
.header-navigation ul.navigation-box > li:hover > .sub-menu {
  opacity: 1;
  visibility: visible;
}
.header-navigation ul.navigation-box > li > ul > li {
  /* no more nested showen */
}
.header-navigation ul.navigation-box > li > ul > li > .sub-menu {
  position: absolute;
  top: 0%;
  left: 100%;
  z-index: 911;
  float: left;
  min-width: 220px;
  padding: 0px 0px;
  text-align: left;
  list-style: none;
  background-color: #2a2833;
  background-clip: padding-box;
  opacity: 0;
  border-radius: 0px;
  visibility: hidden;
  -webkit-transition: opacity .4s ease, visibility .4s ease;
  transition: opacity .4s ease, visibility .4s ease;
  -webkit-box-shadow: 0px 10px 18px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 10px 18px rgba(0, 0, 0, 0.1);
}
@media (min-width: 1200px) {
  .header-navigation ul.navigation-box > li > ul > li > .sub-menu {
    display: block !important;
  }
}
.header-navigation ul.navigation-box > li > ul > li > .sub-menu.right-align {
  left: auto;
  right: 100%;
}
.header-navigation ul.navigation-box > li > ul > li > .sub-menu.center-align {
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.header-navigation ul.navigation-box > li > ul > li > .sub-menu > li {
  display: block;
  position: relative;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}
.header-navigation ul.navigation-box > li > ul > li > .sub-menu > li + li {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}
.header-navigation ul.navigation-box > li > ul > li > .sub-menu > li > a {
  font-size: 15px;
  color: #fff;
  font-weight: 500;
  padding: 12px 30px;
  display: block;
  line-height: 26px;
  white-space: nowrap;
  position: relative;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}
.header-navigation ul.navigation-box > li > ul > li > .sub-menu > li:hover > a {
  color: #fff;
  background: #ffa065;
}
.header-navigation ul.navigation-box > li > ul > li:hover > .sub-menu {
  opacity: 1;
  visibility: visible;
}
.header-navigation ul.navigation-box > li > ul > li ul {
  display: none;
}

.main-logo {max-width: 300px;}


.stricked-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  background-color: #fff;
  -webkit-box-shadow: 0px 6px 13px 0px rgba(0, 0, 0, 0.06);
          box-shadow: 0px 6px 13px 0px rgba(0, 0, 0, 0.06);
  opacity: 0;
  -webkit-transform: translateY(-110%);
          transform: translateY(-110%);
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}
.stricked-menu ul.navigation-box > li {
  padding-top: 18px;
  padding-bottom: 18px;
}
.stricked-menu.stricky-fixed {
  opacity: 1;
  -webkit-transform: translateY(0%);
          transform: translateY(0%);
}

.site-header {
  position: relative;
}

.site-header__header-one {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 91;
  line-height: normal;
}
.site-header__header-one .header-navigation .main-navigation {
  padding-right: 0;
  text-align: right;
  margin-right: 9em;
}

.site-header__header-two .header-navigation ul.navigation-box > li > a {
  color: #ffffff;
}
.site-header__header-two .header-navigation ul.navigation-box > li.current > a,
.site-header__header-two .header-navigation ul.navigation-box > li:hover > a {
  color: #ffffff;
  text-shadow: 1px 0 0 rgba(255, 255, 255, 0.8);
}
.site-header__header-two .header__cta-btn {
  color: #fff;
  background-image: none;
  border-radius: 5px;
  overflow: hidden;
}
.site-header__header-two .header__cta-btn:before {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: transparent;
  border: 2px solid;
  -o-border-image: linear-gradient(90deg, #ff4eb5 0%, #ffa065 100%);
     border-image: -webkit-gradient(linear, left top, right top, from(#ff4eb5), to(#ffa065));
     border-image: linear-gradient(90deg, #ff4eb5 0%, #ffa065 100%);
  border-image-slice: 1;
}
.site-header__header-two .header__cta-btn:after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: -webkit-gradient(linear, left top, right top, from(#ff4eb5), to(#ffa065));
  background-image: linear-gradient(90deg, #ff4eb5 0%, #ffa065 100%);
  border-radius: 5px;
  opacity: 0;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
  z-index: 0;
}
.site-header__header-two .header__cta-btn span {
  z-index: 2;
}
.site-header__header-two .header__cta-btn:hover:before {
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
}
.site-header__header-two .header__cta-btn:hover:after {
  opacity: 1;
}
.site-header__header-two .stricked-menu {
  background-color: #2a2833;
}

.page-wrapper {
  position: relative;
  margin: 0 auto;
  width: 100%;
  min-width: 300px;
  overflow: hidden;
}

.thm-base-bg {
  background-color: #ff4eb5;
}

.thm-base-bg-2 {
  background-color: #ffa065;
}

.thm-gray-bg {
  background-color: #fefbf4;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}

.timeline {
  padding:0px 8em;
}

/*
* 8. video styles
*/
.video-one {
  position: relative;
}
.video-one__bg {
  position: absolute;
  bottom: 0%;
  left: 0;
}
.video-one .container {
  position: relative;
  padding: 15px;
  background-image: -webkit-gradient(linear, left bottom, left top, from(#fae81f), to(#2cae4a));
  background-image: linear-gradient(0deg, #fae81f 0%, #2cae4a 100%);
  border-radius: 7px;
  z-index: 10;
}
.video-one__box {
  position: relative;
  border-radius: 7px;
}
.video-one__box > img {
  width: 100%;
  border-radius: 7px;
}
.video-one__box:hover .video-one__content {
  background-color: rgba(42, 40, 51, 0.2);
}
.video-one__content {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(42, 40, 51, 0.6);
  top: 0;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
  border-radius: 7px;
}
.video-one__btn {
  width: 106px;
  height: 106px;
  background-image: -webkit-gradient(linear, left top, right top, from(#ff4eb5), color-stop(51%, #ffa065), to(#ff4eb5));
  background-image: linear-gradient(to right, #2cae4a 0%, #fae81f 51%, #ff4eb5 100%);
  background-size: 200% auto;
  text-align: center;
  border-radius: 50%;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
  -webkit-box-shadow: 0px 20px 40px 0px rgba(42, 40, 51, 0.8);
          box-shadow: 0px 20px 40px 0px rgba(42, 40, 51, 0.8);
}
.video-one__btn i {
  font-size: 24px;
  color: #fff;
  line-height: 106px;
}
.video-one__btn:hover {
  background-position: right center;
}
.video-one__btn:hover i {
  color: #fff;
}
.video-one__title {
  font-size: 50px;
  font-weight: 400;
  color: #fff;
  margin: 0;
  margin-top: 20px;
  text-align: center;
}
.video-one__title span {
  font-weight: 700;
}

.block-title {
  margin-bottom: 45px;
}
.block-title__title {
  margin: 0;
  margin-top: -10px;
  font-size: 50px;
  font-weight: 400;
  color: #2a2833;
  line-height: 1.2em;
}

.l-text {
  text-align: left;
}
.block-title__title span {
  font-weight: 700;
  color: #28ad47;
  font-size: 50px;
  padding-top: 15px;
}
span.small-text {
    color: #fff;
    font-weight: normal;
    font-size: 38px;
}

.thm-btn {
  border: none;
  display: inline-block;
  vertical-align: middle;
  outline: none;
  font-size: 18px;
  /* font-weight: 500; */
  color: #2cae4a;
  padding: 13px 41px;
  border-radius: 5px;
  -webkit-transition: background .4s ease, color .4s ease;
  transition: background .4s ease, color .4s ease;
  background-image: -webkit-gradient(linear, left top, right top, from(#2cae4a), to(#fae81f));
  background-image: linear-gradient(90deg, #2cae4a 0%, #fae81f 100%);
  position: relative;
}
.thm-btn:before {
  content: '';
  position: absolute;
  top: 2px;
  right: 2px;
  bottom: 2px;
  left: 2px;
  border-radius: 5px;
  background-color: #fff;
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
  -webkit-transition: -webkit-transform .4s ease;
  transition: -webkit-transform .4s ease;
  transition: transform .4s ease;
  transition: transform .4s ease, -webkit-transform .4s ease;
}
.thm-btn span {
  position: relative;
}
.thm-btn:hover {
  color: #fff !important;
}
.thm-btn:hover:before {
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
}

.preloader {
  position: fixed;
  background-color: #fff;
  background-position: center center;
  background-repeat: no-repeat;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9991;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
}
.preloader__image {
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-name: flipInY;
          animation-name: flipInY;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}

.scroll-to-top {
  display: inline-block;
  width: 45px;
  height: 45px;
  background-image: -webkit-gradient(linear, left top, right top, from(#28ad47), color-stop(51%, #dbd10f), to(#28ad47));
  background-image: linear-gradient(to right, #28ad47 0%, #dbd10f 51%, #28ad47 100%);
  background-size: 200% auto;
  position: fixed;
  bottom: 40px;
  right: 40px;
  z-index: 99;
  text-align: center;
  -webkit-transition: .4s;
  transition: .4s;
  display: none;
  border-radius: 50%;
}
.scroll-to-top i {
  color: #fff;
  font-size: 18px;
  line-height: 45px;
}
.scroll-to-top:hover {
  background-position: right center;
}
.scroll-to-top:hover i {
  color: #fff;
}

.top-logo {margin-top: 2em;}


/*
* 3. banner styles
*/
.banner-one {
  position: relative;
  background-image: url(../img/landing/banner-bg-1-1.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
}
.banner-one .container {
  padding-top: 350px;
  padding-bottom: 230px;
  position: relative;
}
.banner-one__title {
  margin: 0;
  color: #2a2833;
  font-weight: 300 !important;
  font-size: 60px;
  line-height: 1.2em;
  letter-spacing: -.06em;
}
.banner-one__title span {
  font-size: 60px !important;
  font-weight: 500;
  color: #28ad47;
}
.banner-one__text {
  margin: 0;
  font-size: 20px;
  line-height: 36px;
  color: #74727a;
  margin-top: 25px;
  margin-bottom: 40px;
}
.banner-one__btn {
  padding: 15px 45px;
}
.banner-one__moc {
  position: absolute;
  bottom: 7%;
  right: -30px;
}
.banner-one__moc:before {
  content: '';
  width: 639px;
  height: 639px;
  /*background-image: linear-gradient(40deg, #ff43c0 0%, #ffa95c 100%);
  -webkit-box-shadow: 0px 20px 60px 0px rgba(244, 151, 190, 0.8);
          box-shadow: 0px 20px 60px 0px rgba(244, 151, 190, 0.8);*/
  /*border-radius: 50%;*/
  background-image: url(../img/landing/top-bg.png);
  background-repeat: no-repeat;
  position: absolute;
  top: -14%;
  /*left: -14%;*/
  -webkit-transform-origin: center bottom;
          transform-origin: center bottom;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-name: bounce;
          animation-name: bounce;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 5s;
          animation-duration: 5s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}
.banner-one__moc img {
  position: relative;
  -webkit-animation: featureImgBounce 5s ease-in-out 0s infinite alternate;
          animation: featureImgBounce 5s ease-in-out 0s infinite alternate;
}

[class*=banner-one__shape-] {
  position: absolute;
  border-radius: 50%;
  -webkit-animation-name: bubbleMover;
          animation-name: bubbleMover;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}

.banner-one__shape-1 {
  background-image: linear-gradient(40deg, #0db8ff 0%, #f332ff 100%);
  -webkit-box-shadow: 0px 20px 60px 0px rgba(141, 142, 255, 0.35);
          box-shadow: 0px 20px 60px 0px rgba(141, 142, 255, 0.35);
  width: 143px;
  height: 143px;
  top: 5%;
  left: 3%;
  -webkit-animation-duration: 8s;
          animation-duration: 8s;
  -webkit-perspective: 150px;
          perspective: 150px;
}

.banner-one__shape-2 {
  /*background-image: linear-gradient(40deg, #39aeff 0%, #3cff53 100%);
  -webkit-box-shadow: 0px 20px 40px 0px rgba(231, 126, 37, 0.44);
          box-shadow: 0px 20px 40px 0px rgba(231, 126, 37, 0.44);*/
  width: 85px;
  height: 85px;
  top: 12%;
  left: 37%;
  -webkit-animation-duration: 10s;
          animation-duration: 10s;
  -webkit-perspective: 85px;
          perspective: 85px;
}

.banner-one__shape-3 {
  /*background-image: linear-gradient(40deg, #ff43c0 0%, #ffa95c 100%);
  -webkit-box-shadow: 0px 20px 40px 0px rgba(255, 118, 142, 0.8);
          box-shadow: 0px 20px 40px 0px rgba(255, 118, 142, 0.8);*/
  width: 85px;
  height: 85px;
  bottom: 12%;
  left: 6%;
  -webkit-animation-duration: 7s;
          animation-duration: 7s;
  -webkit-perspective: 85px;
          perspective: 85px;
}

.banner-one__shape-4 {
  /*background-image: linear-gradient(40deg, #ff703e 0%, #ffec4e 100%);
  -webkit-box-shadow: 0px 20px 40px 0px rgba(255, 178, 71, 0.8);
          box-shadow: 0px 20px 40px 0px rgba(255, 178, 71, 0.8);*/
  width: 85px;
  height: 85px;
  top: 20%;
  right: 4%;
  -webkit-animation-duration: 12s;
          animation-duration: 12s;
  -webkit-perspective: 85px;
          perspective: 85px;
}

@-webkit-keyframes bubbleMover {
  0% {
    -webkit-transform: translateY(0px) translateX(0) rotate(0);
            transform: translateY(0px) translateX(0) rotate(0);
  }
  30% {
    -webkit-transform: translateY(30px) translateX(50px) rotate(15deg);
            transform: translateY(30px) translateX(50px) rotate(15deg);
    -webkit-transform-origin: center center;
            transform-origin: center center;
  }
  50% {
    -webkit-transform: translateY(50px) translateX(100px) rotate(45deg);
            transform: translateY(50px) translateX(100px) rotate(45deg);
    -webkit-transform-origin: right bottom;
            transform-origin: right bottom;
  }
  80% {
    -webkit-transform: translateY(30px) translateX(50px) rotate(15deg);
            transform: translateY(30px) translateX(50px) rotate(15deg);
    -webkit-transform-origin: left top;
            transform-origin: left top;
  }
  100% {
    -webkit-transform: translateY(0px) translateX(0) rotate(0);
            transform: translateY(0px) translateX(0) rotate(0);
    -webkit-transform-origin: center center;
            transform-origin: center center;
  }
}

@keyframes bubbleMover {
  0% {
    -webkit-transform: translateY(0px) translateX(0) rotate(0);
            transform: translateY(0px) translateX(0) rotate(0);
  }
  30% {
    -webkit-transform: translateY(30px) translateX(50px) rotate(15deg);
            transform: translateY(30px) translateX(50px) rotate(15deg);
    -webkit-transform-origin: center center;
            transform-origin: center center;
  }
  50% {
    -webkit-transform: translateY(50px) translateX(100px) rotate(45deg);
            transform: translateY(50px) translateX(100px) rotate(45deg);
    -webkit-transform-origin: right bottom;
            transform-origin: right bottom;
  }
  80% {
    -webkit-transform: translateY(30px) translateX(50px) rotate(15deg);
            transform: translateY(30px) translateX(50px) rotate(15deg);
    -webkit-transform-origin: left top;
            transform-origin: left top;
  }
  100% {
    -webkit-transform: translateY(0px) translateX(0) rotate(0);
            transform: translateY(0px) translateX(0) rotate(0);
    -webkit-transform-origin: center center;
            transform-origin: center center;
  }
}
.banner-two {
  position: relative;
  background-color: #2a2833;
  background-image: url(../img/landing/banner-2-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  margin-bottom: 120px;
}
.banner-two .container {
  padding-top: 260px;
  padding-bottom: 120px;
  position: relative;
}
.banner-two__title {
  margin: 0;
  color: #fff;
  font-weight: 300;
  font-size: 90px;
  line-height: 1em;
  letter-spacing: -.06em;
}
.banner-two__title span {
  font-weight: 500;
}
.banner-two__text {
  margin: 0;
  font-size: 20px;
  line-height: 36px;
  color: rgba(255, 255, 255, 0.7);
  margin-top: 25px;
  margin-bottom: 40px;
}
.banner-two__btn {
  color: #fff;
  background-image: none;
  border-radius: 5px;
  overflow: hidden;
  padding: 16px 55px;
}
.banner-two__btn:before {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: transparent;
  border: 2px solid;
  -o-border-image: linear-gradient(90deg, #ff4eb5 0%, #ffa065 100%);
     border-image: -webkit-gradient(linear, left top, right top, from(#ff4eb5), to(#ffa065));
     border-image: linear-gradient(90deg, #ff4eb5 0%, #ffa065 100%);
  border-image-slice: 1;
}
.banner-two__btn:after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: -webkit-gradient(linear, left top, right top, from(#ff4eb5), to(#ffa065));
  background-image: linear-gradient(90deg, #ff4eb5 0%, #ffa065 100%);
  border-radius: 5px;
  opacity: 0;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
  z-index: 0;
}
.banner-two__btn span {
  z-index: 2;
}
.banner-two__btn:hover:before {
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
}
.banner-two__btn:hover:after {
  opacity: 1;
}
.banner-two__moc {
  position: absolute;
  bottom: 0;
  right: 0px;
}
.banner-two__moc img {
  position: relative;
}

[class*=banner-two__shape-] {
  position: absolute;
  border-radius: 50%;
  -webkit-filter: blur(5px);
          filter: blur(5px);
  -webkit-animation-name: bubbleMover;
          animation-name: bubbleMover;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}

.banner-two__shape-1 {
  background-image: linear-gradient(40deg, #0db8ff 0%, #f332ff 100%);
  width: 143px;
  height: 143px;
  top: 5%;
  left: 3%;
  -webkit-animation-duration: 8s;
          animation-duration: 8s;
  -webkit-perspective: 150px;
          perspective: 150px;
}

.banner-two__shape-2 {
  background-image: linear-gradient(40deg, #39aeff 0%, #3cff53 100%);
  width: 85px;
  height: 85px;
  top: 12%;
  left: 37%;
  -webkit-animation-duration: 10s;
          animation-duration: 10s;
  -webkit-perspective: 85px;
          perspective: 85px;
}

.banner-two__shape-3 {
  background-image: linear-gradient(40deg, #ff43c0 0%, #ffa95c 100%);
  width: 85px;
  height: 85px;
  bottom: 12%;
  left: 6%;
  -webkit-animation-duration: 7s;
          animation-duration: 7s;
  -webkit-perspective: 85px;
          perspective: 85px;
}

.banner-two__shape-4 {
  background-image: linear-gradient(40deg, #ff703e 0%, #ffec4e 100%);
  width: 85px;
  height: 85px;
  top: 20%;
  right: 4%;
  -webkit-animation-duration: 12s;
          animation-duration: 12s;
  -webkit-perspective: 85px;
          perspective: 85px;
}

/*
* 7. inner-banner styles
*/
.inner-banner {
  position: relative;
  background-color: #fff;
  background-image: url(../img/landing/inner-banner-bg-1-1.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  padding-top: 245px;
  padding-bottom: 120px;
}
.inner-banner__title {
  text-align: center;
  line-height: 1em;
  font-size: 46px;
  font-weight: 400;
  color: #fff;
  margin: 0;
  margin-top: 10px;
}

.thm-breadcrumb {
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.thm-breadcrumb li {
  font-size: 18px;
  font-weight: 400;
}
.thm-breadcrumb li a {
  color: #ffffff;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}
.thm-breadcrumb li a:hover {
  color: #ffa065;
}
.thm-breadcrumb li + li::before {
  content: '.';
  color: #fff;
  margin-left: 15px;
  margin-right: 15px;
}


/*
* 10. mailchimp styles
*/
.mailchimp-one {
 
  padding: 7em 0px;
  /* background-image: linear-gradient(40deg, #ff43c0 0%, #ffa95c 100%); */
  background-color: #28ad47;
  background-image: url(../img/landing/footer.png);
  background-position: bottom right;
  background-repeat: no-repeat;
}
.mailchimp-one .block-title__title {
  color: #fff;
  font-weight: 400!important;
}
.mailchimp-one__mc-form {
  width: 100%;
  max-width: 692px;
  height: 91px;
  background-color: #fff;
  border-radius: 5px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.loginul_form {
  position: relative;
  z-index: 9;
  box-sizing: border-box;
  width: 100%;
  list-style-type: none;
  padding: 0;
}
.commaninputdiv {
  margin-top: 27px;
}
input.question-d, textarea.question-d {
  color: black;
  padding-left: 60px;
  font-size: 18px;
  font-weight: 300;
  border-radius: 2px;
  margin: 0;
  border: none;
  width: 100%;
  background: rgba(0, 0, 0, 0);
  transition: padding-top 0.2s ease, margin-top 0.2s ease;
  box-shadow: unset !important;
  overflow-x: hidden;
  padding-bottom: 4px;
}
input.question-d + label, textarea.question-d + label {
  display: block;
  position: relative;
  white-space: nowrap;
  padding: 0;
  margin: 0;
  width: 100%;
  border-top: 1px solid #d2d2d2;
  -webkit-transition: width 0.4s ease;
  transition: width 0.4s ease;
  height: 0px;
}
input.question-d:focus + label, textarea.question-d:focus + label {
  border-color: #2cae4a;
}
input.question-d + label > span, textarea.question-d + label > span {
  padding-left: 54px;
  font-weight: 500;
  margin: 0;
  position: absolute;
  color: #333;
  font-size: 14px;
  top: -34px;
  left: 0px;
  z-index: -1;
  -webkit-transition: top 0.2s ease, font-size 0.2s ease, color 0.2s ease;
  transition: top 0.2s ease, font-size 0.2s ease, color 0.2s ease;
  top: -30px;
  color: #7D7D7D;
}
input.question-d:focus + label > span, input.question-d:valid + label > span {
  top: -55px;
  font-size: 14px;
  color: #2cae4a;
}
input.question-d:focus {
  outline: none;
}

.mc-form__response p {
  background-color: #fff;
  font-size: 11px;
  margin: 0;
  color: #2a2833;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
}

.mc-form__response.successed p {
  background-color: green;
}

/*
* 15. cta styles
*/
.cta-one {
  padding: 165px 0;
  position: relative;
  /*margin-bottom: 12em;*/
}
.cta-one__bg {
  position: absolute;
  bottom: 4%;
  right: 0;
  z-index: -99;
}
.cta-one .container {
  position: relative;
}
.cta-one .container:before {
  content: '';
  width: 448px;
  height: 448px;
  border-radius: 50%;
  background-color: #f6fff7;
  position: absolute;
  top: -19%;
  left: -12%;
  z-index: -9;
}
.cta-one__moc {
  position: absolute;
  top: -45px;
  /*left: -10%;*/
  -webkit-animation: featureImgBounce 5s ease-in-out 0s infinite alternate;
          animation: featureImgBounce 5s ease-in-out 0s infinite alternate;
}
.cta-one .block-title {
  margin-bottom: 0;
  margin-top: 30px;
  margin-bottom: 45px;
}
.cta-one .block-title__title {
  letter-spacing: -.04em;
}
.cta-one__icon {
  font-size: 62px;
  color: #ff4eb5;
}
.cta-one__text p {
  margin: 0;
  color: #74727a;
  font-size: 24px;
  line-height: 40px;
}
.cta-one ul {
  margin: 0;
  margin-top: 45px;
  margin-bottom: 50px;
}
.cta-one ul li {
  position: relative;
  font-size: 16px;
  color: #74727a;
  padding-left: 30px;
}
.cta-one ul li + li {
  margin-top: 3px;
}
.cta-one ul li i {
  font-size: 18px;
  color: #ffa065;
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.cta-one .thm-btn {
  padding: 18px 55.5px;
}

.cta-two {
  padding: 120px 0;
  background-color: #fefbf4;
}
.cta-two .container {
  position: relative;
}
.cta-two__moc {
  position: absolute;
  top: -40px;
  right: -35px;
  -webkit-animation: featureImgBounce 5s ease-in-out 0s infinite alternate;
          animation: featureImgBounce 5s ease-in-out 0s infinite alternate;
}
.cta-two .block-title {
  margin-top: 30px;
  margin-bottom: 35px;
}
.cta-two .block-title__title {
  letter-spacing: -.04em;
}
.cta-two__icon {
  font-size: 62px;
  color: #ffa065;
}
.cta-two__text {
  margin-bottom: 50px;
}
.cta-two__text p {
  margin: 0;
  color: #74727a;
  font-size: 18px;
  line-height: 34px;
}
.cta-two__text p + p {
  margin-top: 35px;
}
.cta-two .thm-btn {
  padding: 18px 55.5px;
}

@-webkit-keyframes featureImgBounce {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
}

@keyframes featureImgBounce {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
}

.show {display: block;}
.hide {display: none;}

.download_btn {
    border: 1px solid #fff;
    display: inline-block;
    vertical-align: middle;
    outline: none;
    font-size: 18px;
    color: #fff !important;
    padding: 0px 45px;
    border-radius: 5px;
    -webkit-transition: background .4s ease, color .4s ease;
    transition: background .4s ease, color .4s ease;
    position: relative;
    margin: 10px 0;
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-name: bounce;
    animation-name: bounce;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    -webkit-animation-duration: 5s;
    animation-duration: 5s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
}

a.download_btn {
    font-weight: 400;
    color: #ffffff!important;
}
.col-lg-6.col-md-6.col-sm-8.offset-sm-2 {
    margin-top: 5em;
}

section#pricing {
    margin-top: 5em;
}


/*----about page start------*/

.aboutbanner-d
{
  margin-top: 8rem;
}

.aboutbanner-d img
{
  margin: 0 auto;
  float: none;
  display: block;
  width: 100%;
}

.aboutbanner-d h4
{
  font-weight: 400!important;
  display: block;
  text-align: center;
  margin-top: 48px;
  color: #28AD47;
  text-shadow: 0px 3px 3px #40404029;
  font-size: 28px;
}
.aboutbanner-d p
{
  font-size: 14px;
  line-height: 29px;
  text-align: center;
  margin-top: 18px;
}
.keyfeatures-d
{
  width: 100%;
  background: transparent linear-gradient(115deg, #D0DF68 0%, #00AF59 100%) 0% 0% no-repeat padding-box;
  /*opacity: 0.42;*/
  height: auto;
  margin-top: 100px;
  padding-bottom: 70px;
}
.keyfeatures-d h4
{
  text-align: center;
  padding-top: 62px;
  color: #ffffff;
  text-shadow: 0px 3px 3px #40404029;
  font-size: 28px;
  display: block;

}
.keylist-d
{
  width: 70%;
  margin: auto;
  padding-top: 25px;

}
.keylist-d li
{
  color: #fff;
  font-size: 15px;
  padding-bottom: 6px;
  list-style-type: none;
  position: relative;
  padding-left: 34px;
}
.keylist-d li:before
{
  content: "";
  display: inline-block;
  width: 12px;
  height: 12px;
  background-color: #ede029;
  background: transparent linear-gradient(96deg, #129a09 0%, #c3d814 100%) 0% 0% no-repeat padding-box;
  opacity: 1;
  border-radius: 10px;
  position: absolute;
  top: 11px;
  left: 0px;
}
.sports_activity-d
{
  padding-top: 100px;
}
.activity-d
{
  float: left;
  margin-top: 4%
}
.activity-d h4
{
  font-weight: 400!important;
  display: block;
  color: #28AD47;
  text-shadow: 0px 3px 3px #40404029;
  font-size: 28px;
  margin-bottom: 25px;
}
.activity-d p
{
  font-size: 14px;
  line-height: 29px;

}
.activity_img-d
{
  display: block;
  float: right;
}
.education_sports-d p
{
  color: #fff;
  font-size: 15px;
  text-align: center;
}
.educationsport-d
{
  margin-top: 50px!important;
}
.aboutlastinfo-d
{
  padding-top: 70px;
  padding-bottom: 70px;
}

.aboutlastsec-d
{
  display: inline-block;
}
.aboutlasttext-d
{
  float: right;
}
.aboutlasttext-d h4
{
  line-height: 35px;
    font-weight: 400!important;
  display: block;
  color: #28AD47;
  text-shadow: 0px 3px 3px #40404029;
  font-size: 28px;
  margin-bottom: 25px;

}

.aboutlasttext-d p
{
  font-size: 14px;
  line-height: 29px;
}

/*----End about page ------*/



/*--------Contact page--------*/

.contactleft
{
  display:inline-block;
}
.containercontact {
  margin: 0 auto;
  background-color: #fff;
  border: 1px solid #e4e4e4;
  border-radius: 5px;
  margin-top: -180px;
  padding: 41px 0px;
  box-shadow: 0px 3px 22px rgba(0, 0, 1, 0.08);
  display:flex;
}
.contactbg {
  background-image: linear-gradient(90deg, #75f793 0%, #fff580 100%);
  min-height: 340px;
}
.getintouch {
  margin: 0;
  text-align: center;
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 40px;
  padding-bottom: 19px;
  font-weight: 600 !important;
  color: #333;
  font-size: 24px;
}
.contactul li input, .contactul li textarea {
  width: 100%;
  background-color: #f1f1f1;
  border: 0;
  padding: 14px 16px;
  border: 1px solid #e6e6e6;
  margin-bottom: 20px;
  resize: none;
}
.contactul li input:focus, .contactul li textarea:focus {
  background-color: #ffffff;
  border: 1px solid #2cae4a;
}
.contactul li textarea {
  min-height: 130px;
}
.contactul li button {
  font-size: 14px;
  padding: 7px 51px;
  margin-top: 30px;
}
.contactinfo
{
list-style-type: none;
  padding: 0;
}
.contactright
{
  float: right;
}
.contactinfo li a {
  color: #444;
  font-size: 15px;
  position: relative;
  display: block;
  padding-left: 47px;   
 top: 10px;
}
.inputicon-d
{
  position: relative;
  top: -33px;
  left: 14px;
  border-right: 1px solid #989898;
  padding-right: 15px;
}
.inputname-d
{
  position: relative;
  top: -33px;
  left: 10px;
  border-right: 1px solid #989898;
  padding-right: 15px;
}
.inputemail-d
{
  position: relative;
  top: -33px;
  left: 7px;
  border-right: 1px solid #989898;
  padding-right: 15px;
}

.contactinfo li a i {
  position: absolute;
  left: 0px;
  top: 9px;
  font-size: 15px;
  color: #2cae4a;
}
.c_socialul {
  margin-top: 25px;
  margin-bottom: 34px;
  padding-left: 0;
}
.c_socialul li {
  display: inline-block;
  margin-right: 10px;
}
.c_socialul li a {
  padding: 0!important;
  display: inline-block;
  width: 30px;
  height: 30px;
  text-align: center;
  border: 1px solid #666;
  line-height: 29px;
  border-radius: 60px;
  color: #666;
}
.c_socialul li a:hover {
  border: 0px solid;
  background-image: linear-gradient(90deg, #2cae4a 0%, #fae81f 100%);
  border-image-source: linear-gradient(90deg, #2cae4a 0%, #fae81f 100%);
}
.c_socialul li a:hover:before{

  color: #ffffff;
}
.contactifream {
  height: 251px;
  margin-bottom: 30px;
}
.submitcustom-d
{
  margin-top: 24px;
}
.formbtn-d
{
  padding: 7px 38px!important;
}

/*--------End Contact page--------*/

/*--------privacy & policy page--------*/

.privacy_wrapper
{
  background-color: #f7f7f7;
}
.privacy_header
{
  background-color: #ffffff;
}
.pad7 {
  padding: 7.5px!important;
}
.containerbottom
{

  margin-left: auto;
  margin-right: auto;
  display: flex;
  padding: 21px;
  background: #fff;
}
.postdata h4
{
  font-size: 16px;
  font-weight: 700;
  color: #333333;
}
.postdata p {
  font-size: 14px;
  padding: 10px 0px;
  line-height: 22px;
  color: #444;
  margin: 0;

}
/*--------End privacy & policy page--------*/


.faq_content h4
{
  margin-top: 31px;
  margin-bottom: 15px;
}
.faq_content ul li
{
  font-size: 15px;
  color: #333;
  font-weight: 500;
}
.line_img
{   width: 34px;
  margin-right: 10px;
  margin-left: 10px;
}
.screenshot
{
  width:100%;
  max-width:233px;
  margin-right: 40px;
  border: 1px solid #dedede;
}

#myImg {
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
}

#myImg:hover {opacity: 0.7;}

.privacy_container
{
  margin-left: auto;
  margin-right: auto;
  margin-top: 138px;
  display: block;
  padding: 21px;
  background: #fff;
}

.postdata li {
    font-size: 14px;
    padding: 10px 0px;
    line-height: 14px;
    color: #444;
    margin: 0;
}