@charset "utf-8";

/* CSS Document */

@media only screen and (max-width: 1367px) {
  .sportsul li {
    margin: 1.5%;
    width: 21.9%;
  }

  .loginhead h2 {
    font-size: 32px;
    line-height: 39px;
  }

  .loginforgot button {
    margin-top: 47px;
  }

  .opponent_inner .createinput {
    margin-right: 13px;
  }

  input.question + label > span,
  textarea.question + label > span {
    font-size: 14px;
  }

  .logintext p {
    letter-spacing: 0.4px;
    line-height: 20px;
    font-size: 15px;
    font-weight: 600;
  }

  .downloaddiv {
    margin-top: 28px;
  }

  .downloaddiv button img {
    width: 145px;
  }

  .logintext p:last-child {
    padding-right: 85px;
    text-align: justify;
    margin-top: 30px;
  }

  .commaninputdiv {
    margin-top: 36px !important;
  }

  .loginul_form li {
    border-bottom: 0px !important;
  }

  .loginforgot button {
    margin-top: 25px;
  }

  .logintext h1 {
    line-height: 42px !important;
    letter-spacing: 1.3px !important;
    text-transform: capitalize;
  }

  .loginformul {
    padding: 10px !important;
    display: flex;
  }

  input.question:focus + label > span,
  input.question:valid + label > span {
    top: -55px;
  }

  .loginforgot button {
    padding: 5px 5px;
  }

  .flag-select .selected--flag--option:before {
    left: 0;
  }

  .logintext p {
    letter-spacing: 0.2px;
    line-height: 23px;
    font-size: 17px;
    font-weight: 600;
  }

  .loginlogo_text img {
    width: 232px;
  }
}

@media only screen and (max-width: 1199px) {
  .datentime_para {
    font-size: 13px;
    line-height: 19px;
  }

  .teamdiv ul li .it_flgscore {
    font-size: 11px;
  }

  .teamdiv ul li .it_flgname {
    min-width: 75px;
  }

  .scoreboxul > li > h3 {
    font-size: 13px;
  }

  .teamdiv ul li .lt_img {
    width: 48px;
  }

  .teamdiv ul li .it_flgname {
    font-size: 12px;
  }

  .dropnotify button {
    width: 35px;
  }

  .logodiv a .logotext {
    width: 154px;
  }

  .pad7 {
    padding: 0px 7px !important;
  }

  .logotext {
    width: 155px;
    margin-top: 4px;
  }

  .downloadlink {
    width: 86px;
  }

  .loginhead h2 {
    font-size: 32px;
    line-height: 41px;
  }

  .loginul_form {
    /*padding: 11px 40px;*/
    width: 100%;
  }

  .sportsul li .ss_sportstile {
    margin-top: 15px;
    font-size: 13px;
    font-weight: 500;
  }

  .sportsul li .ss_sportstile {
    font-size: 13px;
  }

  .sportsul li {
    margin: 1.5%;
    width: 29.9%;
  }

  .srachdiv_bg {
    padding: 0px 5px !important;
  }

  .nav-tabs > li {
    float: left;
    margin: 0px 2px;
  }

  .nav-tabs > li > a {
    padding: 5px 10px !important;
  }

  .aboutbuddies li a {
    display: inline-block;
    width: 40px;
    height: 40px;
  }

  .aboutteamsul li a {
    width: 55px;
    height: 55px;
  }

  .aboutbroadcast li a {
    min-height: 60px;
    padding-top: 15px;
  }

  .aboutleftdata p {
    line-height: 23px;
    font-size: 13px;
    word-break: break-all;
  }

  .useraccount h3 a {
    margin-top: 1px;
    font-size: 13px;
  }

  .useraccount {
    margin-top: -30px !important;
  }

  .userleft {
    top: 97px !important;
  }

  .editprofile {
    padding: 11px 15px;
    width: 100%;
  }

  .aboutbuddies li {
    width: 75px;
  }

  .aboutteamsul li {
    display: inline-block;
    margin: 6px 3px;
  }

  .aboutteamsul li a {
    width: 45px;
    height: 45px;
  }

  .aboutbroadcast li {
    margin: 16px 10px 0px 10px;
  }

  .buddieslist_ul li {
    width: 127px;
  }

  .profileteam_ul li {
    width: 29%;
    margin: 1.7% 1.3%;
  }

  .profileteam_ul li span {
    width: 58px;
    height: 58px;
    border-radius: 7px;
  }

  .profileabout li a img {
    width: 53px;
    margin-top: 47px;
  }

  .profileabout li {
    width: 30% !important;
    margin: 2%;
    margin: 1.7% 1.3%;
  }

  .cpg_span {
    width: 84px;
    height: 84px;
  }

  .aboutul li a {
    font-size: 13px;
  }

  .playdiatorprof .pro_username {
    font-size: 23px;
  }

  .aboutul li {
    width: 100%;
  }

  .fixturebox {
    min-width: 198px;
  }

  .tourcompleted_match ul li .lt_img {
    width: 60px;
    height: 60px;
  }

  .tourcompleted_match ul li .it_flgname {
    font-size: 14px;
  }

  .tourcompleted_match ul li .it_flgname {
    margin-top: 9px;
  }

  .tourcompleted_match .vsicon {
    margin-top: 13px;
    width: 45px;
  }

  .completed_info p {
    font-size: 13px;
  }

  .completed_info {
    padding: 0px 15px 8px 15px;
  }

  .tourcompleted_match .matchdatetime {
    font-size: 14px;
  }

  .postactiondiv button {
    padding: 3px 34px;
  }

  .text-capitalize {
    display: none;
  }

  .topsearch {
    width: 122%;
  }

  .fullnoti_label {
    max-width: 315px;
  }
}

@media only screen and (max-width: 992px) {
  .scoreboxul > li {
    width: 90%;
  }

  .logotext {
    display: none;
  }

  /*.dropaccount {*/
  /*    margin-top: 1px !important;*/
  /*    width: 171px;*/
  /*}*/

  .divpost {
    margin-top: 15px;
  }

  .commanbox {
    margin-top: 15px;
  }

  .d_leftsec {
    margin-top: 50px;
    transition: all 0.4s ease 0s;
  }
  .d_leftsec .commanbox
  {
    margin-top: unset!important;
    padding-top: 15px;
  }

  .profilespan {
    width: 80px;
    height: 80px;
  }

  .prof_span {
    width: 80px;
    height: 80px;
    top: -48px;
    left: 0px;
  }

  .profilediv a {
    width: 35px;
    height: 35px;
  }

  .profilediv a img {
    width: 14px;
  }

  .profileul li a span {
    font-size: 18px;
  }

  .profileul li a {
    font-size: 13px;
  }

  .commanhead {
    /* padding: 15px 10px 15px 10px;
font-size: 18px; */
    padding: 15px 10px;
    font-size: 13px;
  }

  .livescore img {
    vertical-align: -3px;
    margin-right: 5px;
    width: 17px;
  }

  .livescore {
    font-size: 14px;
    top: -1px;
  }

  .activityul li a img {
    margin-right: 8px;
    width: 21px;
  }

  .activityul li a {
    padding: 10px 10px;
    font-size: 12px;
  }

  .pro_username {
    font-size: 16px;
  }

  .profileul {
    margin-top: 4px;
  }

  .b_requestul .br_user span {
    width: 35px;
    height: 35px;
  }

  .b_requestul li {
    padding: 5px 6px;
    display: inline-block;
    width: 100%;
  }

  .b_requestul .br_actions {
    text-align: right;
    float: right;
    margin-top: -3px;
    width: 100%;
  }

  .actincomman {
    width: 25px;
    height: 25px;
    margin-left: 2px;
  }

  .br_user label {
    font-weight: 500;
    font-size: 13px;
  }

  .p_usertime {
    font-size: 11px;
    white-space: nowrap;
  }

  .postactiondiv button {
    padding: 3px 28px;
    font-size: 13px;
    margin-top: 5px;
  }

  .userdetail {
    padding: 10px 20px;
  }

  .p_userli {
    padding-left: 49px;
  }

  .p_userimg {
    width: 40px;
    height: 40px;
  }

  .p_userli label {
    margin-top: 1px;
    font-size: 14px;
    color: #333;
    white-space: nowrap;
  }

  .p_usermore .dropaccount button {
    margin-top: -6px;
  }

  .postuserul {
    margin-bottom: -10px;
  }

  .pro_useredit {
    margin-top: 10px;
    font-size: 13px;
  }

  .likeinner li span {
    width: 25px;
    height: 25px;
    font-size: 9px;
    line-height: 24px;
  }

  .likeinner ul {
    display: none;
  }

  .userlikeinner span {
    font-weight: 500;
    font-size: 11px;
  }

  .commentinner span {
    margin-right: 0px;
    font-size: 13px;
    font-weight: 500;
  }

  .commentinner span .spancount {
    font-size: 12px;
  }

  .likeuser {
    width: 18px;
  }

  .likespan {
    font-size: 13px;
  }

  .commentul {
    display: flex;
    padding: 13px 7px 14px 10px;
    align-items: baseline;
  }

  .commentimg {
    width: 17px;
  }

  .commentul {
    display: flex;
    padding: 2px 7px 4px 10px;
    align-items: baseline;
    position: relative;
    min-height: 38px;
  }

  .helpul {
    padding: 3px;
    text-align: center;
    margin: 0;
  }

  .helpul li a {
    display: inline-block;
    padding: 3px 12px;
    border: 1px solid #2cae4a;
    color: #2cae4a;
    border-radius: 23px;
    font-size: 11px;
    margin: 4px;
    background-color: rgba(203, 236, 210, 0.35);
    float: left;
  }

  .p_usermore .dropaccount button img {
    height: 18px;
  }

  .p_usermore .dropaccount button {
    margin-top: -1px;
    padding: 6px 4px;
  }

  .downloadlink {
    width: 72px;
  }

  .downloadapp_div a {
    margin: 0px 2.5px;
  }

  .downloadapp_div {
    text-align: center;
    padding: 7px 3px;
  }

  .postarea_div textarea {
    height: 90px;
  }

  .postactiondiv ul li a img {
    width: 30px;
  }

  .postactiondiv button {
    margin-top: 1px;
  }

  .videopost .vidplaydiv a img {
    width: 52px;
  }

  .livepost span {
    right: 10px;
    top: 10px;
    font-size: 13px;
  }

  .loginbg {
    padding: 30px;
  }

  .logintext h1 {
    font-size: 40px;
    font-weight: 600 !important;
    line-height: 47px;
    margin-bottom: 0;
    letter-spacing: 2.2px;
  }

  .logintext p {
    letter-spacing: 0.4px;
    line-height: 22px;
    margin-top: 11px;
    font-size: 15px;
  }

  .logintext p br {
    display: none;
  }

  .downloaddiv button img {
    width: 128px;
  }

  .logintext {
    position: absolute;
    top: 45%;
    transform: translateY(-50%);
    width: 94%;
    padding-right: 30px;
  }

  .sportsul li {
    width: 29.9%;
  }

  .livescoreguest img {
    vertical-align: -3px;
    margin-right: 5px;
    width: 19px;
  }

  .livescoreguest {
    top: 12px;
    font-size: 16px;
  }

  .chatseclivescore {
    display: block !important;
  }

  .userlikeinner span {
    display: inline-block;
  }

  .commentul li {
    flex: auto;
  }

  .buddiesul li {
    display: inline-block;
    margin: 0px 1px;
  }

  .buddiesul li span {
    display: inline-block;
    width: 35px;
    height: 35px;
  }

  .leftsecprof {
    top: 63px !important;
    z-index: 1;
  }

  .nav-tabs > li > a {
    padding: 4px 6px !important;
  }

  .aboutteamsul li {
    display: inline-block;
    margin: 2px;
  }

  .aboutteamsul li a {
    width: 55px;
    height: 55px;
  }

  .userleft {
    top: 93px !important;
  }

  .profilesection {
    margin-top: 63px;
  }

  .buddieslist_ul li {
    margin: 5px;
  }

  .buddieslist_ul li {
    width: 44%;
    margin: 2.2% 1.5%;
  }

  .buddieslist_ul li a span {
    width: 50px;
    height: 50px;
  }

  .aboutleftdata p {
    line-height: 15px;
    font-size: 12px;
    word-break: break-all;
    margin-bottom: 4px;
  }

  .aboutleftdata {
    padding: 6px 10px;
  }

  .profileteam_ul li {
    width: 154px;
  }

  .profileteam_ul li {
    width: 154px;
  }

  .profileteam_ul li label {
    color: #222;
    font-size: 12px;
  }

  .createteamform ul li .createinput label {
    font-size: 12px;
  }

  .teamhead {
    padding: 15px 15px;
  }

  .postteam li {
    display: inline-block;
    margin: 0px 9px;
  }

  .teamhead {
    padding: 15px 15px;
    font-size: 18px;
  }

  .teamimg {
    width: 60px;
    height: 60px;
  }

  .matchdate span,
  .matchdate {
    font-size: 15px;
  }

  .profileabout li {
    width: 41.8%;
  }

  .profileabout li a img {
    width: 34px;
    margin-top: 28px;
  }

  .profileabout li a {
    min-height: 110px !important;
  }

  .chatsec {
    display: none;
  }

  .challangebutton li a.cb_link {
    font-size: 12px;
    /* min-width: 136px; */
    display: inline-block;
    margin: 0px 0px 0px 8px;
    padding: 0px 4px;
    line-height: 1.7;
  }

  .challangebutton li {
    flex: 1;
    margin-top: 10px;
  }

  .challangebutton li .ct_drop {
    top: 16px;
  }

  .challangebutton li a.cb_link {
    margin: 0px 0px 0px -2px !important;
  }

  .cl_box_ul li {
    padding: 9px 7px 6px 7px;
  }

  .cl_info_div .cl_userbg {
    width: 50px;
    height: 50px;
    margin-top: 4px;
  }

  .cl_info_div {
    padding-left: 57px;
  }

  .cl_location_div {
    display: inline-block;
    width: 100%;
  }

  .sm_ul li {
    margin: 0px 4px;
  }

  .sm_user {
    width: 60px;
    height: 60px;
  }

  .sm_ul li label {
    font-weight: 500 !important;
    max-width: 100px;
  }

  .multiplelabe {
    font-size: 14px;
  }

  .cp_profile .sm_ul {
    margin-top: 80px;
  }

  .livematch_name,
  .livematch_name span {
    font-size: 16px;
  }

  .livematch_ul li span {
    width: 60px;
    height: 40px;
  }

  .livematch_ul li label {
    font-size: 15px;
    font-weight: 500 !important;
  }

  .livematch_ul li {
    margin: 0px 9px;
  }

  .matchdesc {
    font-size: 13px;
    padding-bottom: 6px;
    margin-top: 2px;
    line-height: 17px;
  }

  .matchlocation {
    font-size: 14px;
  }

  .livescoretag {
    font-size: 13px;
  }

  .livematch_ul {
    display: flex;
  }

  .livematch_ul li {
    flex: 1;
  }

  .livematch_ul li span {
    width: 50px;
    height: 40px;
  }

  .broadcast_date {
    /* margin-top: 20px; */
  }

  div.createteamform ul.teamprofiletab li a {
    font-size: 14px;
  }

  .nav-tabs > li {
    margin-right: 7px;
  }

  .tournamentprofile_div .tourinfo_div {
    padding: 0px 0px;
  }

  .tournamentprofile_div {
    padding-right: 16px !important;
  }

  .leftsecprof {
    position: relative !important;
    top: -22px !important;
  }

  .tour_dateul li label {
    font-size: 15px;
    margin-bottom: 0px;
    padding: 3px 11px 9px 11px;
  }

  .tour_dateul li span {
    font-size: 13px;
  }

  .tour_dateul li {
    padding: 2px 9px;
    margin: 4px 4px;
  }

  .tour_feed_actiondiv button {
    margin: 0px 5px;
  }

  .tour_feed_title span {
    font-size: 17px;
  }

  .tournament_winner_div label {
    width: 90%;
  }

  .tour_inner_feed .teamlocation {
    padding: 0px 15px 10px 15px;
  }

  .livematch_feed {
    padding: 11px 15px 2px 15px;
  }

  .dropbel {
    position: relative;
    right: -88px;
  }

  .topsearch {
    width: 145%;
  }

  .at_ul li {
    display: flex;
    width: auto !important;
    float: unset !important;
    width: auto;
    border: 1px solid #248e3d;
    margin: 15px 15px 15px 15px;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 14px 14px 14px 27px !important;
    text-align: center;
  }

  .fullnoti_label {
    max-width: 210px;
  }
}

@media only screen and (max-width: 767px) {
  .show-w {
    display: none !important;
  }

  .hide-w {
    display: none;
  }

  .mt-10 {
    margin-top: 0%;
  }

  .aboutteamsul li {
    display: inline-block;
    margin: 2px;
    border: 1px solid #2cae4a;
    padding: 10px;
    margin: 6px 6px;
    border-radius: 10px;
  }

  .aboutteamsul li span {
    margin-top: 2px;
  }

  .aboutbroadcast li a {
    min-height: 96px;
    padding-top: 32px;
    background-position: center;
    background-size: cover;
  }

  .aboutleftdata p {
    line-height: 22px;
    font-size: 13px;
    margin-top: 5px;
    margin-bottom: 0;
  }

  .aboutleftdata p label {
    font-weight: 500 !important;
    font-size: 15px;
    color: #222;
    min-width: 103px;
  }

  #editprofile .commanhead,
  #profilebuddies .commanhead,
  #profileteams .commanhead {
    font-size: 18px;
  }

  input.question + label > span,
  textarea.question + label > span {
    font-size: 14px;
  }

  input.question:focus + label > span,
  input.question:valid + label > span {
    top: -55px;
  }

  .editprofile .commaninputdiv {
    margin-top: 31px;
  }

  .profileteam_ul li {
    width: 30%;
  }

  .topbar {
    min-height: 59px;
    z-index: 999;
  }

  .createteamform {
    z-index: 0;
  }

  .sportsul {
    background-color: #f0f0f0;
  }

  .sportsul li {
    width: 29.9%;
    background-color: #fff;
  }

  .d_rightsec {
    display: none;
  }

  .reswidthzero {
    /* padding: 0px !important; */
  }

  .chatnotify {
    display: none;
  }

  .containertop {
    display: flex;
  }

  .topusermenu {
    display: none;
  }

  .dropnotify {
    position: fixed !important;
    bottom: 90px;
    right: 3px;
  }

  .dropnotify .bellnotify {
    display: inline-block;
    width: 50px;
    height: 50px;
    border-radius: 50px;
    background-color: #0d4252 !important;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.4) !important;
    padding: 17px 13px;
    z-index: 9;
  }

  .bellnotify img {
    width: 21px !important;
  }

  .dropnotify button:hover {
    background-color: #248e3d !important;
  }

  .topuserdrop {
    display: none;
  }

  .topsearch input {
    padding: 10px 42px 10px 15px;
    letter-spacing: 0.2px;
    margin-top: 10px;
    color: #fff;
    font-size: 14px;
    margin-bottom: 9px;
  }

  .topsearch img {
    position: absolute;
    right: 9px;
    top: 27px;
    width: 20px;
  }

  .profileul,
  .buddieshead,
  .buddiesul {
    margin-top: 4px;
    display: none;
  }

  .d_leftsec {
    position: fixed;
    width: 0px;
    height: 100%;
    z-index: 8;
    background-color: #fff;
    left: 0px;
    top: 0;
    margin-top: 0;
    box-shadow: 3px 0px 6px rgba(0, 0, 0, 0.5);
    overflow: hidden;
  }

  .d_leftsec_width {
    width: 260px;
    z-index: 999;
  }

  .profilespan {
    margin-top: 0px;
    border: 1px solid #fff;
  }

  .prof_span {
    display: none;
  }

  .d_leftsec .commanbox {
    border: 0 !important;
  }

  .d_leftsec .profileleft {
    margin: 0;
    border-radius: 0;
    padding-top: 18px;
    background-image: url("../img/leftmenubg.png") !important;
    background-position: center;
    background-size: cover;
  }

  .pro_useredit {
    color: #fff;
  }

  .pro_username {
    font-size: 16px;
    color: #fff;
  }

  .pro_viewprofile {
    color: #ffffff;
    font-weight: 500;
    font-size: 14px;
    display: block;
    border: 1px solid #fff;
    border-radius: 70px;
    padding: 0px;
    width: 131px;
    margin: 22px auto 22px auto;
    background-color: #ebe225;
  }

  .pro_viewprofile:hover {
    background-color: #248e3d;
    color: #fff !important;
  }

  .sidemenuul li a img {
    margin-right: 8px;
    width: 35px;
  }

  .sidemenuul li a {
    padding: 10px 10px;
    font-size: 14px;
    font-weight: 500;
  }

  .sidemenuul {
    margin: 0;
    height: 71vh;
    overflow: scroll;
  }

  .profilespan {
    width: 70px;
    height: 70px;
  }

  .profilediv a img {
    width: 17px;
    position: relative;
    top: -1px;
  }

  .pro_viewprofile {
    background-image: url("../img/leftmenubg.png");
  }

  .chatsec,
  .chatopen {
    display: none;
  }

  .containerbottom,
  .containerbottom .pad7 {
    padding: 0px !important;
  }

  .divpost {
    margin-top: 8px;
  }

  .createpostdiv {
    /*    margin-top: 32px; */
    margin-top: 0 !important;
  }

  .createpostdiv .commanhead {
    display: none;
  }

  .createpostdiv .commanhead {
  }

  .createpostdiv .commanhead {
  }

  .postactiondiv {
  }

  .postarea_div {
    padding: 13px;
    padding-left: 13px;
    padding-left: 70px;
  }

  .postarea_div span {
    width: 50px;
    height: 50px;
    left: 10px;
    top: 21px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.79);
    border: 1px solid #999;
  }

  .postarea_div textarea {
    height: 50px;
    border-radius: 70px;
    border: 1px solid #c3ccc6;
    background-color: #e8f2eb;
    font-weight: 500;
    color: #333;
    padding: 14px 22px 0px 18px;
    font-size: 13px;
    margin-top: -21px !important;
    line-height: normal;
  }

  .postarea_div textarea:focus {
    border: 1px solid #2cae4a;
  }

  .Smartactionstop {
    top: 2px;
    margin-top: 58px;
    display: inline-block;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
    padding: 2px 0;
    position: fixed;
    z-index: 9;
  }

  .st_div {
  }

  .st_div ul {
    display: flex;
    margin: 0;
  }

  .st_div li {
    flex: 1;
    text-align: center;
  }

  .st_div li a {
    color: #333;
    font-weight: 500;
    font-size: 11px;
    display: block;
    position: relative;
  }

  .st_div li a.active {
    color: #2cae4a;
  }

  .Smartactionstop .st_div li a.active::after {
    content: "";
    display: inline-block;
    display: inline-block;
    width: 100%;
    height: 2px;
    background-color: #2cae4a;
    position: absolute;
    bottom: -3px;
    left: 0;
  }

  .st_div li a:hover img.smartline,
  .st_div li a.active img.smartline {
    display: none;
  }

  .st_div li a:hover img.smartfill,
  .st_div li a.active img.smartfill {
    display: block;
  }

  .st_div li a img {
    height: 20px;
    display: block;
    margin: auto;
    margin-top: auto;
    margin-bottom: auto;
    margin-top: 3px;
    margin-bottom: 3px;
  }

  .st_div li a .smartline {
  }

  .st_div li a .smartfill {
    display: none;
  }

  .topbar {
    box-shadow: unset;
  }

  .Smartactionsbottom {
    background-color: #fff;
    position: fixed;
    bottom: 0;
    z-index: 1;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.5);
    padding: 5px 0px;
    z-index: 99;
  }

  .containerbottom {
    margin-top: 106px;
  }

  .livescorelink .smartline {
    display: block !important;
    height: 61px;
    margin-top: -27px;
    border-radius: 41px;
    background-color: #fff;
    padding: 6px;
    margin-bottom: -8px;
    box-shadow: 0px -3px 1px rgba(0, 0, 0, 0.3);
  }

  .dropnotify .dropdown-menu {
    top: unset !important;
    bottom: 50px;
    height: 350px;
    right: 10px !important;
    width: 294px;
    transition: all 0.2s linear 0.5s;
  }

  .notificationbox h3 a {
    color: #ece320;
    font-weight: 500;
  }

  .loginbg {
    /* display: none;*/
  }

  .loginform {
    height: auto;
  }

  .loginhead_div {
    position: relative;
    top: 100%;
    transform: translateY(0%);
    width: 100%;
    margin-bottom: -4px;
  }

  .loginforgot button {
    margin-top: 41px;
  }
  .loginlogo_text img {
    display: none;
  }
  .loginhead_div {
    margin-top: 50px;
  }

  .sportskilld {
    text-align: center;
    letter-spacing: 0.5px;
  }

  .ss_search {
    width: 100%;
  }

  .skillshead_div input {
    width: 100%;
  }

  .skillshead_div input {
    width: 100%;
    height: 37px;
  }

  .skillshead_div img {
    right: 10px;
    top: 7px;
  }

  .sportsul li .ss_sportstile {
    font-size: 13px !important;
  }

  .livescoreguest {
    font-size: 16px;
    top: 11px;
  }

  .livescoreguest img {
    width: 20px;
  }

  .teamdiv ul li .it_flgname {
    font-size: 15px;
    display: block;
  }

  .scoreupdate_para {
    font-size: 13px;
  }

  .datentime_para {
    font-size: 15px;
  }

  .scoreboxul > li > h3 {
    font-size: 17px;
  }

  .scoreboxul > li {
    width: 100%;
    margin: 0px 0px 15px 0px;
  }

  .scoreboxul {
    padding: 7px 15px 15px 15px;
  }

  .commanheadlive {
    font-size: 17px;
    padding: 15px;
  }

  .Smartactionsbottomlivescore {
    display: none;
  }

  .commanboxlivescore {
    margin-top: -2px;
  }

  .livescore {
    font-size: 16px;
    top: 13px;
  }

  .Smartactionsbottom,
  .Smartactionstop {
    display: block !important;
  }

  .userlikeinner {
    padding: 0px 10px;
  }

  .d_midsec {
    margin-bottom: 42px;
    overflow: hidden;
  }

  .respadleftzero {
    position: unset !important;
  }

  .profilesection {
    margin-top: 0px;
    margin-bottom: 13px;
    padding-bottom: 61px;
  }

  .skills {
    /* margin-bottom: -26px;
background: transparent;
border: 0;
position: relative;
top: -219px; */
    margin-bottom: 23px !important;
    background: transparent;
    border: 0;
    margin-top: -6px !important;
  }

  .leftarrow {
    display: block;
  }

  .profilecontainer {
    padding: 0px 7px !important;
    margin-top: 30px;
  }

  .followingul li {
    margin: 0px 7px !important;
  }

  .followingul li label {
    font-size: 19px;
  }

  .profilesec {
    margin-top: 107px;
  }

  .profilesec {
    position: relative !important;
    top: 35px !important;
  }

  .uploadbanner {
    margin-right: 15px;
    margin-top: 15px;
    font-size: 12px;
    padding: 4px 10px;
  }

  .skills ul li a .skillsimg {
    width: 55px;
    height: 55px;
  }

  .skills ul li {
    min-width: 87px;
  }

  .profileheader {
    display: none;
  }

  .prof_smart {
    display: none !important;
  }

  .sportsname {
    font-size: 13px;
    color: #333333;
  }

  .followingul {
    /* margin-top: 101px; */
  }

  .respadzero {
    padding: 0px !important;
  }

  .useraccount {
    position: relative;
    width: 100%;
    box-shadow: unset;
    border: 1px solid #e4e4e4;
    border-bottom-color: rgb(228, 228, 228);
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom: 2px solid #e4e4e4;
  }

  .useraccount .commanbox {
    margin-top: 0px;
  }

  .aboutbuddies li span,
  .aboutteamsul li span,
  .aboutbroadcast li label {
    font-size: 15px;
    font-weight: 500 !important;
  }

  .aboutbuddies li a {
    width: 50px;
    height: 50px;
  }

  .useraccount .commanhead {
    font-size: 18px;
  }

  .aboutbroadcast li a img {
    width: 51px;
  }

  .buddieslist_ul li {
    width: 30%;
    margin: 1.7% 1.3%;
  }

  .aboutleftdata p label {
    display: inline-block;
  }

  .buddieslist_ul li label,
  .profileteam_ul li label {
    font-size: 14px;
  }

  .tp_selected li {
    width: 125px;
  }

  .uploadbanner img {
    display: block;
  }

  .uploadbanner span {
    display: none;
  }

  .uploadbanner {
    padding: 0px 18px 0px 10px;
    font-size: 11px !important;
    top: 122px;
    right: -13px;
    box-shadow: unset;
    border-radius: 0;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 12px;
    position: absolute;
  }

  .topnav {
    display: block;
  }

  .createmidsec .divpost {
    margin-top: 0px;
  }

  #team_addplayers .ct_buddies li {
    width: 30%;
  }

  .aboutbroadcast li a img {
    width: 40px;
    margin-top: -6px;
  }

  .profileabout li {
    width: 28%;
  }

  .srachdiv_top {
    top: 59px;
  }

  .broadcast_box {
    padding: 0px 15px;
  }

  .cc_inner_box .createinput {
    margin-bottom: -10px !important;
  }

  .slick-dotted .slick-modified-dots {
    display: none !important;
  }

  .aboutus {
    margin-bottom: 0px;
    padding-bottom: 0px;
  }

  .aboutbox {
    padding: 0px 15px;
    margin-bottom: 68px;
  }

  .pladiatorabout h3 {
    margin-top: 17px;
    margin-bottom: 10px;
  }

  .contactbg {
    min-height: 270px;
  }

  .containercontact {
    margin-top: -115px;
    padding-top: 15px !important;
    margin-bottom: 80px;
  }

  .respad15 {
    padding: 0px 15px;
  }

  .contactul li button {
    margin-top: 0px;
    margin-bottom: 30px;
  }

  .fixture_team span {
    width: 32px;
    height: 32px;
  }

  .fixture_team {
    height: 28px;
    padding: 3px 7px 7px 31px;
  }

  .fixturebox {
    min-width: 198px;
    padding: 10px;
  }

  .fixture_date input {
    margin-top: -1px;
    padding-bottom: 5px;
  }

  .fixturebox {
    min-width: 168px;
  }

  .fixture_team label {
    font-size: 12px;
  }

  .fixture_date img {
    top: -1px;
    width: 15px;
  }

  .fixturebox {
    margin: 12px 15px;
  }

  .fixturelvl_inline {
    margin-bottom: 25px;
  }

  .fixturelvl_li2 .fixturebox {
    margin: 82px 0px 163px 0px;
  }

  .fixturelvl_li1 .fixturelvl_inline::before {
    right: -90px;
    width: 128px;
    top: 53px;
    min-height: 160px;
  }

  .fixturelvl_li3 .fixturebox {
    margin: 226px 0px 453px 0px;
  }

  .fixturelvl_li2 .fixturelvl_inline::before {
    min-height: 288px;
    right: -97px;
    width: 188px;
    top: 64px;
  }

  .fixturelvl_li3 .fixturelvl_inline::before {
    min-height: 574px;
    right: -40px;
    top: 62px;
    width: 108px;
  }

  .fixturelvl_li3 .fixturelvl_inline::before {
    min-height: 574px;
    right: -96px;
    top: 62px;
    width: 108px;
  }

  .fixturelvl_li3 .fixturebox {
    margin: 226px 0px 453px 15px;
  }

  .fixturelvl_li4 .fixturebox {
    margin: 519px 0px 1029px 15px;
  }

  .fixturelvl_li4 .fixturelvl_inline::before {
    min-height: 1156px;
    width: 130px;
    right: -98px;
    top: 64px;
  }

  .fixturelvl_li5 .fixturebox {
    margin-top: 1102px;
  }

  .teamprofile_topdiv {
    margin-top: -3px;
  }

  .tour_selectplayers li {
    width: 30% !important;
  }

  .tourcompleted_match {
    padding: 0px;
  }

  .tourcompleted_match li {
    margin: 0px 0px 0px 0px !important;
  }

  .reslutmodel .modal-dialog {
    max-width: 400px;
    margin: auto;
    margin-top: 58px;
    width: unset !important;
  }

  .notificationbox {
    top: unset !important;
    bottom: 0;
    z-index: 9;
  }

  .dropaccount button {
    z-index: -1;
    /* position: relative;    */
  }

  .notificationbox {
    min-width: 260px;
    bottom: 53px;
  }

  .notiul {
    height: 253px;
  }

  .notificationbox {
    right: 15px;
  }

  .topsearch {
    width: 98% !important;
  }

  .res_notifi {
    top: 43px;
    position: fixed !important;
    bottom: unset !important;
    right: 0 !important;
    width: 100%;
  }

  .res_notifi button {
    position: fixed;
    right: 10px;
    bottom: 84px;
  }

  .responsive_notibiox {
    position: unset !important;
  }

  .res-noiti-list {
    height: 450px !important;
  }

  .fullnoti_ul {
    height: 80vh !important;
  }

  .fullnoti_label {
    max-width: 440px;
  }
}

@media only screen and (max-width: 600px) {
  .sportsul li {
    width: 44.9%;
    background-color: #fff;
    height: 147px;
    margin: 2.5%;
  }

  .sportsul li .ss_sportstile {
    font-size: 14px;
  }

  .sportsul {
    height: 73vh;
  }

  .aboutbroadcast {
    padding: 0px 6px;
  }

  .teamimg {
    width: 60px;
    height: 60px;
  }

  .vsimg {
    width: 30px;
  }

  .matchdate span,
  .matchdate,
  .teamlocation,
  .teamname,
  .teamname_winner {
    font-size: 15px;
  }

  .teamhead {
    font-size: 18px;
  }

  .actiondiv button {
    font-size: 14px;
    padding: 3px 15px;
  }

  .imgwinner {
    width: 26px;
  }

  .teamhead {
    display: block;
    text-align: center;
    padding: 15px 15px;
  }

  .matchscore {
    font-size: 15px;
  }

  .postteam li {
    margin: 0px 5px;
  }

  .teamlocation {
    font-size: 15px;
    font-weight: 500 !important;
    margin: 0;
    margin-top: 0px;
    line-height: 19px;
    margin-top: 5px;
    width: 92%;
  }

  .uploadimg_div {
    margin-top: 58px;
  }

  .teamlocation img {
    margin-right: 25px;
    left: 13px;
  }

  .createteamform ul li .createinput {
    margin-bottom: 4px;
  }

  .createteam_div {
    min-height: 220px;
  }

  .uploadimg_div span {
    width: 100px;
    height: 100px;
  }

  div.createteamform ul.teamprofiletab li a {
    color: #333 !important;
    font-size: 14px;
    white-space: nowrap;
  }

  .topsearch {
    width: 95% !important;
  }

  .challangebutton {
    display: block !important;
    /* text-align: center; */
  }

  .challangebutton li {
    margin-top: 10px;
    display: inline-block;
    min-width: 44%;
    /* margin-left: -52px; */
  }

  .challangebutton li .ct_drop {
    top: -34px;
  }
}

@media only screen and (max-width: 480px) {
  .sportsul li .ss_spansportsimg {
    display: inline-block;
    width: 60px;
    height: 60px;
  }

  .sportsul li .ss_spansportsimg img {
    height: 40px;
    margin-top: 8px;
  }

  .sportsul {
    padding: 9px;
  }

  .nav-tabs > li > a {
    /* padding: 4px 13px !important;
min-width: 112px;
text-align: center;
margin-bottom: 8px; */
    float: left;
    margin-bottom: -1px;
    float: unset !important;
    display: inline-block !important;
    margin: 0px;
  }

  .nav-tabs > li {
    float: left;
    margin-bottom: -1px;
    float: unset !important;
    display: inline-block !important;
  }

  .nav-tabs {
    border-bottom: 1px solid #ddd;
    text-align: center;
  }

  .profileteam_ul li {
    width: 44%;
  }

  .buddieslist_ul li {
    /* width: 44%; */
  }

  .editprofile input {
    padding-bottom: 5px;
  }

  .sportsdiv_profile .sportsul li {
    float: left;
    width: 46%;
  }

  .sportsdiv_profile .sportsul li .ss_sportstile {
    margin-top: 7px;
    font-size: 13px;
    line-height: 15px;
  }

  .teambox ul li {
    width: 100%;
  }

  .teambox ul li {
    min-height: auto;
    margin-bottom: 10px;
  }

  .teambox ul li button.assigncaptain {
    margin-top: 5px;
    margin-bottom: 7px;
  }

  .team_addmembers {
    padding-top: 0px;
    margin-top: -2px;
  }

  #team_addplayers .ct_buddies li {
    width: 47%;
  }

  .profileabout li {
    width: 28%;
    width: 46% !important;
    margin: 1.7% 1.3%;
  }

  .broadcastlist_ul li {
    float: left;
    width: 100%;
  }

  .broadcastlist_ul li {
    padding: 0px 0px 15px 0px !important;
    min-width: 100% !important;
  }

  div.createteamform ul.teamprofiletab li a {
    padding: 0px 0px 7px 0px !important;
    font-size: 13px;
  }

  .bf_div {
    top: 75% !important;
  }

  .broadcastdrop .dropdown-toggle img {
    width: 21px;
  }

  .broadcastdrop .dropdown-toggle {
    margin-left: -1px;
  }

  input.question + label > span,
  textarea.question + label > span,
  .cc_genderli .creategender label {
    font-size: 13px;
  }

  .tour_selectplayers li {
    width: 44% !important;
    margin: 2% 2% !important;
  }

  .selectplayer_popup .modal-body {
    position: relative;
    padding: 2px;
  }

  .teamprofiletab {
    z-index: 8;
    overflow-x: scroll;
  }

  .tour_selectplayers {
    overflow-x: hidden;
    overflow-y: scroll;
    max-height: 377px;
  }

  .tour_feed_title {
    width: 90%;
  }

  .tour_dateul li label {
    font-size: 14px;
  }

  .tour_dateul li span {
    font-size: 12px;
  }

  .valueul {
    display: inline-block !important;
  }

  .valueul li {
    margin: 0px 0% !important;
    min-width: 100%;
    min-width: 100%;
    margin-top: 15px !important;
  }

  /*.dropaccount {*/
  /*    width: 111px !important;*/
  /*}*/

  .challangebutton li a.cb_link {
    font-size: 12px;
    min-width: unset;
    display: inline-block;
    margin: 0px 0px 0px 8px;
    padding: 5px 4px;
    line-height: 1.7;
  }

  .challangebutton {
    display: block !important;
    text-align: unset !important;
  }

  .scheduleDate {
    padding: 0 !important;
  }

  .srachdiv_top {
    width: 146%;
  }

  .fullnoti_moreres {
    width: unset !important;
  }

  .more-fullnotixs {
    position: relative;
    top: -44px;
    right: -12px;
    z-index: 1 !important;
  }
}

@media only screen and (max-width: 1330px) {
  .loginhead {
    display: block;
    text-align: center;
  }

  .googlebtn-d {
    width: 212px;
    height: 39px;
    border: none;
    border-radius: 100px;
    background-color: #db4437;
    text-align: center;
    font-size: 12px;
  }

  .facebookbtn-d {
    width: 212px;
    height: 39px;
    border: none;
    border-radius: 100px;
    background-color: #4267b2;
    text-align: center;
    font-size: 12px;
  }
}

@media only screen and (max-width: 1199px) {
  .sportsimg-d img {
    width: 85%;
    /*margin-top: 10%;*/
  }

  .loginhead {
    display: block;
    text-align: center;
  }

  .googlebtn-d {
    width: 210px;
    height: 39px;
    border: none;
    border-radius: 100px;
    background-color: #db4437;
    text-align: center;
    font-size: 12px;
  }

  .facebookbtn-d {
    width: 210px;
    height: 39px;
    border: none;
    border-radius: 100px;
    background-color: #4267b2;
    text-align: center;
    font-size: 12px;
  }

  .at_ul li {
    float: unset;
    width: 93.5%;
    border: 1px solid #248e3d;
    margin: 15px;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 14px 14px 34px 14px;
  }
}

@media only screen and (max-width: 1024px) {
  .loginhead {
    display: block;
    text-align: center;
  }

  .twobtn-d {
    display: block;
    margin-top: 21px;
    text-align: center;
  }

  .facebookbtn {
    display: block;
  }

  .googlebtn {
    display: block;
    margin-top: 10px;
    float: none;
  }

  .googlebtn-d {
    width: 231px;
    height: 39px;
    border: none;
    border-radius: 100px;
    background-color: #db4437;
    text-align: center;
    font-size: 12px;
  }

  .facebookbtn-d {
    width: 231px;
    height: 39px;
    border: none;
    border-radius: 100px;
    background-color: #4267b2;
    text-align: center;
    font-size: 12px;
  }

  .storeimg-d {
    display: block;
  }

  .storeimg-d img {
    width: 43%;
  }

  .appleimg {
    float: right;
    width: 48%;
  }

  .googleplayimg {
    width: 48%;
  }
}

@media only screen and (max-width: 991px) {
  .sportsimg-d img {
    width: 85%;
    /*margin-top: 26%;*/
  }

  .loginbg-d {
    padding: 30px;
  }

  .cl_info_div .cl_type {
    float: unset !important;
    display: block;
    margin-top: 2px;
    margin-bottom: 7px;
  }
}

@media only screen and (max-width: 767px) {
  .loginbg-d {
    display: block;
  }

  .loginhead_div {
    margin-top: 0px !important;
  }

  .sportsimg-d img {
    /*display: none;*/
  }

  .loginhead {
    display: block;
    text-align: center;
  }

  .twobtn-d {
    display: block;
    margin-top: 21px;
    text-align: unset;
  }

  .facebookbtn {
    display: inline-block;
  }

  .googlebtn {
    display: inline-block;
    margin-top: 0px;
    float: right;
  }

  .signuptag {
    text-align: center;
    display: block;
    margin-top: 24px;
  }

  .signuptag p {
    font-size: 16px;
  }

  .signuptag p span {
    font-size: 16px;
  }

  .googleplayimg {
    width: 40%;
  }

  .appleimg {
    float: right;
    width: 40%;
  }

  .at_ul li {
    float: left !important;
    width: 46.9% !important;
    border: 1px solid #248e3d;
    margin: 15px 0px 0px 15px;
    box-sizing: border-box;
    padding: 14px;
    border-radius: 10px;
    padding: 14px 14px 8px 14px !important;
    justify-content: center;
  }

  .cl_info_div .cl_type {
    float: right !important;
    display: inline-block;
    margin-top: -1px;
  }

  .challangebutton li a.cb_link {
    padding: 10px 4px;
  }
}

@media only screen and (max-width: 480px) {
  .loginbg-d {
    display: block;
  }

  .twobtn-d {
    display: block;
    margin-top: 15px;
    text-align: center;
  }

  .facebookbtn {
    display: block;
  }

  .googlebtn {
    display: block;
    margin-top: 14px;
    float: unset;
  }

  /*.googleplayimg {*/
  /*    display: block;*/
  /*    margin: 0 auto;*/
  /*    width: 50%*/
  /*}*/

  /*.appleimg {*/
  /*    float: none;*/
  /*    display: block;*/
  /*    margin-top: 10px !important;*/
  /*    margin: 0 auto;*/
  /*    width: 50%*/
  /*}*/

  .at_ul li {
    display: flex;
    width: auto !important;
    float: unset !important;
    width: auto;
    border: 1px solid #248e3d;
    margin: 15px 15px 15px 15px;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 14px 14px 14px 25px;
    text-align: center;
  }

  .cl_info_div .cl_type {
    float: unset !important;
    display: block;
    margin-top: 5px;
  }
}

@media only screen and (max-width: 320px) {
  .loginbg-d {
    display: block;
  }

  .googleplayimg {
    display: block;
    margin: 0 auto;
    width: 75%;
  }

  .appleimg {
    float: none;
    display: block;
    margin-top: 10px !important;
    margin: 0 auto;
    width: 75%;
  }

  .logoshield img {
    width: 69%;
    margin: 0 auto;
  }
}

/* cricket responsive */

@media only screen and (max-width: 1199px) {
  .scoreinput-d {
    width: 100%;
    height: 166px;
    border: 1px solid #248e3d;
    margin-top: 15px;
    border-radius: 8px;
  }

  .undobtn-d {
    color: #fff;
    border: none;
    font-size: 13px;
    background-image: linear-gradient(#f19839, #e96f0d);
    margin-top: 9px;
    padding: 5px 35px 5px 35px;
    text-align: center;
  }

  .swapbtn-d {
    background-color: #245470;
    color: #fff;
    padding: 6px;
    border: none;
    font-size: 12px;
  }

  .btnbatsman-d {
    border-right: 1px solid #d0d0d0;
    padding: 5px;
    text-align: center;
  }

  .marg-d {
    border-bottom: 1px solid #d2d4d2;
    padding-bottom: 14px;
    margin: 8px 8px 6px 8px !important;
    text-align: unset;
  }

  .btnrun-d {
    margin-top: -5px;
    text-align: unset;
  }
}

@media only screen and (max-width: 991px) {
  .styled-input-single-d {
    position: relative;
    padding: 0px 0 0px 18px;
    text-align: left;
    margin-right: 11px;
  }

  .scoreinput-d {
    width: 100%;
    height: 185px;
    border: 1px solid #248e3d;
    margin-top: 15px;
    border-radius: 8px;
  }

  .btnbatsman-d {
    border-right: none;
    padding: unset;
    text-align: center;
  }

  .undobtn-d {
    color: #fff;
    border: none;
    font-size: 13px;
    background-image: linear-gradient(#f19839, #e96f0d);
    margin-top: 9px;
    padding: 6px 35px 5px 35px;
    text-align: center;
  }

  .swapbtn-d {
    background-color: #245470;
    color: #fff;
    padding: 6px;
    border: none;
    font-size: 12px;
    margin-right: 10px;
  }

  .btnrun-d {
    margin-top: 9px;
    text-align: center;
    display: flex;
  }

  .createinput label {
    font-weight: 300;
  }
}

@media only screen and (max-width: 768px) {
  .marg-d {
    border-bottom: 1px solid #d2d4d2;
    padding-bottom: 14px;
    margin: 8px 8px 6px 8px !important;
    text-align: center;
  }

  .btnrun-d {
    margin-top: 9px;
    text-align: center;
    display: inline-block;
  }

  .scoreinput-d {
    width: 100%;
    height: 161px;
    border: 1px solid #248e3d;
    margin-top: 15px;
    border-radius: 8px;
  }
}

@media only screen and (max-width: 480px) {
  .marg-d {
    border-bottom: 1px solid #d2d4d2;
    padding-bottom: 14px;
    margin: 8px 8px 6px 8px !important;
    text-align: unset;
  }

  .scoreinput-d {
    width: 100%;
    height: auto;
    border: 1px solid #248e3d;
    margin-top: 15px;
    border-radius: 8px;
    float: left;
    margin-bottom: 15px;
    padding-bottom: 10px;
  }

  .btnrun-d {
    margin-top: 9px;
    text-align: left;
    display: inline-block;
  }
}

@media only screen and (min-width: 320px) {
  .scoreinput-d {
    width: 100%;
    height: auto;
    border: 1px solid #248e3d;
    margin-top: 15px;
    border-radius: 8px;
    float: left;
    margin-bottom: 15px;
    padding-bottom: 10px;
  }
}

/* END cricket responsive */

@media only screen and (max-width: 768px) {
  .chatdiv_sm {
    padding: 10px;
    background-color: #fff;
    display: inline-block;
    height: 100%;
    box-shadow: -2px 3px 2px rgba(198, 198, 198, 0.5);
    overflow-y: scroll;
    width: 100%;
    transition: all 0.2s ease 0s;
  }

  .chatul_sm {
    margin: 36px;
    padding-bottom: 0px;
    margin-top: 55px !important;
  }

  .namechat_side_sm {
    display: inline-block;
    width: 90%;
  }

  .namechat_sm {
    font-weight: 500;
    margin: 1px;
  }

  .chatmsg_show_sm {
    font-size: 13px;
    margin-top: -9px;
    white-space: nowrap;
    width: 102%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .chatline {
    border-top: 1px solid #eaeaea;
    margin-top: -7px;
  }

  .userchatdiv_sm {
    background-color: #fff;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.4);
    overflow: hidden;
    transition: all 0.3s ease 0s;
    width: 100%;
  }

  .chatsend_sm {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #dcecdf;
    padding: 6px;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 99;
  }

  .chatsend {
    position: fixed;
    bottom: 0;
    width: 100%;
  }

  .sent_plane {
    display: inline-block;
    width: 36px;
    height: 34px;
    border-radius: 50px;
    background-color: #2cae4a;
    box-shadow: 0px 2px 2px #627365;
    padding: 10px 9px 9px 9px;
    position: absolute;
    right: 8px;
    bottom: 15px;
  }

  .chatuserli_sm p {
    width: 202px;
  }

  .chatmeli_sm p {
    width: 202px;
  }

  .usermsg {
    margin-top: 10px;
  }

  .ch_userimg {
    margin-right: 15px;
  }

  .chatdetails_sm {
    height: 100vh !important;
    margin-top: -64px;
  }

  .chathead_sm {
    background-color: #2cae4a;
    padding: 6px 6px 2px 6px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 01;
  }

  .smallsize_chat {
    position: fixed;
    top: 6px;
  }

  .inputtypestext {
    padding-left: 37px !important;
  }
}

@media only screen and (max-width: 600px) {
  .chatul_sm {
    margin: 36px;
    padding-bottom: 0px;
  }

  .namechat_side_sm {
    display: inline-block;
    width: 80%;
  }

  .chatmsg_show_sm {
    font-size: 13px;
    margin-top: -9px;
    white-space: nowrap;
    width: 102%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .chatline {
    border-top: 1px solid #eaeaea;
    margin-top: -7px;
  }

  .smallsize_chat {
    position: fixed;
    top: 6px;
  }
}

@media only screen and (max-width: 490px) {
  .chatul_sm {
    margin: 20px;
    padding-bottom: 0px;
  }

  .namechat_side_sm {
    display: inline-block;
    width: 74%;
  }

  .namechat_sm {
    font-weight: 500;
    margin: 1px;
  }

  .chatmsg_show_sm {
    font-size: 13px;
    margin-top: -9px;
  }

  .chatline {
    border-top: 1px solid #eaeaea;
    margin-top: -7px;
  }

  .smallsize_chat {
    position: fixed;
    top: 6px;
  }

  .challangebutton li {
    margin-left: 0px !important;
    min-width: 41%;
  }
}
/* ---------page media query-------- */
@media only screen and (max-width: 1199px) {
  .bottomsec-page {
    margin-top: 31px;
  }

  .right-page-sec {
    margin-top: -29px;
  }

  .page-aboutbuddies li a {
    width: 50px !important;
    height: 50px !important;
  }

  .add-icon-page {
    margin-top: 15px;
    height: 21px;
  }
}

@media only screen and (max-width: 992px) {
  .left-page-sec {
    padding: 0 !important;
    padding-right: unset !important;
  }

  .page-aboutbuddies li {
    margin: 0 15px !important;
  }

  .broadcst-page-ul li {
    margin: 16px 15px !important;
  }

  .right-page-sec {
    margin-top: -2px;
  }

  .broadcst-page-ul li a {
    min-height: 150px !important;
  }

  .broadcst-page-ul li a img {
    width: 48px;
    margin-top: 38px;
  }
}

@media only screen and (max-width: 768px) {
  .left-page-sec {
    padding: 0 !important;
    padding-right: unset !important;
  }

  .page-team-li {
    border: none !important;
  }

  .img-info-page {
    height: auto !important;
  }

  .pageprofile-img {
    display: block;
    position: relative;
    margin: 0 auto;
    text-align: center;
    left: unset !important;
    top: -44px;
  }

  .pageprofile-img a {
    display: inline-block;
    width: 35px;
    height: 35px;
    border-radius: 40px;
    background-color: #1b732f;
    position: absolute;
    bottom: 0;
    left: 53% !important;
    border: 2px solid #2cae4a;
    padding-top: 9px;
    padding-left: 1px !important;
  }

  .page-heading {
    display: inline-block;
    position: unset !important;
    width: 100%;
    margin-left: unset !important;
    text-align: center;
    margin-top: -28px;
  }

  .btnlikepage {
    margin: 0 auto 18px;
    text-align: center;
    display: block;
    float: none !important;
  }

  .bottomsec-page {
    flex-direction: column-reverse;
    display: flex;
  }

  .left-page-sec {
    margin-top: 7%;
  }

  .right-page-sec {
    margin-top: -16px;
  }

  .page-aboutbuddies li {
    margin: 0 0px !important;
  }

  .left-page-sec {
    margin-top: 11%;
  }

  .broadcst-page-ul li a img {
    width: 48px;
    margin-top: 9px;
  }
}
/* Award winning responsive */

@media only screen and (max-width: 1199px) {
  .award_btn {
    width: 46%;
  }

  .greyout {
    border: 1px solid #cecece;
    background-color: #f9f9f9;
  }
}

@media only screen and (max-width: 992px) {
  .award_btn {
    width: 100%;
    margin: 0px;
    margin-bottom: 10px;
  }

  .greyout {
    border: 1px solid #cecece;
    background-color: #f9f9f9;
  }
}

@media only screen and (max-width: 768px) {
  .award_btn {
    border: 1px solid #2cae4a;
    margin: 5px;
    width: 47%;
    padding: 8px;
    background: #2cae4a1f;
    border-radius: 5px;
    font-size: 14px;
    color: #000000;
    margin-left: 7px;
  }

  .award_btn {
    width: 46%;
  }

  .greyout {
    border: 1px solid #cecece;
    background-color: #f9f9f9;
  }
  .tour_matchwinner2 {
    width: 36px;
    margin-top: -24px;
    position: absolute;
    left: 77%;
  }
}

@media only screen and (max-width: 600px) {
  .award_btn {
    width: 46%;
  }

  .greyout {
    border: 1px solid #cecece;
    background-color: #f9f9f9;
  }
  .tour_matchwinner2 {
    width: 36px;
    margin-top: -24px;
    position: absolute;
    left: 77%;
  }
}

@media only screen and (max-width: 480px) {
  .award_btn {
    width: 100%;
    margin: 0px;
    margin-bottom: 10px;
  }

  .greyout {
    border: 1px solid #cecece;
    background-color: #f9f9f9;
  }
  .tour_matchwinner2 {
    width: 36px;
    margin-top: -24px;
    position: absolute;
    left: 75%;
  }
  .app_btnn .big-txt {
    font-size: 16px;
  }
  .app_btnn i {
    width: 19%;
    text-align: center;
    font-size: 29px!important;
    margin-right: 14px;
  }
}
@media only screen and (max-width: 768px) {
  .Qrmodel-width {
   width: auto!important;
  }
  .qrblock img {
    width: 50% !important;
  }
}

@media only screen and (max-width: 600px) {
  .qrcode-model {
    height: unset !important;
  }
  .qrblock img {
    width: 74% !important;
  }
}

@media only screen and (max-width: 992px) {
  .qrcode-model {
    height: auto !important;
    overflow-y: auto;
  }
  .qrblock img {
    width: 45%;
  }
  .Qr-model-body {
    display: flex !important;
    flex-direction: column-reverse;
  }
  .qrblock {
    margin-bottom: 0px!important;
    margin-top: 0px!important;
  }
  .Qr-sec-headng {
    display: none;
    margin-top: -45px;
    font-size: 16px;
    font-weight: 500;
    color: #000;
    margin-bottom: 25px;
  }
}
